import React, { useState } from "react";
import { H4, S05 } from "../../../../../../styles/typography";
import { AllSlotsEl } from "../../elements/batches";
import date from "date-and-time";
import Button from "../../../../../../components/button";

function AllSlots({ data, onSubmit }) {
  const [loading, setLoading] = useState(false);

  return (
    <AllSlotsEl>
      <H4>All Slots</H4>
      {!!data &&
        data.map(({ startTime, endTime }) => (
          <S05 className="preview-slot" key={startTime}>
            <b>{date.format(new Date(startTime), "DD MMM YYYY, ")}</b>
            {date
              .format(new Date(startTime), "hh:mm A - ")
              .split(".")
              .join("")
              .toUpperCase()}
            {date
              .format(new Date(endTime), "hh:mm A")
              .split(".")
              .join("")
              .toUpperCase()}
          </S05>
        ))}
      <Button
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          await onSubmit();
        }}
      >
        Submit
      </Button>
    </AllSlotsEl>
  );
}

export default AllSlots;
