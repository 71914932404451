import { Redirect, Router } from "@reach/router";
import React from "react";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Api } from "../../api/Configuration";
import TopNav from "../../components/topNav";
// import PrivateRoute from "../../utils/PrivateRoute";
import GlobalStyles from "../../styles/global";
import { withStore } from "../../utils/withStore";
import Login from "../auth/pages/login";
import Dashboard from "../dashboard";
import AuthStore from "../auth/stores/authStore";
import Loading from "../../components/loading";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DocumentsViewer from "../../modules/documentsViewer";
import DocumentsViewerStore from "../../modules/documentsViewer/store/documentsViewerStore";
import Test from "../test";
import { ConfirmProvider } from "material-ui-confirm";

function App() {
  const { user, loading, logout } = AuthStore.useContainer();

  return (
    <ConfirmProvider>
      <>
        <GlobalStyles />
        <SWRConfig
          shouldRetryOnError={false}
          value={{
            fetcher: (
              url,
              type = "public",
              parser = (x) => x,
              method = "get"
            ) =>
              Api[type][method](url)
                .then((data) => {
                  if (data?.success) {
                    return data?.result;
                  } else {
                    throw Error(data?.result);
                  }
                })
                .then(parser),
          }}
        >
          <div className="App">
            <DndProvider backend={HTML5Backend}>
              <TopNav />
              <Loading loading={loading}>
                <Router>
                  <Redirect from="/" to="/login" noThrow />
                  <Login path="/login" />
                  <Test path="/test" />
                  <Dashboard
                    path="dashboard/*"
                    role={user.id ? user.role : -1}
                    action={logout}
                  />
                </Router>
              </Loading>
              <DocumentsViewer />
              <ToastContainer />
            </DndProvider>
          </div>
        </SWRConfig>
      </>
    </ConfirmProvider>
  );
}

export default withStore([AuthStore, DocumentsViewerStore])(App);
