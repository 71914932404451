import styled from "styled-components";
import { center, hFlex } from "../../../styles/style-helpers";

export const ContentEditorEl = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1em;
`;

export const ViewerEl = styled.div`
  position: relative;
  width: 100%;

  .MuiAccordion-root {
    margin-bottom: 1em;
    border-radius: 0.25em;
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.5);
  }
`;

export const ContentEl = styled.div`
  position: relative;
  width: 100%;

  h6 {
    margin: 0 0 0.5em;
    width: 100%;
    ${hFlex};
    justify-content: space-between;
    font-weight: bold;
  }

  p {
    margin: 1em 0;
  }

  video {
    border-radius: 0.75em;
    width: 75%;
  }
`;

export const EditorEl = styled.div`
  ${center};
  width: 75%;
  height: 75%;
  background: white;
  border-radius: 0.5em;
  padding: 1em;
  overflow: auto;

  h5 {
    width: 100%;
    ${hFlex};
    justify-content: space-between;
  }

  .MuiFormControl-root {
    margin-bottom: 1em;
  }

  .video {
    margin: 0 0 1em;

    video {
      width: 50%;
      border-radius: 0.5em;
    }
  }

  .actions {
    width: 100%;
    ${hFlex};
    justify-content: flex-end;

    button {
      width: 12.5em;
    }
  }
`;
