import { Api } from "./Configuration";
import { generateOptions } from "../helpers/misc";

export const getTeachers = () =>
  Api.protected
    .get("admin/teacher-info/become-teacher-requests")
    .then((data) => data?.result)
    .catch(() => false);

export const privateToggleRequest = (teacherId, status) =>
  Api.protected.post("admin/alter-teacher-state", { teacherId, status });

export const masterToggleRequest = (teacherId, status) =>
  Api.protected.post("admin/alter-teacher-master-state", { teacherId, status });

export const updateCoverVideoRequest = (teacherId, externalProfileVideo) =>
  Api.protected.put("teacher/profile-video/upload", {
    teacherId,
    externalProfileVideo,
  });

export const processRequest = (userId, status, note) =>
  Api.protected.post("admin/teacher-info/process", {
    status,
    userId,
    note,
  });

export const setTeacherCover = (teacherId, imageId) =>
  Api.protected.post("teacher/make-cover-image", {
    teacherId,
    imageId,
  });

export const deleteTeacher = (teacherID) =>
  Api.protected.delete(`teacher/${teacherID}`);

export const getInstruments = () =>
  Api.protected
    .get("teacher-info/teaching-categories/")
    .then(({ result }) => generateOptions(result))
    .catch(() => false);

export const getLanguages = () =>
  Api.protected
    .get("teacher-info/teaching-languages")
    .then(({ result }) => generateOptions(result))
    .catch(() => false);

export const sendTeacherFollowerEmails = (data) =>
  Api.protected.post(`admin/send-follower-email`, data);

export const uploadTeacherCover = (teacherID, data) =>
  Api.protected.post(`teacher/upload-cover/${teacherID}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteTeacherCover = (teacherId, imageId, imageType) =>
  Api.protected.delete(
    `admin/teacher/${teacherId}/upload-cover-images/${imageId}/${imageType}`
  );

export const changeArtistType = (teacherId, artistType) =>
  Api.protected.put(`admin/teacher/${teacherId}/add-artist-type`, {
    artistType,
  });

export const changeSessionTypes = (teacherId, sessionTypes) =>
  Api.protected.put(`admin/teacher/${teacherId}/add-session-types`, {
    sessionTypes,
  });

export const updateScore = (teacherId, score) =>
  Api.protected.put(`admin/teacher-info/${teacherId}/score/`, {
    score,
  });

export const lobbyAssetUpload = (teacherId, data) =>
  Api.protected.post(`teacher/${teacherId}/prerolldocuments`, data);

export const lobbyAssetUpdate = (teacherId, fileId, weight) =>
  Api.protected.put(`teacher/${teacherId}/prerolldocuments/${fileId}`, {
    weight,
  });

export const deleteLobbyAsset = (teacherId, fileId) =>
  Api.protected.put(`teacher/${teacherId}/prerolldocuments/${fileId}`);

export const updateEndorsements = (teacherId, endorsements = []) =>
  Api.protected.post(`admin/teacher/endorsements`, {
    teacherId,
    endorsements,
  });
export const editTeacher = (teacherId, { description }) =>
  Api.protected.put(`admin/teacher-info/${teacherId}`, {
    teacherId,
    description,
  });

export const TeacherApi = {
  privateToggleRequest,
  masterToggleRequest,
  updateVideo: updateCoverVideoRequest,
  updateRequestStatus: processRequest,
  deleteRequest: deleteTeacher,
  setCoverImage: setTeacherCover,
  uploadTeacherCover,
  deleteTeacherCover,
  changeArtistType,
  changeSessionTypes,
  updateScore,
  lobbyAssetUpload,
  lobbyAssetUpdate,
  deleteLobbyAsset,
  updateEndorsements,
  editTeacher,
  getInstruments,
  getLanguages,
};
