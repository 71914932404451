import React from "react";
import Modal from "../../../../../components/modal";
import Search from "../../../../../components/search";
import { CloseButtonEl, StatusBarEl, StatusBarHeadEl } from "../../../elements";
import { InvitationModalEl, SearchBoxEl } from "../elements";
import InvitationStore from "../store/invitationStore";
import SendInvitation from "./sendInvitation";

function StatusBar() {
  const { setQuery } = InvitationStore.useContainer();
  return (
    <StatusBarEl>
      <StatusBarHeadEl>
        <SearchBoxEl>
          <Search onChange={setQuery} />
        </SearchBoxEl>
        <Modal
          title={"Send Invitation"}
          content={({ modalOpen }) => (
            <InvitationModalEl>
              <CloseButtonEl onClick={() => modalOpen(false)}>X</CloseButtonEl>
              <SendInvitation onComplete={() => modalOpen(false)} />
            </InvitationModalEl>
          )}
        />
      </StatusBarHeadEl>
    </StatusBarEl>
  );
}

export default StatusBar;
