import { useCallback, useState } from "react";

export const useList = (initialValue = []) => {
  const [list, updateList] = useState(initialValue);

  const set = useCallback((value) => {
    updateList((list) => (typeof value === "function" ? value(list) : value));
  }, []);

  const push = useCallback((value) => {
    updateList((list) =>
      typeof value === "function" ? [...list, value(list)] : [...list, value]
    );
  }, []);

  const updateAt = useCallback((index, value) => {
    updateList((list) => {
      list[index] =
        typeof value === "function" ? value(list[index], list) : value;

      return [...list];
    });
  }, []);

  const insertAt = useCallback((index, value) => {
    updateList((list) => [
      ...list.slice(0, index),
      typeof value === "function" ? value(list[index], list) : value,
      ...list.slice(index),
    ]);
  }, []);

  const update = useCallback((test, value) => {
    updateList((list) =>
      list.map((v, i) =>
        test(v)
          ? typeof value === "function"
            ? value(list[i], list)
            : value
          : v
      )
    );
  }, []);

  const updateFirst = useCallback((predicate = () => false, value) => {
    updateList((list) => {
      const index = list.findIndex(predicate);

      if (index !== -1) {
        return [
          ...list.slice(0, index),
          typeof value === "function" ? value(list[0], list) : value,
          ...list.slice(index + 1),
        ];
      } else {
        return list;
      }
    });
  }, []);

  const upsert = useCallback((predicate = () => false, value) => {
    updateList((list) => {
      const index = list.findIndex(predicate);

      if (index !== -1) {
        return [
          ...list.slice(0, index),
          typeof value === "function" ? value(list[0], list) : value,
          ...list.slice(index + 1),
        ];
      } else {
        return [
          ...list,
          typeof value === "function" ? value(list[0], list) : value,
        ];
      }
    });
  }, []);

  const sort = useCallback((comparator = (a, b) => a - b) => {
    updateList((list) => [...list.sort(comparator)]);
  }, []);

  const filter = useCallback((filterFn = () => true) => {
    updateList((list) => [...list.filter(filterFn)]);
  }, []);

  const clear = useCallback(() => {
    updateList([]);
  }, []);

  const reset = useCallback(() => {
    updateList(initialValue);
  }, [initialValue]);

  const remove = useCallback((index) => {
    updateList((list) => [...list.slice(0, index), ...list.slice(index + 1)]);
  }, []);

  return [
    list,
    {
      set,
      push,
      updateAt,
      insertAt,
      update,
      updateFirst,
      upsert,
      sort,
      filter,
      remove,
      clear,
      reset,
    },
  ];
};
