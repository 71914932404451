import React, { useState } from "react";
import Select from "react-select";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import Modal from "../../../../../../components/modal";
import { createSelectOptions } from "../../../../../../helpers/misc";
import { daysSelectOptions } from "../../config";
import { AddSlotModalEl } from "../../elements/batches";

function AddSlot({ schedule, insert, duration }) {
  const [selDate, setSelDate] = useState(
    new Date(new Date().setHours(new Date().getHours() + 1, 0, 0, 0))
  );
  const [day, setDay] = useState(createSelectOptions(0, "Sunday"));
  const [error, setError] = useState("");

  return (
    <Modal
      title={"Add Slot"}
      variant="outlined"
      color="secondary"
      content={({ modalOpen }) => (
        <AddSlotModalEl>
          <Select
            options={daysSelectOptions}
            onChange={setDay}
            value={day}
            placeholder={"Select Day"}
          />
          <Input
            type="timepicker"
            minutesStep={30}
            value={selDate}
            onChange={setSelDate}
          />
          {error && <p className="error">{error}</p>}
          <Button
            onClick={() => {
              if (
                schedule.filter((a) => {
                  const date = new Date(a);
                  return (
                    date.getDay() === day.value &&
                    selDate.getHours() + selDate.getMinutes() / 60 >
                      date.getHours() + date.getMinutes() / 60 - duration &&
                    selDate.getHours() + selDate.getMinutes() / 60 <
                      date.getHours() + date.getMinutes() / 60 + duration
                  );
                }).length > 0
              )
                setError("Slot is overlapping with another slot");
              else {
                const newDate = selDate.setDate(
                  selDate.getDate() + day.value - selDate.getDay()
                );
                insert(newDate);
                modalOpen(false);
              }
            }}
          >
            Add
          </Button>
        </AddSlotModalEl>
      )}
    />
  );
}

export default AddSlot;
