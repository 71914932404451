import { Accordion, AccordionSummary } from "@material-ui/core";
import styled from "styled-components";
import { greyBorder } from "../../../styles/colors";
import { hFlex, vFlex } from "../../../styles/style-helpers";
import { H5, H6 } from "../../../styles/typography";
import { ButtonEl } from "../../button/elements";

export const PollsEl = styled.div`
  position: relative;

  form {
    ${H5} {
      text-align: center;
    }
    > div {
      margin: 0.5em 0;
    }
    ${ButtonEl} {
      margin: 1em 1em 0 0;
    }
  }

  .add-btns {
    ${hFlex}
    justify-content: flex-end;

    ${ButtonEl} {
      margin: 0 1em 1em 0;
    }
  }
`;

export const PollTitleEl = styled(AccordionSummary)`
  flex-direction: row-reverse;

  > .MuiIconButton-edgeEnd {
    margin-left: -24px;
  }

  > .MuiAccordionSummary-content {
    ${hFlex}
    justify-content: space-between;
  }
`;

export const PollEl = styled(Accordion)`
  .MuiAccordionDetails-root {
    ${vFlex}
    position: relative;

    .edit-btn {
      position: absolute;
      top: 0;
      right: 0;
    }

    .poll-info {
      width: 100%;
      position: relative;
      padding: 1em 0;

      .description {
        width: 90%;
      }

      > form {
        border: 1px solid ${greyBorder};
        padding: 1em;
        border-radius: 10px;
      }
    }

    > ${H5} {
      width: 100%;
      ${hFlex}
      justify-content: space-between;
      border-top: 1px solid ${greyBorder};

      > span {
        padding: 12px 0;
      }
    }

    .poll-options {
      width: 100%;
      position: relative;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
    border-bottom: 1px solid ${greyBorder};
  }
`;

export const PollOptionEl = styled.div`
  width: 100%;
  border: 1px solid ${greyBorder};
  padding: 1em;
  border-radius: 10px;
  position: relative;
  margin: 0.5em 0;

  .info {
    width: 90%;

    ${H6} {
      margin-bottom: 0.5em;
    }

    .edit-btn {
      opacity: ${({ deleted }) => (deleted ? 0 : 1)};
      right: calc(1em + 24px);
    }
  }

  .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    opacity: ${({ deleted }) => (deleted ? 0 : 1)};
  }
`;

export const QuickPollEl = styled.form`
  width: 100%;
  padding: 1em;
  border: 1px solid ${greyBorder};
  border-radius: 10px;
  margin-bottom: 1em;
`;
