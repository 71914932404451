import { applySpec, pathOr } from "ramda";
import { parseDoc, parseSlots } from "../helpers/dataParsers";

const coverImages = (user) => {
  const imagesEdited = pathOr({}, ["coverImagesEdited"])(user);
  const imagesRaw = pathOr({}, ["coverImagesRaw"])(user);
  const images = { ...imagesEdited, ...imagesRaw };
  return Object.entries(images)
    .map(([key, val]) => {
      return { id: key, ...parseDoc(val) };
    })
    .filter(({ url }) => url !== "");
};

const availability = (user) => {
  const slots = pathOr([], ["markingSlots"])(user);
  return parseSlots(slots);
};

const instruments = (teacher) => {
  const instruments = pathOr([], ["teachingAreas", "value"])(teacher);
  return instruments.map((val) => {
    return {
      name: val,
      value: val,
    };
  });
};

const languages = (teacher) => {
  const languages = pathOr([], ["teachingLanguages", "value"])(teacher);
  return languages.map((val) => {
    return {
      name: val,
      value: val,
    };
  });
};

const profilePhoto = (teacher) => {
  let imgdoc = pathOr("", ["user", "profile", "profilePhoto"])(teacher);
  imgdoc = imgdoc[Object.keys(imgdoc).slice(-1)[0]];
  return (
    pathOr(null, ["metaData", "s3", "extra", "Location"])(imgdoc) ||
    pathOr("", ["location"])(imgdoc)
  );
};

const lobbyAssets = (teacher) => {
  const files = pathOr({}, ["preroll-documents"])(teacher);
  return Object.entries(files)
    .map(([key, val]) => ({
      id: key,
      name: val.fileName,
      url: val.location,
      size: val.fileSize,
      type: val.fileType,
      weight: val?.weight ? val.weight : 1,
    }))
    .sort((a, b) => b.weight - a.weight);
};

const Teacher = applySpec({
  id: pathOr("", ["id"]),
  status: pathOr("", ["status"]),
  profile: applySpec({
    profilePhoto,
    name: pathOr("", ["user", "profile", "name"]),
    email: pathOr("", ["user", "email"]),
    subtext: pathOr("", ["user", "email"]),
    location: pathOr("", ["user", "profile", "city"]),
    gender: pathOr("", ["user", "gender"]),
    phone: pathOr("", ["user", "phoneNumber"]),
    livdemyId: pathOr("", ["livdemyId", "value"]),
  }),
  updatedAt: pathOr(new Date().getTime(), ["updatedAt"]),
  endorsements: pathOr([], ["endorsements"]),
  artistType: pathOr("", ["artistType"]),
  sessionTypes: pathOr([], ["sessionTypes"]),
  isPrivate: pathOr(false, ["isPrivate"]),
  isMaster: pathOr(false, ["isMaster"]),
  profileVideo: pathOr("", ["externalProfileVideo"]),
  teacherDescription: pathOr("", ["teacherDescription", "value"]),
  instruments,
  languages,
  score: pathOr("", ["score"]),
  experience: pathOr({}, ["documents", "value"]),
  availability,
  coverImage: pathOr("", ["coverImage", "value", "location"]),
  coverImages,
  lobbyAssets,
});

export default Teacher;
