import { applySpec, pathOr, length, pipe, update } from "ramda";
import date from "date-and-time";

const purchaseDate = (subscription) => {
	const time = pathOr("", ["DateOfPurchase"])(subscription);
	return Boolean(time)
		? date.format(new Date(time), "DD MMM YYYY hh:mmA")
		: time;
};

const endDate = (subscription) => {
	const time = pathOr("", ["SubscriptionEndDate"])(subscription);
	return Boolean(time)
		? date.format(new Date(time), "DD MMM YYYY hh:mmA")
		: time;
};

const amount = (bill) => {
	const price = pathOr("", ["amount"])(bill);
	const currency = pathOr("", ["currency"])(bill);
	return `${currency} ${price}`;
};

const bills = (subscription) => {
	const allBills = pathOr([], ["bills"])(subscription);
	return allBills.map((bill) =>
		applySpec({
			id: pathOr("", ["id"]),
			mode: pathOr("", ["paymentGateway"]),
			amount,
			updatedAt: pathOr("", ["updatedAt"]),
			frequency: pathOr("", ["frequency"]),
		})(bill)
	);
};

const Subscription = applySpec({
	purchaseDate,
	endDate,
	total_bills: pipe(pathOr([], ["bills"]), length),
	bills,
	frequency: pathOr("", ["frequency"]),
	name: pathOr("", ["name"]),
	email: pathOr("", ["email"]),
	plan: pathOr("", ["subscriptionLevel"]),
});

export default Subscription;
