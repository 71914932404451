import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { PollOptionEl } from "../elements";
import PollOptionEditForm from "./pollOptionEditForm";
import CreateIcon from "@material-ui/icons/Create";
import { H6, S05 } from "../../../styles/typography";
import DeleteIcon from "@material-ui/icons/Delete";
import PollStore from "../store/pollStore";

function PollOption({ data, mode, onSubmit, onCancel, deleted }) {
  const [editState, setEditState] = useState(mode === "create");

  const { deletePollOption } = PollStore.useContainer();

  return (
    <PollOptionEl deleted={deleted}>
      {editState ? (
        <PollOptionEditForm
          mode={mode}
          data={data}
          onSubmit={async (values) => {
            await onSubmit(values);
            setEditState(false);
          }}
          onCancel={() => {
            setEditState(false);
            onCancel();
          }}
        />
      ) : (
        <div className="info">
          <H6 bold>{data.name} </H6>
          <S05>{data.description}</S05>
          <S05 bold>Votes: {data.voteCount}</S05>
          <IconButton className="edit-btn" onClick={() => setEditState(true)}>
            <CreateIcon />
          </IconButton>
        </div>
      )}
      {mode !== "create" && (
        <IconButton
          className="delete-btn"
          onClick={() => deletePollOption(data.id)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </PollOptionEl>
  );
}

export default PollOption;

PollOption.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  mode: "display",
  deleted: false,
};
