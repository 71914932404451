import { Api } from "./Configuration";

export const passwordAuthRequest = (email, password) =>
  Api.public.post("auth/login/regular/step1", { email, password });

export const clearCache = () =>
  fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/invalid/cache`, {
    method: "DELETE",
  })
    .then((res) => res.status)
    .catch((err) => err);
