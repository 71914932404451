import React from "react";
import ContentEditorStore from "../../stores/content-editor-store";
import Content from "./components/content";
import { ViewerEl } from "../../elements";
import Button from "../../../../components/button";
import AddIcon from "@material-ui/icons/Add";
import Editor from "../editor";

const Viewer = () => {
  const { showEditor, toggleEditor, content } =
    ContentEditorStore.useContainer();

  return (
    <ViewerEl>
      {Object.values(content).map((content, index) => (
        <Content key={content.id} data={content} path={"/"} index={index} />
      ))}
      <div className="actions">
        <Button
          variant="default"
          startIcon={<AddIcon />}
          onClick={() => {
            toggleEditor({ path: "/", content: {} });
          }}
        >
          Add Content
        </Button>
      </div>

      {showEditor && (
        <Editor {...showEditor} close={() => toggleEditor(false)} />
      )}
    </ViewerEl>
  );
};

export default Viewer;
