import React from "react";
import { Checkbox as CheckboxComponent } from "@material-ui/core";
import styled from "styled-components";
import { hFlex } from "../../../styles/style-helpers";

const CheckboxComp = styled(CheckboxComponent)``;

const CheckboxEl = styled.div`
  ${hFlex}
  justify-content: flex-start;
`;

function Checkbox({ label, ...props }) {
  return (
    <CheckboxEl>
      <CheckboxComp inputProps={{ "aria-labelledby": label }} {...props} />
      <span id={label}>{label}</span>
    </CheckboxEl>
  );
}

export default Checkbox;
