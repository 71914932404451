import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { string, url, isRequired } from "../../../utils/form-errors";
import { useToggle } from "../../../utils/hooks/useToggle";
import Button from "../../../components/button";
import Input from "../../../components/input";
import { UrlFileUploadEl } from "../elements";

function UrlFileUpload({ submitRequest, ...rest }) {
  const [submitting, setSubmitting] = useToggle(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        urlLink: yup
          .string(string)
          .url(url)
          .required(isRequired`Attachment Link`),
        urlName: yup.string(string),
      })
    ),
    defaultValues: {
      urlLink: "",
      urlName: "",
    },
  });
  const onSub = async (values) => {
    setSubmitting(true);
    const data = {
      fileName: values.urlName,
      location: values.urlLink,
      fileType: "url",
      fileSize: "0",
    };
    await submitRequest(data);
    setSubmitting(false);
  };
  return (
    <UrlFileUploadEl onSubmit={handleSubmit(onSub)}>
      <Controller
        as={<Input />}
        control={control}
        name="urlLink"
        error={errors.urlLink}
        placeholder="Attachment Url"
      />
      <Controller
        as={<Input />}
        control={control}
        name="urlName"
        error={errors.urlName}
        placeholder="Attachment Name"
      />
      <br />
      <Button type="submit" disabled={rest?.disabled || submitting}>
        Submit
      </Button>
    </UrlFileUploadEl>
  );
}

export default UrlFileUpload;
