import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import styled from "styled-components";
import { vFlex } from "../../../styles/style-helpers";

export const AccordionEl = styled(Accordion)`
  width: 100%;
`;

export const AccordionSummaryEl = styled(AccordionSummary)`
  color: #c7402d !important;
`;

export const AccordionDetailsEl = styled(AccordionDetails)`
  ${vFlex}
  > div {
    width: 100%;
  }
  > * {
    margin: 0 0 0.5em;
    overflow-wrap: anywhere;
  }
  .ql-editor {
    word-break: break-word;
  }
`;
