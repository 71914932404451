import { yupResolver } from "@hookform/resolvers";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../../../../components/button";
import Input from "../../../../../../../components/input";
import { BannerFormEl } from "../elements";
import * as yup from "yup";
import {
  isRequired,
  number,
  string,
} from "../../../../../../../utils/form-errors";
import Modal from "../../../../../../../components/modal";
import { FileUploadModalEl } from "../../../../../elements";
import FileUploader from "../../../../../../../modules/fileUploader";
import { H3, H5 } from "../../../../../../../styles/typography";

function BannerEditForm({
  data,
  submit,
  cancel,
  secondaryAction = null,
  mode,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [image, setImage] = useState(data.image);

  const { handleSubmit, errors, setValue, control } = useForm({
    defaultValues: {
      image: data.image,
      heading: data.heading.join("\n"),
      para: data.para.join("\n"),
      ctaName: data.cta.name,
      ctaRoute: data.cta.route,
      position: data.position,
    },
    resolver: yupResolver(
      yup.object().shape({
        image: yup.string(string).required(isRequired`Image`),
        heading: yup.string(string).required(isRequired`Heading`),
        para: yup.string(string).required(isRequired`Description`),
        ctaName: yup.string(string),
        ctaRoute: yup.string(string),
        position: yup
          .number()
          .typeError(number)
          .required(isRequired`Position`),
      })
    ),
  });

  useEffect(() => {
    if (image) setValue("image", image, { shouldValidate: true });
  }, [image, setValue]);

  return (
    <BannerFormEl
      onSubmit={handleSubmit(async (values) => {
        setSubmitting(true);
        const data = {
          image: values.image,
          heading: values.heading.split("\n"),
          para: values.para.split("\n"),
          cta: {
            name: values.ctaName,
            route: values.ctaRoute,
          },
          position: values.position,
        };
        await submit(data);
      })}
    >
      <H3>{mode === "create" ? "Create Banner" : "Edit Banner"}</H3>
      <Controller as={<input />} control={control} name={"image"} hidden />
      <H5 bold>Background Image:</H5>
      {errors?.image && <p className="error">{errors.image.message}</p>}
      <div className="image-input">
        {image ? (
          <div className="uploaded-img">
            <img src={image} alt={"banner background"} />
            <p>Background Image</p>
          </div>
        ) : (
          <p>
            <b>Not uploaded</b>
          </p>
        )}
        <Modal
          title={`Upload${image ? " New " : " "}Image`}
          content={({ modalOpen }) => (
            <FileUploadModalEl>
              <FileUploader
                fileTypes={["image/*"]}
                uploadSuccess={async (file) => {
                  console.log(file);
                  setImage(file.location);
                  modalOpen(false);
                }}
              />
            </FileUploadModalEl>
          )}
        />
      </div>
      <Controller
        as={<Input />}
        control={control}
        name={"position"}
        error={errors.position}
        label={"Position"}
        type={"number"}
        className="position-input"
      />
      <div className="content">
        <div className="content-info">
          <H5 bold>Heading:</H5>
          <Controller
            as={<Input type="textbox" />}
            control={control}
            name={"heading"}
            error={errors.heading}
            label="Heading"
            rowsMin={2}
          />
          {errors?.heading && (
            <p className="error">{errors.heading?.message}</p>
          )}
          <H5 bold>Description:</H5>
          <Controller
            as={<Input type="textbox" />}
            control={control}
            name={"para"}
            error={errors.para}
            label="Description"
            rowsMin={2}
          />
          {errors?.para && <p className="error">{errors.para?.message}</p>}
        </div>
        <div className="content-button">
          <H5 bold>CTA Button:</H5>
          <Controller
            as={<Input />}
            control={control}
            name={"ctaName"}
            error={errors.ctaName}
            label="Name"
          />
          <Controller
            as={<Input />}
            control={control}
            name={"ctaRoute"}
            error={errors.ctaRoute}
            label="Relative Route"
          />
        </div>
      </div>

      <Button type={"Submit"} disabled={submitting}>
        Submit
      </Button>
      {mode !== "create" && (
        <Button
          onClick={async (e) => {
            e.preventDefault();
            setSubmitting(true);
            await secondaryAction();
          }}
          disabled={submitting}
        >
          {mode === "edit_delete" ? "Delete" : "Restore"}
        </Button>
      )}
      <Button
        onClick={(e) => {
          e.preventDefault();
          cancel();
        }}
        disabled={submitting}
        withconfirmation
      >
        Cancel
      </Button>
    </BannerFormEl>
  );
}

export default BannerEditForm;
