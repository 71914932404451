import React from "react";
import {
  AccordionEl,
  AccordionSummaryEl,
  AccordionDetailsEl,
} from "./elements";

function Accordion({ title, children, ...props }) {
  return (
    <AccordionEl {...props}>
      <AccordionSummaryEl>{title}</AccordionSummaryEl>
      <AccordionDetailsEl>{children}</AccordionDetailsEl>
    </AccordionEl>
  );
}

export default Accordion;
