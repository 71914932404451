import React, { useState } from "react";
import PropTypes from "prop-types";
import { FilesReorderEl } from "../../elements";
import File from "./file";
import Button from "../../../../components/button";
import { useDrop } from "react-dnd";

function FilesReorder({ files: originalFiles, onSubmit }) {
  const [files, setFiles] = useState(originalFiles);
  const [loading, setLoading] = useState(false);

  const moveFile = (dragInd, hoverInd) => {
    const dragFile = files[dragInd];

    if (dragFile) {
      setFiles((prevState) => {
        const copy = [...prevState];
        const prevItem = copy.splice(hoverInd, 1, dragFile);
        copy.splice(dragInd, 1, prevItem[0]);

        return copy;
      });
    }
  };

  const [, dropRef] = useDrop({
    accept: "files",
  });

  return (
    <FilesReorderEl>
      <div className="files-list" ref={dropRef}>
        {files.map((file, i) => (
          <File
            key={file.id}
            data={file}
            setFiles={setFiles}
            index={i}
            moveFile={moveFile}
          />
        ))}
      </div>
      <Button
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          await onSubmit(files);
        }}
      >
        Save
      </Button>
    </FilesReorderEl>
  );
}

export default FilesReorder;

FilesReorder.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      weight: PropTypes.number,
    })
  ),
  onSubmit: PropTypes.func,
};

FilesReorder.defaultProps = {
  files: [],
  onSubmit: () => {},
};
