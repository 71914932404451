import styled from "styled-components";
import { modalBox, vFlex } from "../../../../../styles/style-helpers";

export const InvitationSecEl = styled.div`
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: unset;
  overflow: auto;

  .invitation-table {
    ${vFlex}
    justify-content: flex-start;
    padding: 0;
    height: 85%;
  }
`;

export const InvitationTabEl = styled.div`
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 1em;
  padding: 0.5em 1em;

  p {
    margin: 0.3em 0;
  }

  .id {
    position: absolute;
    top: 0;
    right: 0.2em;
  }
`;

export const InvitationModalEl = styled.div`
  ${modalBox}
  min-height: 50vh;
`;

export const SendInvitationEl = styled.form`
  h4 {
    text-align: center;
    margin-bottom: 1em;
  }

  > div {
    margin: 0.5em 0;
  }
`;

export const SearchBoxEl = styled.div`
  input {
    width: 100%;
    padding: 0.5em;
    background: #fff;
    border-bottom: 1px solid #cdcbcd;
    box-shadow: 0 0 1em rgb(0 0 0 / 25%);
    border-radius: 0.5em;
  }
`;
