import React, { useState } from "react";
import Button from "../../../../../components/button";
import Form from "../../../../../components/forms";
import { StatusBarEl, StatusBarHeadEl, CollapseEl } from "../../../elements";

function TeacherStatusbar({ request, updateRequestStatus, deleteRequest }) {
  const { status } = request;
  const [checked, setChecked] = useState(false);
  return (
    <StatusBarEl state={checked ? "open" : "closed"}>
      <StatusBarHeadEl>
        <div>
          Status: <b>{status}</b>
        </div>
        {status !== "APPROVED" ? (
          <Button onClick={() => setChecked(!checked)}>
            {checked ? "Close" : "Update Status"}
          </Button>
        ) : null}
        {status !== "PENDING_APPROVAL" ? (
          <Button onClick={() => deleteRequest()} withconfirmation="true">
            UNPUBLISH
          </Button>
        ) : null}
      </StatusBarHeadEl>
      <CollapseEl in={checked} unmountOnExit>
        <Form
          formType="status-update"
          defaultVal=""
          onApproved={async (msg) => {
            await updateRequestStatus("APPROVED", msg);
            setChecked(false);
          }}
          onRejected={async (msg) => {
            await updateRequestStatus("REJECTED", msg);
            setChecked(false);
          }}
        />
      </CollapseEl>
    </StatusBarEl>
  );
}

export default TeacherStatusbar;
