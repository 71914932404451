import React, { useState } from "react";
import { default as ModalComponent } from "@material-ui/core/Modal";
import styled from "styled-components";
import Button from "../button";
import { vFlex } from "../../styles/style-helpers";

export const ModalComp = styled(ModalComponent)`
  ${vFlex}
`;

function Modal({ title, content, onOpen = null, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={async () => {
          if (onOpen) await onOpen();
          setOpen(true);
        }}
        {...props}
      >
        {title}
      </Button>
      <ModalComp open={open} onClose={() => setOpen(false)}>
        {content({ modalOpen: setOpen })}
      </ModalComp>
    </>
  );
}

export default Modal;
