import { createFilter } from "react-search-input";
import {
  flatten,
  has,
  hasPath,
  includes,
  map,
  pathOr,
  pipe,
  pluck,
  sortWith,
} from "ramda";
import date from "date-and-time";
import Teacher from "../schemas/teacher";
import Course from "../schemas/course";
import BroadcastClass from "../schemas/broadcastClass";
import BroadcastSeries from "../schemas/broadcastSeries";
import Review from "../schemas/review";
import { filters } from "../app/dashboard/config/routes";
import currencies from "../assets/data/currencies";
import ClassBanner from "../schemas/classBanner";
import Poll from "../schemas/poll";

export const teacherDataParser = (data) => {
  // console.log(data);
  return Object.keys(data)
    .reduce((merged, key) => [...merged, ...data[key]], [])
    .map((teacher) => Teacher(teacher))
    .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
};

export const courseDataParser = (data) => {
  return data.courses
    .filter(({ status }) => status !== "DELETED")
    .map((course) => Course(course))
    .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
};

export const filterSeriesData = (classes = [], series = [], filter) => {
  // console.clear();
  const newSeries = series
    .map((ser) => {
      if (filter === "upcoming" && ser.broadcastClasses.length === 0)
        return ser;
      const filterClasses = ser.broadcastClasses.filter(({ id }) =>
        includes(id, pluck("id", classes))
      );
      filterClasses.sort(
        (a, b) =>
          (filter === "upcoming" &&
            Number(a.startTime) - Number(b.startTime)) ||
          (filter === "completed" && Number(b.startTime) - Number(a.startTime))
      );
      return filterClasses.length > 0
        ? { ...ser, broadcastClasses: filterClasses }
        : {};
    })
    .filter((ser) => has("id")(ser));

  const skipClasses = pipe(
    pluck("broadcastClasses"),
    flatten,
    pluck("id")
  )(newSeries);
  const newClasses = classes.filter((cls) => !includes(cls.id, skipClasses));
  return [...newClasses, ...newSeries].sort((a, b) => {
    const aTime =
      a?.startTime ||
      (a.broadcastClasses.length > 0 && a.broadcastClasses[0].startTime) ||
      new Date(8640000000000000).getTime();
    const bTime =
      b?.startTime ||
      (b.broadcastClasses.length > 0 && b.broadcastClasses[0].startTime) ||
      new Date(8640000000000000).getTime();
    return (
      (filter === "upcoming" && aTime - bTime) ||
      (filter === "completed" && bTime - aTime)
    );
  });
};

export const getFilteredData = (filter, data, KEYS_TO_FILTER) => {
  if (filter) {
    const keys = filters[filter] || filters["approved"];
    if (keys === "upcoming")
      return data.filter(
        ({ endTime, extended = 0 }) =>
          Number(endTime + extended * 60 * 1000) > new Date().getTime()
      );
    if (keys === "completed")
      return data.filter(
        ({ endTime, extended = 0 }) =>
          Number(endTime + extended * 60 * 1000) <= new Date().getTime()
      );

    console.log({keys, KEYS_TO_FILTER})

    return keys
      .map((key) => data.filter(createFilter(key, KEYS_TO_FILTER)))
      .reduce((accumulate, current) => [...accumulate, ...current], [])
      .sort((a, b) => b.updatedAt - a.updatedAt);
  }
};

export const dataToTab = (data = []) => {
  return data.map((dt) => {
    return {
      key: dt.id,
      route: `${dt.id}`,
      matchRoute: `${dt.id}/*`,
      meta: {
        text: dt.profile.name,
        subtext: dt.profile.subtext,
        status: dt.status,
      },
    };
  });
};

export const dataToCourseTab = (data = []) => {
  return data.map((dt) => {
    return {
      key: dt.id,
      route: `${dt.id}`,
      matchRoute: `${dt.id}/*`,
      meta: {
        text: dt.profile.name,
        subtext: dt.profile.subtext,
        status: dt.status,
        type: dt.type,
      },
    };
  });
};

export const dataToBroadcastTab = (data = [], filter) => {
  return data.map((dt) => {
    if (dt?.type === "series") {
      // console.log("a");
      return {
        key: dt.id,
        route: `${dt.id}`,
        matchRoute: `${dt.id}`,
        meta: {
          text: dt.profile.name,
          subtext: `${dt.profile.subtext} | ${
            filter === "upcoming" ? dt.profile.minTime : dt.profile.maxTime
          } | Series`,
          status: dt.status,
          subtabs: dt.broadcastClasses.map((cls) => {
            return {
              key: cls.id,
              route: `${dt.id}/${cls.id}`,
              matchRoute: `${dt.id}/${cls.id}`,
              meta: {
                subtext: `${date.format(
                  new Date(cls.startTime),
                  "DD MMM YYYY"
                )} | Class`,
                text: cls.name,
              },
            };
          }),
        },
      };
    } else {
      return {
        key: dt.id,
        route: `${dt.id}`,
        matchRoute: `${dt.id}/*`,
        meta: {
          text: dt.profile.name,
          subtext: `${dt.profile.subtext} | Class`,
          status: dt.status,
        },
      };
    }
  });
};

export const broadcastClassDataParser = (data) => {
  return data
    .filter(({ status }) => status !== "DRAFT")
    .map((cls) => BroadcastClass(cls));
};

export const broadcastSeriesDataParser = (data) => {
  return data
    .filter(({ status }) => status !== "DRAFT")
    .map((series) => BroadcastSeries({ ...series, type: "series" }));
};

export const parseDoc = (doc = {}) => {
  return {
    name:
      pathOr(null, ["document", "fileName"])(doc) ||
      pathOr("", ["fileName"])(doc),
    url:
      pathOr(null, ["location"])(doc) ||
      pathOr(null, ["document", "location"])(doc) ||
      pathOr(null, ["document", "metaData", "s3", "extra", "Location"])(doc) ||
      pathOr(null, ["metaData", "s3", "extra", "Location"])(doc) ||
      "",
    type:
      pathOr(null, ["fileType"])(doc) ||
      pathOr(null, ["document", "fileType"])(doc) ||
      pathOr(null, ["document", "metaData", "s3", "extra", "fileType"])(doc) ||
      "",
    imageType: hasPath(["document"])(doc) ? "edited" : "raw",
  };
};

export const parseSlots = (slots = []) => {
  let daysToTime = {};
  slots.forEach((slot) => {
    const day = date.format(new Date(slot.startTime), "dddd");
    const time = {
      start: date.format(new Date(slot.startTime), "h:mmA").toUpperCase(),
      end: date.format(new Date(slot.endTime), "h:mmA").toUpperCase(),
    };
    if (!(day in daysToTime)) daysToTime[day] = [];
    daysToTime[day].push(time);
  });
  return Object.entries(daysToTime).map(
    ([day, slot]) =>
      `${day}: ${slot
        .reduce(
          (string, interval) => `${string}, ${interval.start}-${interval.end}`,
          ``
        )
        .substr(2)}`
  );
};

export const getCurrencyList = () => {
  return [{ cc: "DEF" }, ...currencies].map(({ cc }) => {
    return {
      name: cc,
      value: cc,
    };
  });
};

export const reviewDataParser = (type, data) => {
  return data
    .map((review) => Review({ ...review, type: type }))
    .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
};

export const lobbyAssetParser = (files = {}) => {
  return Object.entries(files)
    .map(([key, val]) => {
      return {
        id: key,
        name: val.fileName,
        url: val.location,
        size: val.fileSize,
        type: val.fileType,
        weight: val?.weight ? val.weight : 1,
      };
    })
    .sort((a, b) => b.weight - a.weight);
};

export const bannerDataParser = (data) => data?.map((dt) => ClassBanner(dt));

export const pollParser = (data = []) =>
  pipe(
    map(Poll),
    sortWith([
      (a, b) => (a.isPrivate && b.isPrivate ? 0 : b.isPrivate ? -1 : 1),
      (a, b) => b.updatedAt - a.updatedAt,
    ])
  )(data);
