import { AccordionDetails, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { PollEl, PollTitleEl } from "../elements";
import CreateIcon from "@material-ui/icons/Create";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PollEditForm from "./pollEditForm";
import AddIcon from "@material-ui/icons/Add";
import { H5 } from "../../../styles/typography";
import PollOption from "./pollOption";
import { PollOption as Option } from "../../../schemas/poll";
import PollStore from "../store/pollStore";
import Button from "../../button";

function Poll({ data, mode, onSubmit, onCancel, onDelete }) {
  const [open, setOpen] = useState(mode === "create");
  const [editState, setEditState] = useState(mode === "create");
  const [creating, setCreating] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const { addPollOption, editPollOption } = PollStore.useContainer();

  return (
    <PollEl
      expanded={open}
      onChange={() => {
        if (mode !== "create") {
          setEditState(false);
          setOpen(!open);
        }
      }}
    >
      <PollTitleEl expandIcon={<ExpandMoreIcon />}>
        <p>{!editState && data.name}</p>
        {mode !== "create" && (data.isPrivate ? "PRIVATE" : "PUBLIC")}
      </PollTitleEl>
      <AccordionDetails>
        <div className="poll-info">
          {editState ? (
            <PollEditForm
              mode={mode}
              data={data}
              onSubmit={async (values) => {
                await onSubmit(values);
                setEditState(false);
              }}
              onCancel={() => {
                setEditState(false);
                onCancel();
              }}
            />
          ) : (
            <>
              <div className="description">
                <b>Description: </b>
                {data.description}
              </div>
              <IconButton
                className="edit-btn"
                onClick={() => setEditState(true)}
              >
                <CreateIcon />
              </IconButton>
            </>
          )}
        </div>
        {mode !== "create" && (
          <>
            <H5 bold>
              <span>Poll Options:</span>
              {!creating && (
                <IconButton onClick={() => setCreating(true)}>
                  <AddIcon />
                </IconButton>
              )}
            </H5>
            <div className="poll-options">
              {creating && (
                <PollOption
                  mode={"create"}
                  data={Option({})}
                  onSubmit={async (values) => {
                    await addPollOption({ pollId: data.id, ...values });
                    setCreating(false);
                  }}
                  onCancel={() => setCreating(false)}
                />
              )}
              {data.options.length < 1
                ? "No Poll Options"
                : data.options.map((option) => (
                    <PollOption
                      mode={"display"}
                      data={option}
                      onSubmit={(values) => editPollOption(option.id, values)}
                    />
                  ))}
              {data.deletedOptions.length > 0 && (
                <Button onClick={() => setShowDeleted(!showDeleted)}>
                  {showDeleted ? "Hide" : "View"} Deleted Options
                </Button>
              )}
              {showDeleted && (
                <>
                  <hr style={{ marginTop: "1em" }} />
                  {data.deletedOptions.map((option) => (
                    <PollOption mode={"display"} data={option} deleted={true} />
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </AccordionDetails>
    </PollEl>
  );
}

export default Poll;

Poll.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  mode: "display",
};
