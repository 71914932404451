import React from "react";
import ClickToCopy from "../../../../../modules/clickToCopy";
import Invitation from "../../../../../schemas/invitation";
import date from "date-and-time";

export const invitationDataParser = (data = []) => {
  return data.map((dt) => Invitation(dt));
};

export const templateDataToTab = (data = []) => {
  return data.map((dt) => {
    return {
      key: dt,
      route: `${dt}`,
      matchRoute: `${dt}/*`,
      meta: {
        text: dt,
      },
    };
  });
};

export const templateToList = (data = []) => {
  return data.map((dt) => ({ label: dt, value: dt }));
};

export const invitationCellModifier = (data = [], header) => {
  switch (header) {
    case "invitationTime":
    case "consumptionTime":
      data =
        data[header] === 0
          ? ""
          : date.format(new Date(data[header]), "DD MMM YYYY hh:mmA");
      break;
    default:
      data = data[header];
      break;
  }
  return <ClickToCopy data={String(data)}>{data}</ClickToCopy>;
};
