export const createTeachersList = (data = {}) =>
  Object.keys(data)
    .filter((key) => key !== "pendingApprovals")
    .reduce((merged, key) => [...merged, ...data[key]], [])
    .filter(({ isPrivate }) => !isPrivate)
    .map(({ user, livdemyId }) => ({
      label: `${user?.profile?.name}`,
      value: livdemyId?.value,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
