import React from "react";
import { PricingEl } from "../../courses/elements";
import CountryDiscountForm from "../../../../../components/forms/specificForms/countryDiscounts";
import { H4 } from "../../../../../styles/typography";
import { getCurrencyList } from "../../../../../helpers/dataParsers";
import BroadcastClassStore from "../store/broadcastClassStore";
import { DiscountEl, DiscountsEl } from "../elements";
import { Box, IconButton, Tab, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useToggle } from "../../../../../utils/hooks/useToggle";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Form from "../../../../../components/forms";

function Pricing() {
  const [value, setValue] = React.useState("live");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [processing, toggleProcessing] = useToggle(false);

  const {
    curRequest: {
      id,
      priceMatrix,
      currency,
      countryDiscounts,
      recordingDiscount = {},
      recordingPriceMatrix = {},
    },
    addDiscount,
    editBroadcastClass,
  } = BroadcastClassStore.useContainer();

  return (
    <PricingEl>
      <TabContext value={value}>
        <Box style={{ width: "100%" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Live Price" value="live" />
            <Tab label="Recording Price" value="recording" />
          </TabList>
        </Box>
        <TabPanel
          value={"live"}
          index={0}
          style={{ width: "100%", padding: "2em 0" }}
        >
          <Typography variant={"h5"} style={{ marginBottom: 16 }}>
            Country Specific Prices
          </Typography>
          <CountryDiscountForm
            submitRequest={async (val) =>
              await editBroadcastClass({
                priceMatrix: { ...priceMatrix, ...val },
              })
            }
            items={getCurrencyList()}
            textLabel={"Price"}
            outputSchema={({ Value, Currency }) => ({
              [`${Currency}`]: Value,
            })}
          />

          <DiscountsEl>
            {Object.entries(priceMatrix).map(([key, val]) => (
              <DiscountEl
                key={`${id}-${key}`}
                onClick={() => {
                  console.log({ key, val });
                }}
              >
                <H4>{key}</H4>
                <p>{val}</p>
                <IconButton
                  onClick={async () => {
                    const matrix = { ...priceMatrix };
                    delete matrix[key];

                    toggleProcessing(true);

                    await editBroadcastClass({
                      priceMatrix: matrix,
                    });

                    toggleProcessing(false);
                  }}
                  disabled={processing}
                >
                  <DeleteIcon />
                </IconButton>
              </DiscountEl>
            ))}
          </DiscountsEl>
          <Typography
            variant={"h5"}
            style={{ marginBottom: 16, marginTop: 32 }}
          >
            Discounts
          </Typography>
          <CountryDiscountForm
            submitRequest={async (val) => {
              console.log({ val });
              return await addDiscount(val);
            }}
            items={getCurrencyList()}
            textLabel={"Discount (%)"}
            outputSchema={({ Value, Currency }) => ({
              [`${Currency}`]: {
                percentage: Value,
              },
            })}
            maxLimit={100}
          />
          <DiscountsEl>
            {Object.entries(countryDiscounts)
              .filter(([, dis]) => Boolean(dis?.percentage))
              .map(([key, val]) => (
                <DiscountEl key={`${id}-${key}`}>
                  <H4>{key}</H4>
                  <p>{val.percentage}%</p>
                  <IconButton
                    onClick={async () => {
                      toggleProcessing(true);

                      await addDiscount({ [key]: { percentage: 0 } });

                      toggleProcessing(false);
                    }}
                    disabled={processing}
                  >
                    <DeleteIcon />
                  </IconButton>
                </DiscountEl>
              ))}
          </DiscountsEl>
        </TabPanel>
        <TabPanel
          value={"recording"}
          index={1}
          style={{ width: "100%", padding: "2em 0" }}
        >
          <Typography
            variant={"h5"}
            style={{ marginBottom: 16, marginTop: 16 }}
          >
            Base Recording Price:{" "}
            {!recordingPriceMatrix?.DEF
              ? "NOT_SET"
              : `${currency} ${recordingPriceMatrix.DEF}`}
          </Typography>
          <Form
            formType="text"
            name={`${id}-base-recording-price`}
            value={recordingPriceMatrix?.DEF}
            submitRequest={async (val) =>
              await editBroadcastClass({
                recordingPriceMatrix: {
                  ...recordingPriceMatrix,
                  DEF: Number(val),
                },
              })
            }
            resetOnSubmit={true}
            label={`Update Base Recording Price (${currency})`}
          />
          {Boolean(recordingPriceMatrix.DEF) && (
            <>
              <hr style={{ margin: "3em 0", opacity: 0.5 }} />
              <Typography
                variant={"h5"}
                style={{ marginBottom: 16, marginTop: 32 }}
              >
                Country Specific Prices
              </Typography>
              <CountryDiscountForm
                submitRequest={async (val) =>
                  await editBroadcastClass({
                    recordingPriceMatrix: { ...recordingPriceMatrix, ...val },
                  })
                }
                items={getCurrencyList().filter((x) => x.value !== "DEF")}
                textLabel={"Price"}
                outputSchema={({ Value, Currency }) => ({
                  [`${Currency}`]: Value,
                })}
              />

              <DiscountsEl>
                {Object.entries(recordingPriceMatrix).map(([key, val]) =>
                  key === "DEF" ? null : (
                    <DiscountEl
                      key={`${id}-${key}`}
                      onClick={() => {
                        console.log({ key, val });
                      }}
                    >
                      <H4>{key}</H4>
                      <p>{val}</p>
                      <IconButton
                        onClick={async () => {
                          const matrix = { ...recordingPriceMatrix };
                          delete matrix[key];

                          toggleProcessing(true);

                          await editBroadcastClass({
                            recordingPriceMatrix: matrix,
                          });

                          toggleProcessing(false);
                        }}
                        disabled={processing}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </DiscountEl>
                  )
                )}
              </DiscountsEl>
              <Typography
                variant={"h5"}
                style={{ marginBottom: 16, marginTop: 32 }}
              >
                Discounts
              </Typography>
              <CountryDiscountForm
                submitRequest={async (val) => {
                  return await editBroadcastClass({
                    recordingDiscount: { ...recordingDiscount, ...val },
                  });
                }}
                items={getCurrencyList()}
                textLabel={"Discount (%)"}
                outputSchema={({ Value, Currency }) => ({
                  [`${Currency}`]: Value,
                })}
                maxLimit={100}
              />
              <DiscountsEl>
                {Object.entries(recordingDiscount)
                  .filter(([, dis]) => Boolean(dis))
                  .map(([key, val]) => (
                    <DiscountEl key={`${id}-${key}`}>
                      <H4>{key}</H4>
                      <p>{val}%</p>
                      <IconButton
                        onClick={async () => {
                          const matrix = { ...recordingDiscount };
                          delete matrix[key];

                          toggleProcessing(true);

                          await editBroadcastClass({
                            recordingDiscount: matrix,
                          });

                          toggleProcessing(false);
                        }}
                        disabled={processing}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </DiscountEl>
                  ))}
              </DiscountsEl>
            </>
          )}
        </TabPanel>
      </TabContext>
    </PricingEl>
  );
}

export default Pricing;
