export const templates = [
  "TEST_TEMPLET_03",
  "SUBSCRIPTION_INVITATION_FEB_01_15",
  "SUBSCRIPTION_INVITATION_JUL_05",
];

export const FILTER_KEYS = [
  "id",
  "inviteeId",
  "inviteeEmail",
  "inviteeName",
  "inviterEmail",
  "inviterId",
  "invitationTime",
  "consumptionTime",
];

export const invitationTableHeaders = [
  { id: "inviteeId", label: "Invitee Id", width: 80 },
  { id: "inviteeEmail", label: "Email of Invitee" },
  { id: "inviterEmail", label: "Email of Inviter" },
  { id: "invitationTime", label: "Date of Invitation" },
  { id: "status", label: "Status" },
  { id: "consumptionTime", label: "Date of Consumption" },
];
