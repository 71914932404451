import React from "react";
import CountryDiscountForm from "../../../../../components/forms/specificForms/countryDiscounts";
import { H3, H4, H5 } from "../../../../../styles/typography";
import { DiscountEl, DiscountsEl } from "../../broadcastClass/elements";
import { getCurrencyList } from "../../../../../helpers/dataParsers";
import CourseStore from "../store/courseStore";
import { PricingEl } from "../elements";

function Pricing() {
  const {
    curRequest: request,
    addDiscount,
    courseEditRequest,
  } = CourseStore.useContainer();

  return (
    <PricingEl>
      <div className="grp">
        <H3>Prices</H3>
        <H5>
          Default Price: {request.fee?.currency}
          {request.fee?.amount}
        </H5>
        <CountryDiscountForm
          submitRequest={async (data) =>
            await courseEditRequest({
              priceMatrix: { ...request.priceMatrix, ...data },
            })
          }
          items={getCurrencyList()}
          textLabel={"Price"}
          outputSchema={({ Value, Currency }) => ({
            [`${Currency}`]: Value,
          })}
        />
        <DiscountsEl>
          {Object.entries(request.priceMatrix).map(([key, val]) => (
            <DiscountEl key={`${request.id}-price-${key}`}>
              <H4>{key}</H4>
              <p>{val}</p>
            </DiscountEl>
          ))}
        </DiscountsEl>
      </div>
      <div className="grp">
        <H3>Discounts</H3>
        <CountryDiscountForm
          submitRequest={async (val) => await addDiscount(val)}
          items={getCurrencyList()}
          textLabel={"Discount (%)"}
          outputSchema={({ Value, Currency }) => ({
            [`${Currency}`]: {
              percentage: Value,
            },
          })}
          maxLimit={100}
        />
        <DiscountsEl>
          {Object.entries(request.countryDiscounts).map(([key, val]) => (
            <DiscountEl key={`${request.id}-${key}`}>
              <H4>{key}</H4>
              <p>{val.percentage}%</p>
            </DiscountEl>
          ))}
        </DiscountsEl>
      </div>
    </PricingEl>
  );
}

export default Pricing;
