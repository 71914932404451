import React, { useEffect, useRef } from 'react'

const ProtectedVideo = ({ url, name, otp, playbackInfo }) => {
  console.log({ url, name, otp, playbackInfo })
  const video = useRef(null)

  useEffect(() => {
    ;(function (v, i, d, e, o) {
      v[o] = v[o] || {}
      v[o].add =
        v[o].add ||
        function V(a) {
          ;(v[o].d = v[o].d || []).push(a)
        }
      if (!v[o].l) {
        v[o].l = 1 * new Date()
        const a = i.createElement(d)
        const m = i.getElementsByTagName(d)[0]
        a.async = 1
        a.src = e
        m.parentNode.insertBefore(a, m)
      }
    })(
      window,
      document,
      'script',
      'https://player.vdocipher.com/playerAssets/1.6.10/vdo.js',
      'vdo',
    )
    window.vdo.add({
      otp,
      playbackInfo,
      theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
      container: video.current,
    })
  }, [url, otp, playbackInfo, name])

  return (
    <div className='protectedVideo'>
      <div className='video' ref={video} />
    </div>
  )
}

export default ProtectedVideo
