import { useCallback, useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import useSWR from "swr";
import {
  dataToTab,
  getFilteredData,
  teacherDataParser,
} from "../../../../../helpers/dataParsers";
import { useParams } from "../../../../../utils/hooks/useParams";
import { navigate } from "@reach/router";
import { TeacherApi } from "../../../../../api/teacher";
import { Notification } from "../../../../../modules/toastNotification";

const KEYS_TO_FILTER = ["status"];

const TeacherStore = createContainer(() => {
  const API = TeacherApi;
  const params = useParams("/dashboard/teachers/:filter/:requestId");
  const [curRequest, setcurRequest] = useState(null);
  const {
    data: allRequests,
    mutate,
    error,
  } = useSWR([
    "admin/teacher-info/become-teacher-requests",
    "protected",
    teacherDataParser,
  ]);

  const [instruments, updateInstruments] = useState([]);
  const [languages, updateLanguages] = useState([]);

  useEffect(() => {
    (async () => {
      const [instruments = [], languages = []] = await Promise.all([
        TeacherApi.getInstruments(),
        TeacherApi.getLanguages(),
      ]);

      updateInstruments(instruments);
      updateLanguages(languages);
    })();
  }, []);

  useEffect(() => {
    if (params?.requestId !== undefined && allRequests) {
      const req = allRequests.find(({ id }) => id === params.requestId);
      setcurRequest(req);
    }
  }, [allRequests, setcurRequest, params]);

  const filteredRequests = useMemo(() => {
    if (allRequests) {
      const filteredData = getFilteredData(
        params.filter,
        allRequests,
        KEYS_TO_FILTER
      );
      return dataToTab(filteredData);
    } else return null;
  }, [allRequests, params.filter]);

  const updateRequestStatus = useCallback(
    async (status, msg) => {
      const { success } = await API?.updateRequestStatus(
        params.requestId,
        status,
        msg
      );
      if (success) {
        await mutate();
        setcurRequest(null);
        navigate("../", { replace: true });
      }
    },
    [setcurRequest, API, params, mutate]
  );

  const toggleMaster = useCallback(
    async (status) => {
      const { success } = await API?.masterToggleRequest(
        params.requestId,
        status
      );
      if (success) {
        await mutate();
      }
    },
    [mutate, params, API]
  );

  const togglePrivate = useCallback(
    async (status) => {
      const { success } = await API?.privateToggleRequest(
        params.requestId,
        status
      );
      if (success) {
        await mutate();
      }
    },
    [API, mutate, params]
  );

  const deleteRequest = useCallback(async () => {
    const { success } = await API?.deleteRequest(params.requestId);
    if (success) {
      await mutate();
      setcurRequest(null);
      navigate("../", { replace: true });
    }
  }, [API, params, setcurRequest, mutate]);

  const updateCoverImage = useCallback(
    async (image) => {
      const { success } = await API?.setCoverImage(params.requestId, image.id);
      if (success) {
        await mutate();
      }
    },
    [API, params, mutate]
  );

  const changeArtistType = useCallback(
    async (type) => {
      const { success } = await API?.changeArtistType(params.requestId, type);
      if (success) {
        await mutate();
        Notification("success", `Successfully set Artist Type to ${type}`);
      }
    },
    [API, params, mutate]
  );

  const changeSessionTypes = useCallback(
    async (data) => {
      const { success } = await API?.changeSessionTypes(params.requestId, data);
      if (success) {
        await mutate();
        Notification("success", `Successfully set Session Types to ${data}`);
      }
    },
    [API, params, mutate]
  );

  const uploadTeacherCover = useCallback(
    async (file) => {
      const { success } = await API?.uploadTeacherCover(curRequest.id, file);
      if (success) {
        await mutate();
      }
    },
    [API, curRequest, mutate]
  );

  const updateCoverVideo = useCallback(
    async (url) => {
      const { success } = await API?.updateVideo(curRequest.id, url);
      if (success) {
        await mutate();
      }
    },
    [API, curRequest, mutate]
  );

  const deleteTeacherCover = useCallback(
    async ({ id: imageId, imageType = "raw" }) => {
      const { success } = await API?.deleteTeacherCover(
        curRequest.id,
        imageId,
        imageType
      );
      if (success) {
        await mutate();
      }
    },
    [curRequest, API, mutate]
  );

  const updateScore = useCallback(
    async (score) => {
      const { success } = await API?.updateScore(curRequest.id, score);
      if (success) {
        await mutate();
      }
    },
    [API, curRequest, mutate]
  );

  const lobbyAssetUpload = useCallback(
    async (data) => {
      const { success } = await API?.lobbyAssetUpload(curRequest.id, data);

      if (success) await mutate();
    },
    [API, curRequest, mutate]
  );

  const deleteLobbyAsset = useCallback(
    async ({ id: fileId }) => {
      const { success } = await API?.deleteLobbyAsset(curRequest.id, fileId);
      if (success) await mutate();
    },
    [API, curRequest, mutate]
  );

  const lobbyAssetUpdate = useCallback(
    async (fileId, newWeight) => {
      const { success } = await API?.lobbyAssetUpdate(
        curRequest.id,
        fileId,
        newWeight
      );
      if (success) await mutate();
    },
    [API, curRequest, mutate]
  );

  const endorsementUpdate = useCallback(
    async (endorsements) => {
      const { success } = await API?.updateEndorsements(
        curRequest?.id,
        endorsements
      );

      if (success) {
        await mutate();
      }

      return success;
    },
    [API, curRequest, mutate]
  );

  const editTeacher = useCallback(
    async ({ description }) => {
      const { success } = await API?.editTeacher(curRequest?.id, {
        description,
      });

      if (success) {
        await mutate();
      }

      return success;
    },
    [API, curRequest, mutate]
  );

  return {
    loading: !filteredRequests,
    loadFailed: Boolean(error),
    requestLoading: !curRequest,
    instruments,
    languages,
    allRequests,
    filteredRequests,
    curRequest,
    params,
    toggleMaster,
    togglePrivate,
    updateRequestStatus,
    deleteRequest,
    updateCoverImage,
    updateCoverVideo,
    uploadTeacherCover,
    deleteTeacherCover,
    changeArtistType,
    changeSessionTypes,
    updateScore,
    lobbyAssetUpload,
    deleteLobbyAsset,
    lobbyAssetUpdate,
    endorsementUpdate,
    editTeacher,
  };
});

export default TeacherStore;
