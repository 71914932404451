export const streamOptions = [
	{
		name: "fb",
		urlFieldName: "fb-url",
		url: "rtmps://live-api-s.facebook.com:443/rtmp/",
		keyFieldName: "fb-key",
	},
	{
		name: "yt",
		urlFieldName: "yt-url",
		url: "rtmp://a.rtmp.youtube.com/live2",
		keyFieldName: "yt-key",
	},
];

export const classExtendOptions = [...Array(13)].map((_, i) => ({
	label: `${i * 10} minutes`,
	value: i * 10,
}));

export const addLobbyAssetOptions = (func1) => [
	{
		id: "add-url",
		name: "Add URL",
		func: () => func1("URL"),
	},
	{
		id: "upload-file",
		name: "Upload File",
		func: () => func1("UPLOAD"),
	},
	{
		id: "teacher-lobby",
		name: "Add from Teacher's lobby",
		func: () => func1("TEACHER"),
	},
];

export const preRollOptions = (data) => {
	return data.map((dt) => ({
		value: {
			id: dt?.id,
			type:
				dt?.type === "live" || dt?.type === "recorded" ? "course" : dt?.type,
			name: dt?.profile?.name,
		},
		label: dt?.profile?.name,
	}));
};
export const premuimOptions = (data) => {
	return data.map((dt) => ({
		value: dt?.id,
		label: dt?.info?.title,
	}));
};
