import { useCallback, useState } from "react";

export const useToggle = (initialState = false) => {
  const [state, toggle] = useState(Boolean(initialState));

  const toggleFn = useCallback(
    (payload) =>
      toggle(
        typeof payload === "function"
          ? payload(state)
          : payload !== undefined
          ? Boolean(payload)
          : !state
      ),
    [state]
  );

  return [state, toggleFn];
};
