import React from "react";
import { getYupResolver } from "../../helpers/yupResolvers";
import InlineForm from "./components/inlineForm";
import ToggleForm from "./components/toggleForm.js";
import ListForm from "./components/listForm.js";
import StatusUpdateForm from "./components/statusUpdateForm.js";
import ListSubmitForm from "./components/listSubmitForm";

function Form({ formType, ...rest }) {
  const resolver = getYupResolver(formType, rest.name);

  switch (formType) {
    case "url-notReq":
    case "url":
    case "rtf":
      return <InlineForm resolver={resolver} {...rest} />;
    case "text":
      return <InlineForm resolver={resolver} {...rest} />;
    case "toggle":
      return <ToggleForm {...rest} />;
    case "list":
      return <ListForm {...rest} />;
    case "list-with-submit":
      return <ListSubmitForm {...rest} />;
    case "status-update":
      return <StatusUpdateForm {...rest} />;
    default:
      return null;
  }
}

export default Form;
