import React from "react";
import AuthStore from "../../app/auth/stores/authStore";
// import user from "../../assets/icons/topnav/user.svg";
import logo from "../../assets/logo/logo-white.svg";
import {
  LeftItemsEl,
  LogoEl,
  RightItemsEl,
  TopNavEl,
  LogoutEl,
} from "./elements";

const TopNav = () => {
  const { user, logout } = AuthStore.useContainer();

  return (
    <TopNavEl>
      <LeftItemsEl></LeftItemsEl>
      <LogoEl src={logo} />
      <RightItemsEl>
        {user.id ? <LogoutEl onClick={() => logout()}>Logout</LogoutEl> : ""}
      </RightItemsEl>
    </TopNavEl>
  );
};

export default TopNav;
