import { permissions } from "../app/dashboard/config/permissions";
import { RedirectStates } from "../config";

export default function checkPermission(role, location) {
  return role in permissions[location].roles
    ? RedirectStates.show
    : RedirectStates.redirect;
  // if (role in permissions[location].roles) return RedirectStates.show;
  // else return
  // const loc = location.split("/");
  // loc.shift();
  // if (loc.length <= 1) return 0;
  // if (loc[0] === "dashboard") {
  //   if (loc[1] in permissions) {
  //     const allowed = permissions[loc[1]].roles;
  //     return role in allowed ? 0 : permissions[loc[1]].redirectUrl;
  //   } else return "/login";
  // }
  // return 0;
}
