import React from "react";
import ContentEditor from "../../modules/content-editor";

const Test = () => {
  return (
    <div>
      <ContentEditor
        bcId={"-MkQIrxzevmZ546UpFgl"}
        bcType={"concert"}
        content={{
          "-MkanDbq750l-7N273i3": {
            content: {
              "-MkcDqkDF7OyfMKM057l": {
                createdAt: 1632762555426,
                description: "whatever",
                id: "-MkcDqkDF7OyfMKM057l",
                primaryVideo:
                  "https://s3.us-east-1.amazonaws.com/livdemybucket/sample-video+-+Copy.mp4%232021%239%2327%23undefined",
                title: "Lesson 1",
                updatedAt: 1632762555426,
                videoId: "ee30fffa16f240db99a0c856ec1824e4",
              },
            },
            createdAt: 1632738535950,
            description: "whatever",
            id: "-MkanDbq750l-7N273i3",
            primaryVideo:
              "https://s3.us-east-1.amazonaws.com/livdemybucket/sample.mp4%232021%239%2326%23undefined",
            title: "chapter 1",
            updatedAt: 1632738535950,
            videoId: "12351eca8b17409b821717121d3882cf",
          },
          "-MkbKsXJlsugCt9pXPBY": {
            createdAt: 1632747620523,
            description: "whatever whoever",
            id: "-MkbKsXJlsugCt9pXPBY",
            primaryVideo:
              "https://s3.us-east-1.amazonaws.com/livdemybucket/sample-video.mp4%232021%239%2327%23undefined",
            title: "Chapter 2",
            updatedAt: 1632747620523,
            videoId: "15cafa15b00445bcb7f9aa86fa1a376b",
          },
        }}
      />
    </div>
  );
};

export default Test;
