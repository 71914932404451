import React from "react";
import PropTypes from "prop-types";
import { Match } from "@reach/router";

const TabMap = ({ tabs = [], tabStyle, ...rest }) => {
  // const location = useLocation().pathname;
  // const params = useParams();
  // console.log(useLocation(), useParams());
  const fixedTabs = tabs.filter((tab) => tab?.tabType === "fixed");
  tabs = tabs.filter((tab) => tab?.tabType !== "fixed");

  return (
    <>
      {tabs.length === 0
        ? "No Results"
        : tabs.map(({ meta, ...tab }) => {
            return (
              <Match key={tab.key} path={tab?.matchRoute || tab?.route}>
                {({ match }) =>
                  tabStyle({
                    route: tab?.route,
                    isActive: Boolean(match),
                    ...meta,
                    ...rest,
                  })
                }
              </Match>
            );
          })}
      {fixedTabs.length > 0 && (
        <>
          <hr />
          {fixedTabs.map(({ meta, ...tab }) => {
            return (
              <Match key={tab.key} path={tab?.matchRoute || tab?.route}>
                {({ match }) =>
                  tabStyle({
                    route: tab?.route,
                    isActive: Boolean(match),
                    ...meta,
                    ...rest,
                  })
                }
              </Match>
            );
          })}
        </>
      )}
    </>
  );
};

export default TabMap;

TabMap.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      meta: PropTypes.any,
    })
  ).isRequired,
};
