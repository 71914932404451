import React from "react";
import {
  CoverImageEl,
  CoverImagesEl,
  CoverImageTextEl,
  FileUploadModalEl,
} from "../../../elements";
import FileUploader from "../../../../../modules/fileUploader";
import Modal from "../../../../../components/modal";
import TeacherStore from "../store/teacherStore";

function CoverImages() {
  const {
    updateCoverImage,
    uploadTeacherCover,
    deleteTeacherCover,
    curRequest: request,
  } = TeacherStore.useContainer();
  return (
    <>
      <Modal
        title="Upload"
        content={({ modalOpen }) => (
          <FileUploadModalEl>
            <FileUploader
              files={request.coverImages}
              uploadSuccess={async (file) => {
                await uploadTeacherCover(file);
                modalOpen(false);
              }}
              deleteFile={async (img) => await deleteTeacherCover(img)}
            />
          </FileUploadModalEl>
        )}
      />
      <CoverImagesEl>
        {request.coverImages.map((image) => (
          <CoverImageEl
            key={`cover-img-${image.id}`}
            onClick={async () => await updateCoverImage(image)}
          >
            <img alt="Teacher Cover" src={`${image.url}`} />
            <CoverImageTextEl show={image.url === request.coverImage}>
              {image.url === request.coverImage
                ? "Profile Cover Image"
                : "Set as Profile Cover"}
            </CoverImageTextEl>
          </CoverImageEl>
        ))}
      </CoverImagesEl>
    </>
  );
}

export default CoverImages;
