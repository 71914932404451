import React from "react";
import CreateIcon from "@material-ui/icons/Create";
import Input from "../../input";
import { EditDataEl } from "../elements";

function FormDataDisplay({ data, toggleEditView, ...rest }) {
  return (
    <EditDataEl>
      <p>
        <b>{rest?.label ? `${rest.label}: ` : ""} </b>
        {rest?.type === "texteditor" ? (
          <Input
            type="texteditor"
            value={data}
            theme="bubble"
            disabled
            modules={{ toolbar: false }}
          />
        ) : data ? (
          rest.displayType === "link" ? (
            <a href={`${data}`} target="_blank" rel="noopener noreferrer">
              {data}
            </a>
          ) : (
            data
          )
        ) : (
          <i>Not Set</i>
        )}
      </p>
      {!rest.disabled ? (
        <CreateIcon onClick={() => toggleEditView(false)} />
      ) : null}
    </EditDataEl>
  );
}

export default FormDataDisplay;
