import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../../../../../components/input";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useToggle } from "../../../../../utils/hooks/useToggle";
import {
  futureDate,
  string,
  isRequired,
} from "../../../../../utils/form-errors";
import Button from "../../../../../components/button";
import BroadcastClassStore from "../store/broadcastClassStore";
import date from "date-and-time";
import { DateTimeEl, TimeEl, RescheduleClassFormEl } from "../elements";
import {
  getHoursList,
  getRescheduleDefaultVal,
} from "../../../../../helpers/misc";
import { H5 } from "../../../../../styles/typography";

function RescheduleClass(props) {
  const {
    rescheduleClass,
    curRequest: { startTime },
  } = BroadcastClassStore.useContainer();
  const [submitting, setSubmitting] = useToggle(false);

  const { handleSubmit, errors, control, setError, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        rescheduleMsg: yup.string(string).required(isRequired`Message`),
      })
    ),
    defaultValues: getRescheduleDefaultVal(startTime),
  });

  useEffect(() => {
    reset(getRescheduleDefaultVal(startTime));
  }, [reset, startTime]);

  const onSub = async ({
    rescheduleMsg,
    rescheduleDate,
    hour,
    period,
    minute,
  }) => {
    const time = date
      .parse(
        `${rescheduleDate} ${hour}:${minute} ${period}`,
        "YYYY-MM-DD h:m A"
      )
      .getTime();
    if (time <= new Date().getTime()) {
      setError("time", { type: "manual", message: futureDate });
    } else {
      setSubmitting(true);
      await rescheduleClass(time, rescheduleMsg);
      reset(getRescheduleDefaultVal(startTime));
      setSubmitting(false);
    }
  };

  return (
    <>
      <H5>Reschedule Class:</H5>
      <RescheduleClassFormEl onSubmit={handleSubmit(onSub)}>
        <DateTimeEl>
          <Controller
            as={<Input inputType="date" label="Date" />}
            control={control}
            name="rescheduleDate"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TimeEl>
            <Controller
              control={control}
              as={<Input type="list" />}
              name="hour"
              label="Hour"
              items={getHoursList()}
            />
            <Controller
              control={control}
              as={<Input type="list" />}
              name="minute"
              label="Minute"
              items={[
                { name: "00", value: 0 },
                { name: "30", value: 30 },
              ]}
            />
            <Controller
              control={control}
              as={<Input type="list" />}
              name="period"
              label=""
              items={[
                { name: "AM", value: "AM" },
                { name: "PM", value: "PM" },
              ]}
            />
          </TimeEl>
        </DateTimeEl>
        <Controller
          as={<Input type="texteditor" />}
          control={control}
          name="rescheduleMsg"
          error={errors.rescheduleMsg}
          placeholder="Enter message here"
        />
        {(errors?.rescheduleMsg?.message || errors?.time?.message) && (
          <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
            {errors?.rescheduleMsg?.message} {errors?.time?.message}
          </p>
        )}
        <br />
        <Button type="submit" disabled={props?.disabled || submitting}>
          Submit
        </Button>
      </RescheduleClassFormEl>
    </>
  );
}

export default RescheduleClass;
