import React from "react";
import CourseStore from "../../store/courseStore";
import Batch from "./batch";

function Batches() {
  const {
    curRequest: { batches },
  } = CourseStore.useContainer();

  return (
    <div style={{ position: "relative" }}>
      {batches.length < 1
        ? "No Batches"
        : batches.map((batchInfo) => (
            <Batch key={batchInfo.id} data={batchInfo} />
          ))}
    </div>
  );
}

export default Batches;
