import styled from "styled-components";
import { greyBorder } from "../../../../../../../styles/colors";
import {
  hFlex,
  overlay,
  vFlex,
} from "../../../../../../../styles/style-helpers";
import { H2, H3, H5 } from "../../../../../../../styles/typography";

export const AllBannersEl = styled.div`
  width: 100%;
  ${vFlex}
  padding: 5em 2em 2em 2em;
  justify-content: flex-start;
  position: relative;
  overflow: auto;

  .createBtn {
    position: absolute;
    left: 1.5em;
    top: 1.5em;
  }
`;

export const BannerEl = styled.div`
  padding: 1.5em;
  border: 1px solid ${greyBorder};
  border-radius: 10px;
  margin-bottom: 1.5em;
  position: relative;
  width: 80%;
  background: ${({ editing }) => (editing ? "" : "black")};
  opacity: ${({ style, editing }) =>
    style?.deleted && !editing ? "0.5" : "1"};

  .edit-icon {
    color: white;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    cursor: pointer;
    ${hFlex}
  }

  .background {
    ${overlay};
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .info {
    width: 30%;
    height: 100%;
    margin-left: 12.5%;
    ${vFlex};
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;

    & ${H2} {
      font-weight: bold;
      color: white;
      margin-bottom: 1em;

      & > span {
        overflow: hidden;
        display: block;

        & > span {
          display: block;
          white-space: nowrap;
        }
      }
    }

    & ${H5} {
      color: white;
      opacity: 0.75;
      line-height: 1.5;
      margin-bottom: 2em;
    }
  }
`;

export const BannerFormEl = styled.form`
  width: 100%;

  ${H3} {
    text-align: center;
  }

  ${H5} {
    margin: 0.4em 0;
  }

  .image-input {
    ${hFlex}
    justify-content: flex-start;

    .uploaded-img {
      ${hFlex}

      img {
        width: 5em;
        margin-right: 1em;
      }
    }
    > button {
      margin-left: 1.5em;
    }
  }

  .position-input {
    width: 45%;
    margin: 0.5em 0;
  }

  .content {
    ${hFlex}
    justify-content: space-between;

    > div {
      width: 45%;
    }
    .content-button {
      > div {
        margin-bottom: 0.5em;
      }
    }
  }

  > button {
    margin: 1em 1em 0 0;
  }

  .error {
    color: red;
    font-size: 0.75em;
  }
`;
