import { serviceWrapper } from "./helper";
import { authenticated } from "./services/authenticated";
import { base } from "./services/base";
import { core } from "./services/core";

export const slsPublicBaseUrl = process.env.REACT_APP_PUBLIC_API_URL;
export const slsCoreBaseUrl = process.env.REACT_APP_SLS_API_URL;

export const Api = {
  public: serviceWrapper(base),
  protected: serviceWrapper(authenticated),
  core: serviceWrapper(core),
};
