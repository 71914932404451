import React from "react";
import { Router, Redirect } from "@reach/router";
import ReviewStore from "./store/reviewStore";
import { ActiveSecEl, FilterNavEl } from "../../elements";
import TabMap from "../../../../components/tabMap";
import { reviewFilterTabs } from "../../config/routes";
import FilterTab from "../common/filterTab";
import ReviewContent from "./components/reviewContent";
import ReviewStatusbar from "./components/reviewStatusbar";
import RequestView from "../requestView";

function Reviews() {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        <TabMap
          tabs={reviewFilterTabs}
          tabStyle={(props) => <FilterTab {...props} />}
        />
      </FilterNavEl>
      <ReviewStore.Provider>
        <Router component={ActiveSecEl}>
          <Redirect from="/" to="teachers" noThrow />
          <RequestView
            path=":filter/*"
            contentComponent={ReviewContent}
            statusComponent={ReviewStatusbar}
            Store={ReviewStore}
          />
        </Router>
      </ReviewStore.Provider>
    </ActiveSecEl>
  );
}

export default Reviews;
