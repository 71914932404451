import React from "react";
import {
  CoverImageEl,
  CoverImagesEl,
  CoverImageTextEl,
} from "../../../elements";
import { H5 } from "../../../../../styles/typography";
import CourseStore from "../store/courseStore";

function Cover() {
  const {
    setCourseCover,
    curRequest: { coverImages, teacherId, courseCover },
  } = CourseStore.useContainer();
  return (
    <div style={{ marginBottom: "1em" }}>
      <H5>Course Cover:</H5>
      <CoverImagesEl>
        {coverImages.map((img) => (
          <CoverImageEl
            key={`cover-img-${img.id}`}
            onClick={async () => await setCourseCover(teacherId, img)}
          >
            <img alt="Course Cover" src={`${img.url}`} />
            <CoverImageTextEl show={img.url === courseCover}>
              {img.url === courseCover
                ? "Profile Cover Image"
                : "Set as Profile Cover"}
            </CoverImageTextEl>
          </CoverImageEl>
        ))}
      </CoverImagesEl>
      {/* {type === "recorded" && (
        <Form
          formType="url-notReq"
          name={`${id}-coverVideoUrl`}
          defaultVal={coverVideoUrl}
          submitRequest={async (val) =>
            await courseEditRequest({ coverVideoUrl: val })
          }
          editToggle={true}
          label="Cover Video"
          displayType="link"
        />
      )} */}
    </div>
  );
}

export default Cover;
