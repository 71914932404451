import React from "react";
import { StatusBarEl, StatusBarHeadEl } from "../../../elements";
import ReviewStore from "../store/reviewStore";
import Button from "../../../../../components/button";

function ReviewStatusbar() {
  const { curRequest: request, updateRequest } = ReviewStore.useContainer();
  return (
    <StatusBarEl>
      <StatusBarHeadEl>
        <div>
          Status: <b>{request.status}</b>
        </div>
        <Button
          onClick={async () =>
            await updateRequest(request.type, {
              sk: request.sk,
              pk: request.pk,
              statusSk: request.statusSk,
              status: request.status !== "APPROVED" ? "APPROVED" : "REJECTED",
            })
          }
        >
          {request.status !== "APPROVED" ? "Approve" : "Reject"}
        </Button>
        <Button
          onClick={async () =>
            await updateRequest(request.type, {
              sk: request.sk,
              pk: request.pk,
              statusSk: request.statusSk,
              status:
                request.status !== "WHITELISTED" ? "WHITELISTED" : "APPROVED",
            })
          }
        >
          {request.status === "WHITELISTED" ? "Remove from whitelist" : "Whitelist"}
        </Button>
      </StatusBarHeadEl>
    </StatusBarEl>
  );
}

export default ReviewStatusbar;
