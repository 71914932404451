import styled from "styled-components";
import { hFlex, square } from "../../../styles/style-helpers";

export const TopNavEl = styled.div`
  height: 7.5vh;
  width: 100%;
  ${hFlex};
  justify-content: space-between;
  background: #232323;
`;

export const LeftItemsEl = styled.div`
  flex: 1;
  ${hFlex};
  justify-content: flex-start;
  padding-left: 1em;
`;

export const RightItemsEl = styled.div`
  flex: 1;
  ${hFlex};
  justify-content: flex-end;
  padding-right: 1em;
`;

export const LogoutEl = styled.button`
  color: white;
  font-size: 1em;
  background: none;
  border: 0;
  cursor: pointer;
`;

export const LogoEl = styled.img`
  height: 66%;
`;

export const UserIconEl = styled.img`
  ${square("3.25vh")};
`;
