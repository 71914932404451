import React, { useState } from "react";
import {
  Table as TableComp,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { getComparator, stableSort } from "./helpers";
import { useStyles, TableCellEl } from "./elements";

function Table({
  data = [],
  headers = [],
  rowCellModifier,
  defOrder = "asc",
  defOrderBy = "id",
  ...props
}) {
  const classes = useStyles();
  const [order, setOrder] = useState(defOrder);
  const [orderBy, setOrderBy] = useState(defOrderBy);
  const createSortHandler = (property) => (e) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableComp {...props}>
        <TableHead>
          <TableRow>
            {headers.map(({ id, label, sortable = true, ...rest }) => (
              <TableCellEl
                key={`${id}`}
                sortDirection={orderBy === id ? order : false}
                {...rest}
              >
                <TableSortLabel
                  active={orderBy === id}
                  hideSortIcon={!sortable}
                  className={classes.sortLabel}
                  direction={orderBy === id ? order : "asc"}
                  onClick={sortable ? createSortHandler(id) : null}
                >
                  {label}
                  {orderBy === id && sortable ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCellEl>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0
            ? "No Results"
            : stableSort(data, getComparator(order, orderBy)).map((row, j) => (
                <TableRow key={`row-${j}`}>
                  {headers.map((header, i) => (
                    <TableCellEl
                      key={`row-${j}-val-${i}`}
                      width={header?.width}
                    >
                      {rowCellModifier(row, header.id)}
                    </TableCellEl>
                  ))}
                </TableRow>
              ))}
        </TableBody>
      </TableComp>
    </TableContainer>
  );
}

export default Table;
