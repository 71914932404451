import { map, pipe } from "ramda";
import useSWR from "swr";
import { createContainer } from "unstated-next";
import Subscription from "../../../../../schemas/subscription";

const key = ["admin/subscriptions", "protected", pipe(map(Subscription))];

const SubscriptionStore = createContainer(() => {
  const { data: subscriptions, mutate, error } = useSWR(key);

  return {
    loading: !subscriptions,
    loadFailed: Boolean(error),
    subscriptions,
    mutate,
    // sidebarTabs: createSubscriptionTabs(subscriptions)
  };
});

export default SubscriptionStore;
