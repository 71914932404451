import { createGlobalStyle } from "styled-components";
import { basicType } from "./typography";

const GlobalStyles = createGlobalStyle`
  *{
    ${basicType};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
  };
  
  html, body, .App {
    height: 100%;
    width: 100%;
    overflow: auto;
  };
`;

export default GlobalStyles;
