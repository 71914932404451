import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Form from "../../../../../components/forms";
import SchoolStore from "../store/schoolStore";
import { CoHostEl } from "../../broadcastClass/elements";
import { type } from "ramda";

function RecordedCourses() {
	const {
		curRequest: request,
		courses = [],
		addCourse,
		deleteCourse,
		teachers = [],
	} = SchoolStore.useContainer();

	const coHosts = teachers
		.filter((teacher) => request?.teachers?.hasOwnProperty(teacher.id))
		.map(({ id, name }) => ({ id, name }));

	const Courses = courses
		.filter((course) => {
			return request?.courses
				? request?.courses?.recorded
					? request?.courses?.recorded?.hasOwnProperty(course.id)
					: false
				: false;
		})
		.map(({ id, name }) => ({ id, name }));

	const courseList = courses
		.map(({ id, name, teacherId, type }) => ({
			label: name,
			value: id,
			teacherId: teacherId,
			type: type,
		}))
		.filter(({ value, teacherId, type }) => {
			return (
				!request?.courses?.recorded?.hasOwnProperty(value) &&
				type === "recorded" &&
				coHosts.some((teacher) => teacher.id === teacherId)
			);
		});

	return (
		<>
			<Form
				formType="list"
				name={`${request.id}-courses`}
				options={courseList}
				changeRequest={async ({ value }) => await addCourse(value, "recorded")}
				resetOnSubmit={true}
				placeholder="Add Courses"
			/>
			<CoHostEl>
				<ol>
					{Courses.map((val, i) => (
						<li key={`course-${i}`}>
							{val.name}
							<Button onClick={async () => deleteCourse(val.id, "recorded")}>
								Remove Course
							</Button>
						</li>
					))}
				</ol>
			</CoHostEl>
		</>
	);
}

export default RecordedCourses;
