import styled, { css } from "styled-components";
import { Link } from "@reach/router";
import {
  flex,
  hFlex,
  modalBox,
  overlay,
  vFlex,
} from "../../../styles/style-helpers";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
} from "@material-ui/core";

export const SectionEl = styled.div`
  ${hFlex};
  align-items: unset;
  justify-content: space-around;
  width: 100%;
  height: calc(100vh - 7.5vh);
`;

export const ActiveSecEl = styled.div`
  ${vFlex};
  position: relative;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 1em;
`;

export const ActiveSecContentEl = styled.div`
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: unset;
  overflow: auto;
`;

export const DashboardSectionEl = styled(ActiveSecContentEl)`
  box-shadow: 0 0 2em rgba(0, 0, 0, 0.35);
`;

export const PrimarySidebarEl = styled.div`
  padding-top: 2em;
  height: 100%;
  width: 12.5%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: auto;
  background: white;

  > p {
    cursor: pointer;
  }
`;

export const PrimaryTabEl = styled.p`
  position: relative;
  width: 100%;
  padding: 1em 1em;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  margin-bottom: 1em;
  color: inherit;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #fff;
          box-shadow: -1em 0 1em rgba(0, 0, 0, 0.35);
        `
      : ""};
`;

export const CourseInfoEl = styled.div`
  ${vFlex};
  justify-content: flex-start;
  align-items: flex-start;

  > p {
    margin: 0.1em 0;

    b {
      margin-right: 0.5em;
    }
  }
`;

export const FilterTabEl = styled.p`
  position: relative;
  color: #c7402d;
  font-size: 1.25em;
  margin-right: 3em;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  letter-spacing: 0.025em;
  padding-bottom: 0.25em;
  cursor: pointer;
  transition: border-bottom-color 0.15s ease;
  ${({ isActive }) =>
    isActive
      ? css`
          border-bottom: 2px solid #c7402d;
        `
      : ""};
`;

export const SearchTabEl = styled(Box)`
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  div {
    z-index: 3;
    padding-top: 0.5em;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
  }

  h5 {
    margin-bottom: 0.25em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;

    button {
      z-index: 3;
      margin: 0 !important;
      padding: 0 !important;
      font-size: 2em;
    }
  }

  p {
    color: #4a4a4a;
    font-size: 0.8em;
    opacity: 0.75;
  }

  .status {
    color: #c7402d;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5em;
    font-size: 0.64em;
    text-transform: uppercase;
  }

  .expandable {
    color: #c7402d;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.5em;
    font-size: 0.64em;
    text-transform: uppercase;
  }

  .record-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 0.5em;
  }

  ${({ isactive }) =>
    isactive
      ? css`
          transform: scale(1.05);
          background-color: rgba(100, 100, 100, 0.1);
        `
      : ""};
`;

export const FilterNavEl = styled.div`
  ${hFlex};
  width: 100%;
  justify-content: flex-start;
  padding: 0 2em;
`;

export const SearchSidebarEl = styled(Grid)`
  width: 30%;
  border-right: 1px solid #cdcbcd;
  margin-top: 1em;
`;

export const SearchboxEl = styled.div`
  height: 10%;
  border-bottom: 1px solid #cdcbcd;
  padding: 0 2em 1em;

  input {
    width: 100%;
    padding: 1em;
    background: #fff;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 0.5em;
  }
`;

export const SearchTabsEl = styled.div`
  height: 90%;
  width: 100%;
  padding: 1em 2em;
  overflow: auto;
`;

export const StatusBarEl = styled(Card)`
  position: absolute;
  top: 1em;
  margin: 0 auto;
  padding: 0.1em 1em;
  width: 95%;
  background-color: white;
  z-index: 2;
  ${({ state }) =>
    state === "open"
      ? css`
          box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.5) !important;
          transform: scale(1.025);
        `
      : ""}
`;

export const StatusBarHeadEl = styled(CardContent)`
  ${flex};
  padding: 0 !important;
  height: 2.75em;

  div {
    flex: 1 1;
    font-size: 0.8em;
  }

  button {
    margin-left: 1em;
    font-size: 0.8em;
    padding: 0.56em 1em;
    border-radius: 0;
    line-height: 1;

    span {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
`;

export const CollapseEl = styled(Collapse)`
  padding: 1em 0.1em;

  textarea {
    border: 0;
  }

  button {
    margin-left: 0.5em;
  }
`;

export const ScrollSectionEl = styled(Grid)`
  position: absolute;
  top: 4.6em;
  min-height: 80%;
  max-height: 90%;
  margin: 0 auto;
  width: 95%;
  overflow: auto;
  padding: 1em 4em 2em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
`;

export const ToggleEl = styled.div`
  width: 100%;
  ${hFlex};

  div {
    justify-content: flex-start;
    width: ${({ count }) =>
      count > 0 ? `${Math.max(100 / count, 33)}%` : "100%"};
  }
`;

export const CoverImagesEl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 1.5em;
`;

export const CoverImageEl = styled.div`
  width: 30% !important;
  margin: 2em 0 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const CoverImageTextEl = styled.div`
  opacity: ${(props) => (props?.show ? 1 : 0)};
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  background: #000;
  color: #fff;
  transition: opacity 0.15s ease;

  ${CoverImageEl}:hover & {
    opacity: 1;
  }
`;

export const FileUploadModalEl = styled.div`
  ${modalBox};
  ${hFlex};
  position: relative;
  justify-content: space-around;
  overflow: hidden;
`;

export const TabLinkEl = styled(Link)`
  ${overlay};
  z-index: 2;
`;

export const DescriptionListEl = styled.div`
  ${hFlex};
  justify-content: space-around;

  div {
    ${vFlex};
    width: 50%;
    align-items: flex-start;

    ol {
      margin-left: 2em;

      li {
        margin: 0.2em 0;
      }
    }
  }
`;

export const EmailModalEl = styled.div`
  ${modalBox}
  .ql-editor {
    min-height: 10em;
  }

  .bodyBox2 {
    border: 1px solid black;
    padding: 0.5em;
    margin: 1em 0 0;
  }

  .error {
    color: red;
    font-size: 0.8em;
  }
`;

export const CloseButtonEl = styled(Button)`
  padding: 0.1em !important;
  position: absolute !important;
  min-width: 0 !important;
  top: 2%;
  right: 3%;
`;

export const FilesListModalEl = styled.div`
  ${modalBox}
  ${vFlex}
  position: relative;
  justify-content: space-around;
  overflow: hidden;
`;
