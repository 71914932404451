import { applySpec, join, juxt, map, pathOr, pipe } from "ramda";
import { getUrlFromType } from "../helpers/misc";

const recordingBillSchema = applySpec({
	earnings: pathOr({}, ["earnings"]),
	bills: pipe(
		pathOr([], ["allBills"]),
		map(
			applySpec({
				id: pathOr("", ["id"]),
				classId: pathOr("", ["broadcastClassId"]),
				userId: pathOr("", ["userId"]),
				price: pipe(
					juxt([pathOr("", ["currency"]), pathOr("", ["amount"])]),
					join(" ")
				),
				amount: pathOr("", ["amount"]),
				currency: pathOr("", ["currency"]),
				name: pathOr("", ["name"]),
				email: pathOr("", ["email"]),
				status: pathOr("", ["status"]),
				paymentGateway: pathOr("", ["paymentGateway"]),
				type: pathOr("", ["type"]),
				recordingRegistrationId: pathOr("", ["recordingRegistrationId"]),
				updatedAt: pathOr(0, ["updatedAt"]),
				classLink: pipe(
					juxt([
						pathOr("", ["livdemyId"]),
						pipe(pathOr("", ["type"]), getUrlFromType),
						pathOr("", ["url"]),
						pathOr("", ["version"]),
					]),
					join("/")
				),
			})
		)
	),
});

export default recordingBillSchema;
