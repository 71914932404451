import localforage from "localforage";
import { LOCAL_STORE_KEY } from "../../../app/auth/stores/authStore";
import { refresh } from "./refresh";
import fetch from "@apicase/adapter-fetch";
import { ApiService } from "@apicase/services";

const getAuthToken = () =>
  localforage
    .getItem(LOCAL_STORE_KEY)
    .then((value) => `Bearer ${value?.authToken}`);

export const core = new ApiService({
  url: process.env.REACT_APP_SLS_API_URL,
  adapter: fetch,
  mode: "cors",
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
  hooks: {
    before: async ({ payload, next }) => {
      const accessToken = await getAuthToken();
      payload.headers = {
        ...payload.headers,
        Authorization: `${accessToken}`,
      };
      next(payload);
    },

    fail: async ({ payload, result, retry, next }) => {
      if (result.status === 401) {
        const { success, result } = await refresh.doSingleRequest({});
        if (success) {
          const accessToken = `Bearer ${result.authToken}`;
          payload.headers = {
            ...payload.headers,
            Authorization: `${accessToken}`,
          };

          retry(payload);
        } else {
          next(result);
        }
      } else if (result.status !== 401) {
        return next(result);
      }
    },
  },
});
