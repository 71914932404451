import styled from "styled-components";

export const LoadingCoverEl = styled.div`
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    content: "Loading...";
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
