import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { FILTER_KEYS, templates } from "../config";
import { invitationDataParser, templateDataToTab } from "../helpers";
import API from "../../../../../api/invitations";
import useSWR from "swr";
import { Notification } from "../../../../../modules/toastNotification";
import { createFilter } from "react-search-input";

const InvitationStore = createContainer(() => {
  const [templateType, setTemplateType] = useState("");
  const [query, setQuery] = useState("");

  const sidebarTabs = templateDataToTab(templates);
  // const { data: templates, error } = useSWR([
  //   "admin/invitations/TEST_TEMPLET_03",
  //   "protected",
  //   templateDataParser,
  // ]);

  const { data: allInvitations, mutate, error } = useSWR([
    templateType ? `admin/invitations/${templateType}` : null,
    "protected",
    invitationDataParser,
  ]);

  let filteredInvitations = allInvitations?.filter(
    createFilter(query, FILTER_KEYS)
  );

  const sendInvitation = useCallback(
    async (data) => {
      const { success } = await API?.sendInvite(data);
      if (success) {
        await mutate();
        Notification("success", "Invitation sent successfully.");
      }
    },
    [mutate]
  );

  return {
    loading: !filteredInvitations,
    loadFailed: Boolean(error),
    sidebarTabs,
    setTemplateType,
    sendInvitation,
    filteredInvitations,
    setQuery,
  };
});

export default InvitationStore;
