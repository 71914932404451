import date from "date-and-time";

export const capitalize = (phrase = "") =>
  phrase
    ? phrase
        .toString()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";

export const getField = (obj = {}, path, def) =>
  path
    .replace(/\[/g, ".")
    .replace(/]/g, "")
    .split(".")
    .filter(Boolean)
    .every((step) => !(step && [undefined, null].includes((obj = obj[step]))))
    ? obj
    : def;

export const generateOptions = (options = []) =>
  options.map((option) => ({ label: capitalize(option), value: option }));

export const getHoursList = () => {
  return [...Array(13)].map((val, i) => {
    return {
      name: i > 9 ? `${i}` : `0${i}`,
      value: i,
    };
  });
};

export const getRescheduleDefaultVal = (startTime) => {
  const defaultTime = new Date(startTime);
  return {
    rescheduleDate: date.format(defaultTime, "YYYY-MM-DD"),
    rescheduleMsg: "",
    hour: defaultTime.getHours() % 12 ? defaultTime.getHours() % 12 : 12,
    minute: defaultTime.getMinutes(),
    period: defaultTime.getHours() >= 12 ? "PM" : "AM",
  };
};

export const getUrlFromType = (type) =>
  type === "master-class" ? "masterclass" : type;

export const createSelectOptions = (option, label = null) => ({
  label: label || option,
  value: option,
});

export const getDaysList = () => [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getTimeZone = () =>
  new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "long" })
    .split(" ")
    .slice(2)
    .map((word) => word.charAt(0))
    .join("");

export const HrToMs = (hr) => hr * 60 * 60 * 1000;
