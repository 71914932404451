import React, { useEffect } from "react";
import { withStore } from "../../../../../../utils/withStore";
import FeaturedArtistsStore from "./store/featuredArtistsStore";
import Loading from "../../../../../../components/loading";
import ArtistCard from "./components/artistCard";
import { FeaturedArtistsEl } from "./elements";

function FeaturedArtists() {
  const {
    loading,
    loadFailed,
    init,
    featuredArtists,
  } = FeaturedArtistsStore.useContainer();

  useEffect(() => {
    (async function () {
      await init();
    })();
  }, [init]);

  return (
    <Loading loading={loading} loadFailed={loadFailed}>
      <FeaturedArtistsEl>
        <div className="artists-list">
          {featuredArtists?.map(({ id, ...artist }, index) => (
            <ArtistCard key={`${id}-${index}`} data={artist} index={index} />
          ))}
        </div>
      </FeaturedArtistsEl>
    </Loading>
  );
}

export default withStore([FeaturedArtistsStore])(FeaturedArtists);
