import React from "react";
import Modal from "../../../../../components/modal";
import FileUploader, {
  FilesList,
  UrlFileUpload,
} from "../../../../../modules/fileUploader";
import {
  FileUploadModalEl,
  EmailModalEl,
  CloseButtonEl,
} from "../../../elements";
import { ResourcesEl } from "../elements";
import BroadcastClassStore from "../store/broadcastClassStore";

function Resources() {
  const {
    resourcesUpload,
    deleteResource,
    curRequest: { documents },
  } = BroadcastClassStore.useContainer();

  return (
    <ResourcesEl>
      <div className="upload-btns">
        <Modal
          title="Upload File"
          content={({ modalOpen }) => (
            <FileUploadModalEl>
              <FileUploader
                uploadSuccess={async (file) => {
                  await resourcesUpload(file);
                  modalOpen(false);
                }}
              />
            </FileUploadModalEl>
          )}
        />
        <Modal
          title="Add Url"
          content={({ modalOpen }) => (
            <EmailModalEl>
              <CloseButtonEl onClick={() => modalOpen(false)}>X</CloseButtonEl>
              <UrlFileUpload
                submitRequest={async (data) => {
                  await resourcesUpload(data);
                  modalOpen(false);
                }}
              />
            </EmailModalEl>
          )}
        />
      </div>
      <FilesList files={documents} deleteFile={deleteResource} canView={true} />
    </ResourcesEl>
  );
}

export default Resources;
