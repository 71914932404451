import localforage from "localforage";
import { LOCAL_STORE_KEY } from "../../../app/auth/stores/authStore";
import { base } from "./base";

const REFRESH_TOKEN_API_URL = "auth/get-auth-token";

const getRefreshToken = () =>
  localforage.getItem(LOCAL_STORE_KEY).then((value) => value?.refreshToken);

const updateAuthToken = (token) =>
  localforage.getItem(LOCAL_STORE_KEY).then((value) =>
    localforage.setItem(LOCAL_STORE_KEY, {
      ...value,
      authToken: token,
    })
  );

export const refresh = base
  .extend({
    url: REFRESH_TOKEN_API_URL,
    method: "POST",
    hooks: {
      before: async ({ payload, next }) => {
        const refreshToken = await getRefreshToken();

        payload.headers = {
          ...payload.headers,
        };
        payload.body = {
          refreshToken,
        };
        next(payload);
      },
    },
  })
  .on("done", (res) => updateAuthToken(res.authToken));
