import React from "react";
import PropTypes from "prop-types";
import { PrimaryTabEl, TabLinkEl } from "../../elements";

function PrimaryTab({ text, route, ...rest }) {
  return (
    <PrimaryTabEl {...rest}>
      <TabLinkEl to={route}></TabLinkEl>
      {text}
    </PrimaryTabEl>
  );
}

export default PrimaryTab;

PrimaryTab.propTypes = {
  text: PropTypes.string.isRequired,
};
