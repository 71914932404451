import styled from "styled-components";
import Button from "../../../../../components/button";
import { LogoEl } from "../../../../../components/topNav/elements";
import { hFlex, square, vFlex } from "../../../../../styles/style-helpers";
import { H4 } from "../../../../../styles/typography";

export const LoginEl = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 10vh 0;
  ${vFlex};
`;

export const HeadingEl = styled(H4)`
  ${hFlex};
  white-space: nowrap;
  height: 2.5em;
  font-weight: bold;
  margin-bottom: 1.5em;

  & ${LogoEl} {
    transform: translateY(-10%);
    margin-left: 0.25em;
  }
`;

export const PasswordLoginEl = styled.form`
  width: 50%;
  ${vFlex};
  padding: 1em 0 2em;

  & > * {
    margin-bottom: 1.5em !important;
  }

  & > ${Button} {
    width: 100%;
    padding: 0.75em 0;
  }
`;

export const SocialLoginIconEl = styled.img`
  ${square("1em")};
  margin-right: 1em;
`;
