import React from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";

const DropdownEl = styled.div`
  width: 80%;
`;

function Dropdown({ items, label, ...props }) {
  return (
    <DropdownEl>
      <InputLabel id="input-select">{label}</InputLabel>
      <Select labelId="input-select" {...props}>
        {items.map(({ value, name }, i) => (
          <MenuItem key={`${value}-${i}`} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props?.error?.message}</FormHelperText>
    </DropdownEl>
  );
}

export default Dropdown;
