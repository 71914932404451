import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InlineFormEl } from "../elements";
import { useToggle } from "../../../utils/hooks/useToggle";
import Button from "../../button";
import Input from "../../input";
import FormDataDisplay from "./formDataDisplay";

function InlineForm({
  resolver,
  defaultVal,
  submitRequest,
  name,
  editToggle = false,
  resetOnSubmit = false,
  ...rest
}) {
  const [submitting, setSubmitting] = useToggle(false);
  const [editView, setEditView] = useState(editToggle);
  const { handleSubmit, errors, control, setValue } = useForm({
    resolver: resolver,
  });

  const onSub = async (values) => {
    setSubmitting(true);
    await submitRequest(values[name]);
    if (resetOnSubmit) setValue(name, "");
    setSubmitting(false);
    if (editToggle) setEditView(true);
  };

  return editView ? (
    <FormDataDisplay data={defaultVal} {...rest} toggleEditView={setEditView} />
  ) : (
    <InlineFormEl onSubmit={handleSubmit(onSub)}>
      <Controller
        as={<Input {...rest} />}
        control={control}
        name={name}
        rules={{ required: true }}
        error={errors[name]}
        defaultValue={defaultVal}
      />
      <Button type="submit" disabled={rest?.disabled || submitting}>
        Submit
      </Button>
    </InlineFormEl>
  );
}

export default InlineForm;
