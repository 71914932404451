import React, { useState, useRef } from "react";
import styled from "styled-components";
import {
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
  MenuList as MenuListComponent,
} from "@material-ui/core";
import { default as ItemButtonComp } from "../button";
import { vFlex } from "../../styles/style-helpers";
import { ButtonEl } from "../button/elements";

const MenuList = styled(MenuListComponent)`
  ${vFlex}
  z-index: 2;
  align-items: baseline;
`;

const ItemButton = styled(ItemButtonComp)`
  width: 100%;
  z-index: 3;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
`;

function DropdownMenu({
  title,
  items,
  withConfirmation = false,
  variant = "outlined",
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <ButtonEl
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant={variant}
        color="primary"
      >
        {title}
      </ButtonEl>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {items.map(({ id, name, func }) => (
                    <ItemButton
                      key={id}
                      onClick={async (e) => {
                        e.persist();
                        await func();
                        handleClose(e);
                      }}
                      withconfirmation={withConfirmation}
                      variant="text"
                    >
                      {name}
                    </ItemButton>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DropdownMenu;
