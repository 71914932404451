import React from "react";
import Button from "../../../../../components/button";
import {
  CloseButtonEl,
  EmailModalEl,
  StatusBarEl,
  StatusBarHeadEl,
} from "../../../elements";
import Modal from "../../../../../components/modal";
import { sendMasterclassEmail } from "../../../../../api/broadcastClass";
import PaidUnpaidEmail from "../../../../../components/forms/specificForms/paidUnpaidEmail";
import PaymentModal from "../../common/paymentModal";
import BroadcastClassStore from "../store/broadcastClassStore";

function ClassStatusbar() {
  const {
    params,
    curRequest: request,
    deleteRequest,
    paymentInfo,
    approveRequest,
    toggleSeriesPrivate,
    getPaymentInfo,
    downloadPaymentInfo,
    markBillPaid,
    editBroadcastClass,
    highlightUsersChatMessages,
    deregister,
    toggleLiveClassRegistrations,
  } = BroadcastClassStore.useContainer();
  return (
    <StatusBarEl>
      <StatusBarHeadEl>
        <div>Status: {request.status}</div>
        <PaymentModal
          info={paymentInfo}
          downloadPaymentInfo={async () => await downloadPaymentInfo()}
          getPaymentInfo={getPaymentInfo}
          markBillPaid={async (id) => await markBillPaid(id)}
          highlightUserMessages={highlightUsersChatMessages}
          deregister={deregister}
        />
        {request?.type === "series" ? (
          <>
            {request.status === "APPROVED" ? (
              <Button onClick={async () => await toggleSeriesPrivate()}>
                Make {request.isPrivate ? "Public" : "Private"}
              </Button>
            ) : (
              <Button
                onClick={async () => await approveRequest()}
                withconfirmation="true"
              >
                Approve
              </Button>
            )}
            <Button
              onClick={async () => deleteRequest()}
              withconfirmation="true"
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            {!request.series ? (
              <Button
                onClick={async () => deleteRequest()}
                withconfirmation="true"
              >
                Delete
              </Button>
            ) : null}
            <Modal
              title="Compose Email"
              content={({ modalOpen }) => (
                <EmailModalEl>
                  <CloseButtonEl onClick={() => modalOpen(false)}>
                    X
                  </CloseButtonEl>

                  <PaidUnpaidEmail
                    submitRequest={(val) => {
                      sendMasterclassEmail(params.classType, request.id, val);
                      modalOpen(false);
                    }}
                  />
                </EmailModalEl>
              )}
            />
            {!params.selectedRequestId ? (
              request.status !== "APPROVED" ? (
                <Button
                  onClick={async () => await approveRequest()}
                  withconfirmation="true"
                >
                  Approve
                </Button>
              ) : (
                <Button
                  onClick={async () =>
                    await editBroadcastClass({ isPrivate: !request.isPrivate })
                  }
                  withconfirmation="true"
                >
                  Make {request.isPrivate ? "Public" : "Private"}
                </Button>
              )
            ) : null}
            {!params.selectedRequestId ? (
              request.status !== "APPROVED" ? (
                <Button
                  onClick={async () => await approveRequest()}
                  withconfirmation="true"
                >
                  Approve
                </Button>
              ) : (
                <Button
                  onClick={async () => await toggleLiveClassRegistrations()}
                  withconfirmation="true"
                >
                  {request.comingSoon ? "Enable" : "Disable"} Live Registrations
                </Button>
              )
            ) : null}
          </>
        )}
      </StatusBarHeadEl>
    </StatusBarEl>
  );
}

export default ClassStatusbar;
