import {
	applySpec,
	keys,
	map,
	pathOr,
	pickBy,
	pipe,
	propOr,
	values,
} from "ramda";
import date from "date-and-time";
import { lobbyAssetParser } from "../helpers/dataParsers";

const fee = (brdcls) => {
	const cur = pathOr("", ["currency"])(brdcls);
	const fees = pathOr("", ["fee"])(brdcls);
	return `${cur} ${fees}`;
};

const whitelisted = (brdcls) => {
	const wl = pathOr({}, ["whitelisted"])(brdcls);
	return Object.entries(wl).map(([, value]) => {
		return {
			email: value.email,
			isModerator: value?.isModerator || false,
		};
	});
};

const cohosts = (coHostIds) => Object.keys(coHostIds);

const profilePhoto = (brdcls) => {
	let url = pathOr("", ["coverImage", "location"])(brdcls);
	if (url === "")
		url = pathOr("", ["teacher", "coverImage", "location"])(brdcls);
	return url;
};

const subtext = (brdcls) => {
	const teacherName = pathOr("", ["teacher", "userInfo", "name"])(brdcls);
	const dt = pathOr(new Date(8640000000000000).getTime(), ["startTime"])(
		brdcls
	);
	return `${teacherName} | ${date.format(new Date(dt), "DD MMM YYYY")}`;
};

const lobbyAssets = (brdcls) => {
	const files = pathOr({}, ["preroll-documents"])(brdcls);
	return lobbyAssetParser(files);
};

const teacherLobbyAssets = (brdcls) => {
	const files = pathOr({}, ["teacher", "getPreRollDocs"])(brdcls);
	return lobbyAssetParser(files);
};

const BroadcastClass = applySpec({
	id: pathOr("", ["id"]),
	status: pathOr("", ["status"]),
	profile: applySpec({
		name: pathOr("", ["name"]),
		fee,
		teacher: pathOr("", ["teacher", "userInfo", "name"]),
		subtext,
		description: pathOr("", ["description"]),
		profilePhoto,
		teacherId: pathOr("", ["teacher", "id"]),
	}),
	hasYoutubeUrl: pathOr(false, ["hasYoutubeUrl"]),
	correspondingCourseIds: pathOr([], ["correspondingCourseIds"]),
	youtubeUrl: pathOr("", ["youtubeUrl"]),
	restreamUrl: pathOr("", ["restreamUrl"]),
	fee: propOr(0, "fee"),
	currency: propOr("INR", "currency"),
	isPrivate: pathOr(false, ["isPrivate"]),
	coverImage: pathOr("", ["coverImage"]),
	livdemyId: pathOr("", ["livdemyId"]),
	teacherLivdemyId: pathOr("", ["teacher", "livdemyId"]),
	url: pathOr("", ["url"]),
	version: pathOr("", ["version"]),
	recordingId: pathOr("", ["recordingId"]),
	profileVideo: pathOr("", ["profileVideo"]),
	endTime: pathOr(new Date().getTime(), ["endTime"]),
	startTime: pathOr(new Date(8640000000000000).getTime(), ["startTime"]),
	showReelVideos: pathOr("", ["showReelVideos"]),
	classSnippets: pathOr([], ["classSnippets"]),
	content: pathOr([], ["content"]),
	comingSoon: pathOr(false, ["comingSoon"]),
	cohosts: pipe(pathOr([], ["co-hosts"]), cohosts),
	whitelisted,
	lobbyAssets,
	teacherLobbyAssets,
	level: pathOr("", ["level"]),
	paidRegistrationLimit: pathOr(0, ["paidRegistrationLimit"]),
	series: pathOr(false, ["series"]),
	countryDiscounts: pathOr({}, ["coupon", "value"]),
	recordingDiscount: pathOr({}, ["recordingDiscount"]),
	socialBroadcastId: pathOr("", ["socialBroadcastId"]),
	recordingExpireTime: pathOr(0, ["recordingExpireTime"]),
	recordingForSale: pathOr(false, ["recordingForSale"]),
	languages: pipe(
		pathOr({}, ["languages"]),
		pickBy((val) => val),
		keys
	),
	priceMatrix: pathOr({}, ["priceMatrix"]),
	recordingPriceMatrix: pathOr({}, ["recordingPriceMatrix"]),
	documents: pipe(
		pathOr({}, ["documents"]),
		values,
		map(
			applySpec({
				id: pathOr("", ["id"]),
				name: pathOr("", ["fileName"]),
				size: pathOr(0, ["fileSize"]),
				url: pathOr("", ["location"]),
				type: pathOr("", ["fileType"]),
			})
		)
	),
	extended: pathOr(0, ["extend", "time"]),
	type: pathOr("", ["type"]),
	postRollClasses: pathOr([], ["post-roll"]),
	ended: pathOr(false, ["ended"]),
	preRollTime: pathOr(15, ["preRollTime"]),
});

export default BroadcastClass;
