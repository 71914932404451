import { applySpec, pathOr } from "ramda";

const isTeacher = (user) => Boolean(pathOr("", ["becameTeacherAt"])(user));

const User = applySpec({
  id: pathOr("", ["id"]),
  name: pathOr("", ["profile", "name"]),
  email: pathOr("", ["email"]),
  phone: pathOr("", ["phoneNumber"]),
  gender: pathOr("", ["gender"]),
  city: pathOr("", ["profile", "city"]),
  picture: pathOr("", ["profile", "profilePhoto", "location"]),
  authToken: pathOr("", ["authToken"]),
  role: pathOr(0, ["admin_role"]),
  isTeacher,
});

export default User;
