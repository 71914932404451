//level -> 0: 'r', 1->'edit', 2->'delete'

export const permissions = {
  teachers: {
    redirectUrl: "/dashboard/courses",
    default: "/approval",
    roles: {
      "0": {
        paths: ["approval", "edit", "approved", "all"],
        level: 2,
      },
      "1": {
        paths: ["approved", "all"],
        level: 0,
      },
      "2": {
        paths: null,
        level: -1,
      },
    },
  },
  // [{
  //   'teachers': {
  //     level: 1,
  //     paths: ['approval', 'approved', 'all'],
  //     default: '/approval',
  //     reDirect: '/dashboard',
  //   }
  // },
  // {
  //   'teachers': {
  //     level: 2,
  //     paths: ['approval', 'edit', 'approved', 'all'],
  //     default: '/approval',

  //   }
  // },]
};
