import useSWR from "swr";
import { createContainer } from "unstated-next";
import { broadcastClassDataParser } from "../../../helpers/dataParsers";

const ClassesListStore = createContainer(() => {
  const { data, error: err } = useSWR([
    "admin/broadcast-classes/master-class",
    "protected",
    broadcastClassDataParser,
  ]);
  const { data: data2, error: err2 } = useSWR([
    "admin/broadcast-classes/workshop",
    "protected",
    broadcastClassDataParser,
  ]);
  const { data: data3, error: err3 } = useSWR([
    "admin/broadcast-classes/demo",
    "protected",
    broadcastClassDataParser,
  ]);
  const { data: data4, error: err4 } = useSWR([
    "admin/broadcast-classes/btm",
    "protected",
    broadcastClassDataParser,
  ]);

  const isLoading = !data || !data2 || !data3 || !data4;

  return {
    loading: isLoading,
    loadFailed: !!err || !!err2 || !!err3 || !!err4,
    allClasses: isLoading ? null : [...data, ...data2, ...data3, ...data4],
  };
});

export default ClassesListStore;
