import fetch from "@apicase/adapter-fetch";
import { ApiService } from "@apicase/services";
import { apiErrorHandler, otherErrorHandler } from "../error/global";

export const base = new ApiService({
  url: process.env.REACT_APP_API_URL,
  adapter: fetch,
  mode: "cors",
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
  hooks: {
    before: async ({ payload, next, meta = {} }) => {
      if (meta.headers) {
        payload.headers = {
          ...payload.headers,
          ...meta.headers,
        };
      }

      next(payload);
    },
    done: async ({ result, next, meta = {} }) => {
      result = meta?.type === "csv" ? result?.body : result?.body?.data;
      next(result);
    },
  },
})
  .on("fail", apiErrorHandler)
  .on("error", otherErrorHandler);
