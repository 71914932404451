import React from "react";
import ReactPlayer from "react-player";
import VideoPlayer from "./videoPlayer";

const IframeViewer = ({ url, name, type, width, height }) => {
  return ReactPlayer.canPlay(url) ? (
    <VideoPlayer url={url} name={name} />
  ) : (
    <div
      className={
        "iframeViewer " + (type !== "application/pdf" ? "withPadding" : "")
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div className="placeholderMessage">
        The attached link may fail to load if the resource is protected. Try
        opening it in a new tab in that case.
      </div>
      <iframe title={name} src={url} width={width} height={height} />
      {type !== "application/pdf" && (
        <a
          href={url}
          target={"_blank"}
          className="openInNewTab"
          rel="noopener noreferrer"
        >
          <span />
          Open in new tab
        </a>
      )}
    </div>
  );
};

export default IframeViewer;

IframeViewer.defaultProps = {
  width: "300px",
  height: "150px",
};
