import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Form from "../../../../../components/forms";
import SchoolStore from "../store/schoolStore";
import { CoHostEl } from "../../broadcastClass/elements";

function BrodcastClass() {
	const {
		curRequest: request,
		Bc = [],
		addBc,
		deleteBc,
		teachers = [],
	} = SchoolStore.useContainer();

	const coHosts = teachers
		.filter((teacher) => request?.teachers?.hasOwnProperty(teacher.id))
		.map(({ id, name }) => ({ id, name }));

	const Courses = Bc.filter((course) => {
		let flag = false;
		if (request?.Bc) {
			Object.keys(request?.Bc).forEach((key) => {
				if (request?.Bc?.[key]?.hasOwnProperty(course.id)) {
					flag = true;
				}
			});
		} else return false;
		return flag;
	}).map(({ id, name, type }) => ({ id, name, type }));

	const courseList = Bc.map(({ id, name, teacherId, type }) => ({
		label: name + " - " + type,
		value: id + "/" + type,
		id: id,
		teacherId: teacherId,
		Bctype: type,
	})).filter(({ value, teacherId, Bctype, id }) => {
		return (
			!request?.Bc?.[Bctype]?.hasOwnProperty(id) &&
			coHosts.some((teacher) => teacher.id === teacherId)
		);
	});

	return (
		<>
			<Form
				formType="list"
				name={`${request.id}-courses`}
				options={courseList}
				changeRequest={async ({ value }) => {
					const string = value.split("/");
					const id = string[0];
					const type = string[1];
					return await addBc(id, type);
				}}
				resetOnSubmit={true}
				placeholder="Add Bc"
			/>
			<CoHostEl>
				<ol>
					{Courses.map((val, i) => (
						<li key={`course-${i}`}>
							{val.name + " - " + val.type}
							<Button onClick={async () => deleteBc(val.id, val.type)}>
								Remove Bc
							</Button>
						</li>
					))}
				</ol>
			</CoHostEl>
		</>
	);
}

export default BrodcastClass;
