import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "../../input";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { isRequired, string } from "../../../utils/form-errors";
import Button from "../../button";
import ToggleForm from "../../forms/components/toggleForm";
import { H5 } from "../../../styles/typography";

function PollEditForm({ mode, onSubmit, onCancel, data }) {
  const [processing, setProcessing] = useState(false);

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      name: data.name,
      description: data.description,
      isPrivate: data.isPrivate,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string(string).required(isRequired`Name`),
        description: yup.string(string),
      })
    ),
  });

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        setProcessing(true);
        await onSubmit(values);
      })}
    >
      <H5 bold>{mode === "create" ? "Add Poll" : "Edit Poll"}</H5>
      <Controller
        as={<Input />}
        name={"name"}
        control={control}
        error={errors?.name}
        label={"Name"}
      />
      <Controller
        as={<Input />}
        control={control}
        name={"description"}
        error={errors.description}
        label={"Short Description"}
      />
      {mode !== "create" && (
        <Controller
          as={<ToggleForm type="toggle" />}
          control={control}
          name={"isPrivate"}
          label={"isPrivate"}
        />
      )}
      <Button type="submit" onClick={() => {}} disabled={processing}>
        Submit
      </Button>
      <Button onClick={onCancel} disabled={processing}>
        Cancel
      </Button>
    </form>
  );
}

export default PollEditForm;
