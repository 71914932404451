import { pluck } from "ramda";
import React from "react";
import Select from "react-select";
import { Notification } from "../../../../../../../modules/toastNotification";
import { ArtistCardEl, dropdownStyle } from "../elements";
import FeaturedArtistsStore from "../store/featuredArtistsStore";

function ArtistCard({ data: { coverImage, name, livdemyId }, index }) {
  const {
    teachersList,
    featuredArtists,
    updateFeaturedArtists,
  } = FeaturedArtistsStore.useContainer();

  const onChange = async ({ value }) => {
    let artists = pluck("livdemyId")(featuredArtists);
    artists[index] = value;
    Notification("success", "Updating Featured Artist");
    await updateFeaturedArtists({ artists: artists.filter(Boolean) });
  };

  return (
    <ArtistCardEl image={coverImage}>
      {!coverImage && <div className="no-image">No Image</div>}
      <div className="name">
        <Select
          value={{ label: name, value: livdemyId }}
          onChange={onChange}
          options={teachersList}
          styles={dropdownStyle}
        />
      </div>
    </ArtistCardEl>
  );
}

export default ArtistCard;
