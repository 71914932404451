import { useCallback, useEffect, useMemo } from "react";
import { createContainer } from "unstated-next";
import useSWR from "swr";
import { curry } from "ramda";
import { useParams } from "../../../../../utils/hooks/useParams";
import {
  dataToTab,
  getFilteredData,
  reviewDataParser,
} from "../../../../../helpers/dataParsers";
import { useList } from "../../../../../utils/hooks/useList";
import { approveReview } from "../../../../../api/reviews";
import { navigate } from "@reach/router";

const KEYS_TO_FILTER = ["status"];
const dataParser = curry(reviewDataParser);

const ReviewStore = createContainer(() => {
  const params = useParams("/dashboard/reviews/:filter/:requestId");
  const [allRequests, { updateAt: allRequestsUpdate, set: setallRequests }] =
    useList(null);

  const teacherKey = useMemo(
    () => [
      params.filter !== "courses" ? `admin/review/v2/teacher` : null,
      "protected",
      dataParser("teacher"),
    ],
    [params.filter]
  );

  const courseKey = useMemo(
    () => [
      params.filter !== "teachers" ? `admin/review/v2/course` : null,
      "protected",
      dataParser("course"),
    ],
    [params.filter]
  );

  const { data: teacherReviews, error: err1 } = useSWR(teacherKey);
  const { data: courseReviews, error: err2 } = useSWR(courseKey);

  useEffect(() => {
    const tr = teacherReviews ? teacherReviews : [];
    const cr = courseReviews ? courseReviews : [];
    if (tr.length || cr.length) setallRequests([...tr, ...cr]);
  }, [teacherReviews, courseReviews, setallRequests]);

  const FilterRequests = useCallback(
    (requests) => {
      if (requests) {
        console.log({requests})
        const filteredData = getFilteredData(
          params.filter,
          requests,
          KEYS_TO_FILTER
        );
        return dataToTab(filteredData);
      } else return requests;
    },
    [params]
  );

  const getCurRequest = useCallback(() => {
    if (!params.requestId || !allRequests) return null;

    return allRequests.find((req) => req.id === params.requestId);
  }, [params, allRequests]);

  const updateRequest = useCallback(
    async (type, data) => {
      const { success } = await approveReview(data);

      if (success) {
        const ind = allRequests.findIndex(({ id }) => id === params.requestId);

        data.whitelist = String(data.whitelist);
        allRequestsUpdate(ind, { ...getCurRequest(), ...data });

        if (params.filter === "whitelisted") {
          navigate("../");
        }
      }
    },
    [getCurRequest, params, allRequests, allRequestsUpdate]
  );

  return {
    loading: !allRequests,
    loadFailed: Boolean(err1) && Boolean(err2),
    filteredRequests: FilterRequests(allRequests),
    requestLoading: !getCurRequest(),
    curRequest: getCurRequest(),
    params,
    updateRequest,
  };
});

export default ReviewStore;
