import { Api } from "./Configuration";

export const sendInvite = (data) =>
  Api.protected.post("admin/invitations", { ...data });

const API = {
  sendInvite,
};

export default API;
