import React from "react";
import FileUploader, {
  FilesList,
  UrlFileUpload,
} from "../../../../../modules/fileUploader";
import TeacherStore from "../store/teacherStore";
import {
  FileUploadModalEl,
  CloseButtonEl,
  EmailModalEl as FormModalEl,
} from "../../../elements";
import { LobbyAssetsEl } from "../elements";
import Modal from "../../../../../components/modal";
import { H5 } from "../../../../../styles/typography";

function LobbyAssets() {
  const {
    lobbyAssetUpload,
    deleteLobbyAsset,
    curRequest: { lobbyAssets },
    lobbyAssetUpdate,
  } = TeacherStore.useContainer();
  return (
    <LobbyAssetsEl>
      <H5>
        Lobby Assets
        <Modal
          title="Upload File"
          content={({ modalOpen }) => (
            <FileUploadModalEl>
              <FileUploader
                fileTypes={["video/*", "image/*"]}
                uploadSuccess={async (file) => {
                  await lobbyAssetUpload(file);
                  modalOpen(false);
                }}
              />
            </FileUploadModalEl>
          )}
        />
        <Modal
          title="Add Url"
          content={({ modalOpen }) => (
            <FormModalEl>
              <CloseButtonEl onClick={() => modalOpen(false)}>X</CloseButtonEl>
              <UrlFileUpload
                submitRequest={async (data) => {
                  await lobbyAssetUpload(data);
                  modalOpen(false);
                }}
              />
            </FormModalEl>
          )}
        />
      </H5>
      {lobbyAssets.length === 0 ? (
        "No files"
      ) : (
        <FilesList
          files={lobbyAssets}
          deleteFile={deleteLobbyAsset}
          canView={true}
          sortable
          sortFile={lobbyAssetUpdate}
        />
      )}
    </LobbyAssetsEl>
  );
}

export default LobbyAssets;
