import { applySpec, pathOr } from "ramda";

const ClassBanner = applySpec({
  id: pathOr("", ["id"]),
  image: pathOr("", ["image"]),
  cta: applySpec({
    name: pathOr("", ["cta", "name"]),
    route: pathOr("", ["cta", "route"]),
  }),
  heading: pathOr([], ["heading"]),
  para: pathOr([], ["para"]),
  position: pathOr(0, ["position"]),
  deleted: pathOr(false, ["deleted"]),
});

export default ClassBanner;
