const languages = [
  "English",
  "Mandarin",
  "Spanish",
  "Hindi",
  "Arabic",
  "Portuguese",
  "Bengali (Bangla)",
  "Russian",
  "Japanese",
  "Punjabi",
  "German",
  "Javanese",
  "Wu",
  "Malay",
  "Telugu",
  "Vietnamese",
  "Korean",
  "French",
  "Marathi",
  "Tamil",
  "Urdu",
  "Turkish",
  "Italian",
  "Yue (Cantonese)",
  "Thai",
  "Gujarati",
  "Jin",
  "Southern Min (Hokkien and Teochew)",
  "Persian",
  "Polish",
  "Pashto",
  "Kannada",
  "Xiang (Hunanese)",
  "Malayalam",
  "Sundanese",
  "Hausa",
  "Odia (Oriya)",
  "Burmese",
  "Hakka",
  "Ukrainian",
  "Bhojpuri",
  "Tagalog (Filipino)",
  "Yoruba",
  "Maithili",
  "Uzbek",
  "Sindhi",
  "Amharic",
  "Fula",
  "Romanian",
  "Oromo",
  "Igbo",
  "Azerbaijani",
  "Awadhi",
  "Gan Chinese",
  "Cebuano (Visayan)",
  "Dutch",
  "Kurdish",
  "Serbo-Croatian",
  "Malagasy",
  "Saraiki",
  "Nepali",
  "Sinhalese",
  "Chittagonian",
  "Zhuang",
  "Khmer",
  "Turkmen",
  "Assamese",
  "Madurese",
  "Somali",
  "Marwari",
  "Magahi",
  "Haryanvi",
  "Hungarian",
  "Chhattisgarhi",
  "Greek",
  "Chewa",
  "Deccan",
  "Akan",
  "Kazakh",
  "Northern Min",
  "Sylheti",
  "Zulu",
  "Czech",
  "Kinyarwanda",
  "Dhundhari",
  "Haitian Creole",
  "Eastern Min (Fuzhounese)",
  "Ilocano",
  "Quechua",
  "Kirundi",
  "Swedish",
  "Hmong",
  "Shona",
  "Uyghur",
  "Hiligaynon Ilonggo (Visayan)",
  "Mossi",
  "Xhosa",
  "Belarusian",
  "Balochi",
  "Konkani",
  "Odia (oriya)",
  "Bengali (bangla)",
].map((option) => ({ label: option, value: option }));

export default languages;
