import styled from "styled-components";
import { circle, background, hFlex } from "../../../../../styles/style-helpers";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarIcon from "@material-ui/icons/Star";

export const ProfilePicEl = styled.div`
  ${() => circle("5em")}
  ${(props) => background(props.image, "cover")}
margin-right: 2em;
`;

export const ReviewDetailsEl = styled.div`
  ${hFlex}
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  > div {
    width: 48%;
  }
`;

export const InfoEl = styled.div`
  ${hFlex}
  justify-content: space-around;
  div {
    margin-right: 0.5em;
  }
`;

export const ReviewContentEl = styled.div`
  h4 {
    border-bottom: 1px solid rgba(125, 125, 125);
  }
  > * {
    margin: 0.6em 0 0 0;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const FilledStarEl = styled(StarIcon)`
  color: rgb(255, 200, 8);
`;

export const EmptyStarEl = styled(StarBorderRoundedIcon)`
  color: rgb(255, 200, 8);
`;
