import React, { useCallback, useState } from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import Button from "../../../../../components/button";
import { useToggle } from "react-use";
import SchoolStore from "../store/schoolStore";
import CloseIcon from "@material-ui/icons/Close";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { string } from "../../../../../utils/form-errors";
import Input from "../../../../../components/input";
import FileUploader from "../../../../../modules/fileUploader";

const CreateSchool = () => {
	const [showCreateSchool, toggleCreateSchool] = useToggle(false);
	const { editSchool } = SchoolStore.useContainer();
	const [image, setImage] = useState("");

	const { handleSubmit, errors, control } = useForm({
		resolver: yupResolver(
			yup.object().shape({
				name: yup.string(string).required(),
				description: yup.string(string).min(10).required(),
				location: yup.string(string).required(),
				image: yup.string(string).required(),
				schoolHandle: yup.string(string).min(3).required(),
			})
		),
		defaultValues: {
			name: "",
			description: "",
			location: "",
			image: "",
			schoolHandle: "",
		},
	});

	const onSubmit = async (data) => {
		console.log("done");
		const success = await editSchool(data);
		if (success) {
			toggleCreateSchool(false);
		}
	};

	return (
		<Box
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "flex-end",
				padding: "0.5em 2em",
			}}
		>
			<Button onClick={() => toggleCreateSchool(true)}>Create School</Button>

			<Modal open={showCreateSchool} onClose={() => toggleCreateSchool(false)}>
				<Box
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box
						style={{
							padding: 16,
							background: "white",
							borderRadius: 4,
							width: "60%",
							maxHeight: "85vh",
							overflow: "auto",
						}}
					>
						<Typography
							variant={"h5"}
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "0.5em",
							}}
						>
							Create a school
							<CloseIcon
								style={{ cursor: "pointer", marginLeft: 8 }}
								onClick={() => toggleCreateSchool(false)}
							/>
						</Typography>
						<form>
							<div>
								<Typography style={{ marginTop: "1em" }}>Name:</Typography>
								<Controller
									as={<Input type="text" />}
									control={control}
									name="name"
									error={errors.name}
									placeholder="Name"
								/>
							</div>
							<div>
								<Typography style={{ marginTop: "1em" }}>
									Description:
								</Typography>
								<Controller
									as={<Input type="textbox" rows={5} />}
									control={control}
									name="description"
									error={errors.description}
									placeholder="Description"
								/>
							</div>
							<div>
								<Typography style={{ marginTop: "1em" }}>
									SchoolHandle:
								</Typography>
								<Controller
									as={<Input type="text" />}
									control={control}
									name="schoolHandle"
									error={errors.schoolHandle}
									placeholder="SchoolHandle"
								/>
							</div>
							<div>
								<Typography style={{ marginTop: "1em" }}>Location:</Typography>
								<Controller
									as={<Input type="text" />}
									control={control}
									name="location"
									error={errors.location}
									placeholder="Location"
								/>
							</div>
							<div>
								<Controller
									as={<input />}
									control={control}
									name={"image"}
									hidden
								/>
								<Typography>Image:</Typography>
								{errors?.image && (
									<p className="error" style={{ color: "red" }}>
										{errors.image.message}
									</p>
								)}
								<div className="image-input">
									{image != "" ? (
										<div
											className="uploaded-img"
											style={{ position: "relative" }}
										>
											<img
												src={image}
												alt={"banner background"}
												style={{ maxWidth: "100%", width: "100%" }}
											/>
											<div
												style={{
													position: "absolute",
													color: "red",
													top: "10px",
													right: "10px",
													height: "50px",
													width: "50px",
												}}
												onClick={() => setImage("")}
											>
												<CloseIcon />
											</div>
										</div>
									) : (
										<p>
											<FileUploader
												fileTypes={["image/*"]}
												uploadSuccess={async (file) => {
													console.log(file);
													setImage(file.location);
													control.setValue("image", file.location);
												}}
											/>
										</p>
									)}
								</div>
							</div>

							<Button
								onClick={handleSubmit(onSubmit)}
								style={{ marginTop: "1em" }}
							>
								Submit
							</Button>
						</form>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default CreateSchool;
