import React from "react";
import ReviewStore from "../store/reviewStore";
import { H4 } from "../../../../../styles/typography";
import {
  ProfilePicEl,
  ReviewDetailsEl,
  InfoEl,
  ReviewContentEl,
  FilledStarEl,
  EmptyStarEl,
} from "../elements";

function ReviewContent() {
  const { curRequest: request } = ReviewStore.useContainer();

  console.log({request})
  return (
    <>
      <ReviewContentEl>
        <H4>Review</H4>
        <div>
          Rating:{" "}
          {[...Array(request.rating)].map((val, i) => (
            <FilledStarEl key={`star-${i}`} />
          ))}{" "}
          {[...Array(5 - request.rating)].map((val, i) => {
            return <EmptyStarEl key={`emptyStar-${i}`} />;
          })}
          {request.rating}.0
        </div>
        <p>{request.review}</p>
      </ReviewContentEl>
      <ReviewDetailsEl>
        <ReviewContentEl>
          <H4>Reviewed</H4>
          <p>Course: {request.title}</p>
          <p>Teacher: {request.teacherName}</p>
        </ReviewContentEl>
        <ReviewContentEl>
          <H4>Reviewer</H4>
          <InfoEl>
            <ProfilePicEl image={request.profile.profilePhoto} />
            <div>
              <p>{request.user}</p>
            </div>
          </InfoEl>
        </ReviewContentEl>
      </ReviewDetailsEl>
    </>
  );
}

export default ReviewContent;
