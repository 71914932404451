import { applySpec, pathOr } from "ramda";

const Invitation = applySpec({
  id: pathOr("", ["invitation_id"]),
  inviteeId: pathOr("", ["child_user_id"]),
  inviteeEmail: pathOr("", ["child_email"]),
  inviteeName: pathOr("", ["child_name"]),
  inviterEmail: pathOr("", ["parent_email"]),
  inviterId: pathOr("", ["parent_user_id"]),
  invitationTime: pathOr(0, ["invitation_time"]),
  status: pathOr("", ["status"]),
  consumptionTime: pathOr(0, ["consumed_at"]),
});

export default Invitation;
