import React, { useEffect } from "react";
import Loading from "../../../../../components/loading";
import Table from "../../../../../components/table";
import { ActiveSecEl, ScrollSectionEl } from "../../../elements";
import { invitationTableHeaders } from "../config";
import { invitationCellModifier } from "../helpers";
import InvitationStore from "../store/invitationStore";
import StatusBar from "./statusBar";
// import Table from "./Table";

function ViewTemplate({ requestId }) {
  const {
    loading,
    loadFailed,
    setTemplateType,
    filteredInvitations,
  } = InvitationStore.useContainer();

  useEffect(() => {
    setTemplateType(requestId);
  }, [requestId, setTemplateType]);

  return (
    <Loading loading={loading} loadFailed={loadFailed}>
      <ActiveSecEl>
        <StatusBar />
        <ScrollSectionEl className="invitation-table">
          {console.log(filteredInvitations)}
          <Table
            data={filteredInvitations}
            headers={invitationTableHeaders}
            rowCellModifier={invitationCellModifier}
            stickyHeader
            defOrder="desc"
            defOrderBy="consumptionTime"
          />
        </ScrollSectionEl>
      </ActiveSecEl>
    </Loading>
  );
}

export default ViewTemplate;
