import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useList } from "react-use";
import Button from "../../../../../components/button";
import Form from "../../../../../components/forms";
import Loading from "../../../../../components/loading";
import { withStore } from "../../../../../utils/withStore";
// import ClassesListStore from "../../../stores/PremiumCoursesListStore";
import CourseStore from "../../courses/store/courseStore";
import { premuimOptions } from "../data";
import BroadcastClassStore from "../store/broadcastClassStore";
import DeleteIcon from "@material-ui/icons/Delete";
import { CorrespondingCourseEl } from "../elements";

function CorrespondingCourse() {
	const {
		curRequest: { id, correspondingCourseIds },
		PremiumCourse,
		addCorrespondingCourseClasses: addCorrespondingCourse,
	} = BroadcastClassStore.useContainer();
	// const { loading, allCourses } = CourseStore.useContainer();
	// const { loading: loading2, allClasses: allBcClasses } =
	// 	ClassesListStore.useContainer();

	const [
		PremiumCourses,
		{
			set: setPremiumCourse,
			push: pushPremiumCourse,
			removeAt: removePremiumCourse,
		},
	] = useList(PremiumCourse);
	const [editing, setEditState] = useState(false);

	// useEffect(() => {
	// 	if (allBcClasses && allCourses)
	// 		setAllClasses([
	// 			...allBcClasses.filter(
	// 				({ status, endTime, recordingForSale = false }) =>
	// 					status === "APPROVED" &&
	// 					(recordingForSale || endTime > new Date().getTime())
	// 			),
	// 			...allCourses.filter(({ status }) => status === "LIVE"),
	// 		]);
	// }, [allBcClasses, allCourses, setAllClasses]);

	useEffect(() => {
		if (Array.isArray(PremiumCourse)) setPremiumCourse(PremiumCourse);
		setInterval(() => {}, 1000);
	}, [setPremiumCourse, PremiumCourse]);

	return (
		<Loading loading={false}>
			<CorrespondingCourseEl>
				{
					<Form
						formType="list"
						name={`${id}-correspondingCourse`}
						options={premuimOptions(PremiumCourse)}
						onChange={({ value }) => {
							addCorrespondingCourse(id, value);
						}}
						placeholder="Add Class"
					/>
				}
				{PremiumCourses.length === 0
					? "No Post Roll Classes"
					: correspondingCourseIds.map((cls, i) => (
							<div className="post-roll-class" key={cls.id}>
								<p>
									<b>{cls?.title}</b>
								</p>
								{/* <IconButton
									onClick={() => {
										removePremiumCourse(i);
										setEditState(true);
									}}
								>
									<DeleteIcon />
								</IconButton> */}
							</div>
					  ))}
			</CorrespondingCourseEl>
		</Loading>
	);
}

export default withStore([CourseStore])(CorrespondingCourse);
