import { toast } from "react-toastify";

export const Notification = (type = "notify", message = "") => {
  toast(message, {
    autoClose: 3000,
    closeButton: false,
  });
};

window.notify = Notification;
