import styled from "styled-components";
import {
  background,
  hFlex,
  modalBox,
  overlay,
  square,
  vFlex,
} from "../../../../../../../styles/style-helpers";
import { greyBorder } from "../../../../../../../styles/colors";

export const FeaturedArtistsEl = styled.div`
  ${vFlex}
  justify-content: flex-start;
  width: 80%;
  height: 100%;

  .artists-list {
    ${hFlex}
    justify-content: flex-start;
    margin-top: 2em;
    width: 100%;
    position: relative;
    overflow-x: auto;
  }
  .content {
    ${modalBox}
    ${vFlex}
  }
`;

export const ArtistCardEl = styled.div`
  ${({ image }) => background(image, "cover")}
  ${() => square("14em")};
  margin: 1em;
  border: 1px solid ${greyBorder};
  position: relative;
  top: 0;

  .no-image {
    ${overlay}
    width: 100%;
    height: 100%;
    padding: 40% 0;
    text-align: center;
  }

  .name {
    position: relative;
    top: 11em;
    height: 3em;
    background: rgba(0, 0, 0, 0.5);
    ${vFlex}
    > * {
      width: 100%;
    }
  }
`;

export const dropdownStyle = {
  control: (provided) => ({
    ...provided,
    border: "none",
    borderRadius: 0,
    background: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  input: (styles) => ({
    ...styles,
    border: 0,
    color: "#fff",
  }),
  menuList: (styles) => ({
    ...styles,
    position: "fixed !important",
    backgroundColor: "white",
    border: "1px solid lightgray",
    width: "14em",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#fff",
  }),
};
