import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { string, isRequired } from "../../../utils/form-errors";
import { WhitelistCourseFormEl } from "../elements";
import { useToggle } from "../../../utils/hooks/useToggle";
import Button from "../../button";
import Input from "../../input";

function WhitelistCourseForm({
	submitRequest,
	items,
	textLabel,
	outputSchema,
	maxLimit,
	...rest
}) {
	const [submitting, setSubmitting] = useToggle(false);
	const defaultVal = {
		CourseType: "STANDARD",
		Email: "",
	};
	const EmailResolver = yup
		.string()
		.email()
		.typeError(`Please enter a valid email`);

	const { handleSubmit, errors, control, reset } = useForm({
		resolver: yupResolver(
			yup.object().shape({
				CourseType: yup
					.string(string)
					.typeError(`Please select a Course Type`)
					.required(isRequired`CourseType`),
				Email: EmailResolver,
			})
		),
		defaultEmails: defaultVal,
	});
	const onSub = async (data) => {
		try {
			setSubmitting(true);
			await submitRequest(data.Email, data.CourseType);
			reset(defaultVal);
		} catch (error) {
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<WhitelistCourseFormEl onSubmit={handleSubmit(onSub)}>
			<Controller
				control={control}
				as={<Input type="text" inputType="Text" label={textLabel} />}
				error={errors.Email}
				name={"Email"}
			/>
			<Controller
				control={control}
				as={<Input type="list" />}
				name={"CourseType"}
				error={errors.CourseType}
				label="CourseType"
				items={items}
				defaultValue="STANDARD"
				fullWidth
			/>
			<Button type="submit" disabled={rest?.disabled || submitting}>
				Submit
			</Button>
		</WhitelistCourseFormEl>
	);
}

export default WhitelistCourseForm;
