import { applySpec, filter, map, pathOr, pipe, sort } from "ramda";

export const PollOption = applySpec({
  id: pathOr("", ["id"]),
  name: pathOr("", ["name"]),
  description: pathOr("", ["description"]),
  updatedAt: pathOr("", ["updatedAt"]),
  video: pathOr("", ["video"]),
  image: pathOr("", ["image"]),
  voteCount: pathOr(0, ["voteCount"]),
  deleted: pathOr(false, ["deleted"]),
});

const Poll = applySpec({
  id: pathOr("", ["id"]),
  name: pathOr("", ["name"]),
  description: pathOr("", ["description"]),
  isPrivate: pathOr(true, ["isPrivate"]),
  updatedAt: pathOr("", ["updatedAt"]),
  video: pathOr("", ["video"]),
  image: pathOr("", ["image"]),
  attachments: pathOr([], ["attachments"]),
  options: pipe(
    pathOr([], ["options"]),
    map(PollOption),
    filter(({ deleted }) => !deleted),
    sort((a, b) => b.updatedAt - a.updatedAt)
  ),
  deletedOptions: pipe(
    pathOr([], ["options"]),
    map(PollOption),
    filter(({ deleted }) => deleted)
  ),
});

export default Poll;
