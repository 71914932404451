import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useList } from "react-use";
import Button from "../../../../../components/button";
import Form from "../../../../../components/forms";
import Loading from "../../../../../components/loading";
import { withStore } from "../../../../../utils/withStore";
import ClassesListStore from "../../../stores/classesListStore";
import CourseStore from "../../courses/store/courseStore";
import { preRollOptions } from "../data";
import BroadcastClassStore from "../store/broadcastClassStore";
import DeleteIcon from "@material-ui/icons/Delete";
import { PostRollEl } from "../elements";

function PostRoll() {
	const {
		curRequest: { id, postRollClasses },
		addPostRollClasses: addClasses,
	} = BroadcastClassStore.useContainer();
	const { loading, allCourses } = CourseStore.useContainer();
	const { loading: loading2, allClasses: allBcClasses } =
		ClassesListStore.useContainer();

	const [classes, { set: setClasses, push: pushClass, removeAt: removeClass }] =
		useList(postRollClasses);
	const [allClasses, { set: setAllClasses }] = useList([]);
	const [editing, setEditState] = useState(false);

	useEffect(() => {
		if (allBcClasses && allCourses)
			setAllClasses([
				...allBcClasses.filter(
					({ status, endTime, recordingForSale = false }) =>
						status === "APPROVED" &&
						(recordingForSale || endTime > new Date().getTime())
				),
				...allCourses.filter(({ status }) => status === "LIVE"),
			]);
	}, [allBcClasses, allCourses, setAllClasses]);

	useEffect(() => {
		if (Array.isArray(postRollClasses)) setClasses(postRollClasses);
		console.log("preRollOptions(allClasses)", preRollOptions(allClasses));
	}, [setClasses, postRollClasses]);

	return (
		<Loading loading={loading || loading2}>
			<PostRollEl>
				{classes.length < 3 && (
					<Form
						formType="list"
						name={`${id}-postRoll`}
						options={preRollOptions(allClasses)}
						onChange={({ value }) => {
							pushClass(value);
							setEditState(true);
						}}
						placeholder="Add Class"
					/>
				)}
				{classes.length === 0
					? "No Post Roll Classes"
					: classes.map((cls, i) => (
							<div className="post-roll-class" key={cls.id}>
								<p>
									<b>{cls?.name}</b> ({cls?.type})
								</p>
								<IconButton
									onClick={() => {
										removeClass(i);
										setEditState(true);
									}}
								>
									<DeleteIcon />
								</IconButton>
							</div>
					  ))}
				{editing && (
					<Button
						onClick={async () => {
							await addClasses(classes);
							setEditState(false);
						}}
					>
						Save
					</Button>
				)}
			</PostRollEl>
		</Loading>
	);
}

export default withStore([CourseStore, ClassesListStore])(PostRoll);
