import React from "react";
// import { Router, Redirect } from "@reach/router";
import { ActiveSecEl, ActiveSecContentEl, FilterNavEl } from "../../elements";
// import TabMap from "../../../../components/tabMap";
// import FilterTab from "../common/filterTab";
// import RequestView from "../requestView";

function Analytics() {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        {/* <TabMap tabs={[]} tabStyle={(props) => <FilterTab {...props} />} /> */}
      </FilterNavEl>
      <ActiveSecContentEl>Coming soon</ActiveSecContentEl>
    </ActiveSecEl>
  );
}

export default Analytics;
