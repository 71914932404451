import React from "react";
import ReactPlayer from "react-player";
import { VideoSnippetsEl, WhitelistEmailEl } from "../elements";
import Accordion from "../../../../../components/accordian";
import Form from "../../../../../components/forms";
import Button from "../../../../../components/button";
import ClassSettings from "./classSettings";
import VisualSettings from "./visualSettings";
import BroadcastClassStore from "../store/broadcastClassStore";
import CoHosts from "./coHosts";
import LiveClassSettings from "./liveClassSettings";
import Recording from "./recording";
import Pricing from "./pricing";
import SeriesContent from "./seriesContent";
import Resources from "./resources";
import PostRoll from "./postRoll";
import Restream from "./restream";
import CorrespondingCourses from "./CorrespondingCourses";

function ClassContent() {
	const {
		curRequest: {
			type = "",
			id,
			status,
			whitelisted,
			classSnippets = [],
			// startTime,
		},
		processWhitelistEmail,
		toggleModerator,
		params,
		editBroadcastClass,
	} = BroadcastClassStore.useContainer();

	if (type !== "series")
		return (
			<>
				<Accordion title="Visual Settings">
					<VisualSettings />
				</Accordion>
				<Accordion title="Resources" TransitionProps={{ unmountOnExit: true }}>
					<Resources />
				</Accordion>
				{params.filter === "upcoming" && (
					<Accordion title="Class Settings">
						<ClassSettings />
					</Accordion>
				)}
				<Accordion title="Whitelisted Emails">
					<Form
						formType="text"
						name={`${id}-whitelistemail`}
						defaultVal=""
						submitRequest={async (val) => await processWhitelistEmail(val)}
						resetOnSubmit={true}
						label="Whitelist Email"
					/>
					<WhitelistEmailEl>
						<ol>
							{whitelisted.map((val, i) => (
								<li key={`whiteleisted-email-${i}`}>
									{val.email} {val.isModerator ? "(Moderator)" : ""}
									<Button
										onClick={async () => {
											await toggleModerator(val.email, !val.isModerator);
										}}
									>
										{val.isModerator ? "Remove Moderator" : "Make Moderator"}
									</Button>
								</li>
							))}
						</ol>
					</WhitelistEmailEl>
				</Accordion>
				<Accordion title="Co-Hosts">
					<CoHosts />
				</Accordion>
				{type === "qna" && (
					<Accordion title="Corresponding Courses">
						<CorrespondingCourses />
					</Accordion>
				)}
				<Accordion title="Recording">
					<Recording />
				</Accordion>
				<Accordion title="Add Video Snippet">
					<Form
						formType="url"
						name={`${id}-classSnippet`}
						defaultVal=""
						submitRequest={(val) =>
							editBroadcastClass({
								classSnippets: [...classSnippets, val],
							})
						}
						resetOnSubmit={true}
						label="Video Snippet Url"
					/>
					<VideoSnippetsEl>
						{classSnippets.map((url, i) => (
							<div key={`class-snippet-${i}`}>
								<ReactPlayer url={url} height="10em" width="14em" />
							</div>
						))}
					</VideoSnippetsEl>
				</Accordion>
				<Accordion title="Restream Integration">
					<Restream />
				</Accordion>
				<Accordion title="Pricing">
					<Pricing noForm={params.filter !== "upcoming"} />
				</Accordion>
				{status === "APPROVED" && (
					<Accordion title="Live Class Settings">
						<LiveClassSettings />
					</Accordion>
				)}
				<Accordion
					title="Post Roll Classes"
					TransitionProps={{ unmountOnExit: true }}
				>
					<PostRoll />
				</Accordion>
			</>
		);
	else return <SeriesContent />;
}

export default ClassContent;
