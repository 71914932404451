import React, { useState } from "react";
import Button from "../../../../../components/button";
import Form from "../../../../../components/forms";
import PaidUnPaidEmail from "../../../../../components/forms/specificForms/paidUnpaidEmail";
import Modal from "../../../../../components/modal";
import {
  StatusBarEl,
  StatusBarHeadEl,
  CollapseEl,
  EmailModalEl,
  CloseButtonEl,
} from "../../../elements";
import CourseStore from "../store/courseStore";
import { sendpaidUnpaidEmail } from "../../../../../api/course";
import { Notification } from "../../../../../modules/toastNotification";
import PaymentInfo from "./paymentInfo";

function Statusbar() {
  const {
    updateRequestStatus,
    deleteRequest,
    // getPaymentInfo,
    curRequest: { id, type, status },
  } = CourseStore.useContainer();
  const [checked, setChecked] = useState(false);
  return (
    <StatusBarEl state={checked ? "open" : "closed"}>
      <StatusBarHeadEl>
        <div>
          Status: <b>{status}</b>
        </div>
        {status !== "APPROVED" ? (
          <Button onClick={() => setChecked(!checked)}>
            {checked ? "Close" : "Update Status"}
          </Button>
        ) : null}
        <Modal
          title="Payment Info"
          content={(props) => <PaymentInfo {...props} />}
        />
        {type === "recorded" && (
          <Modal
            title="Compose Email"
            content={({ modalOpen }) => (
              <EmailModalEl>
                <CloseButtonEl onClick={() => modalOpen(false)}>
                  X
                </CloseButtonEl>

                <PaidUnPaidEmail
                  withSubject
                  submitRequest={async (val) => {
                    await sendpaidUnpaidEmail(id, val);
                    Notification("success", "Email Sent Successfully");
                    modalOpen(false);
                  }}
                />
              </EmailModalEl>
            )}
          />
        )}
        <Button onClick={() => deleteRequest()} withconfirmation="true">
          Delete
        </Button>
      </StatusBarHeadEl>
      <CollapseEl in={checked} unmountOnExit>
        <Form
          formType="status-update"
          defaultVal=""
          onApproved={async (msg) => {
            await updateRequestStatus("APPROVED", msg);
            setChecked(false);
          }}
          onRejected={async (msg) => {
            await updateRequestStatus("REJECTED", msg);
            setChecked(false);
          }}
        />
      </CollapseEl>
    </StatusBarEl>
  );
}

export default Statusbar;
