import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { isRequired, string } from "../../../utils/form-errors";
import { useToggle } from "../../../utils/hooks/useToggle";
import Button from "../../button";
import Input from "../../input";
import { FollowersEmailEl } from "../elements";

function EmailToFollowers({ submitRequest, ...rest }) {
  const [submitting, setSubmitting] = useToggle(false);
  const { handleSubmit, errors, control, getValues } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        subject: yup.string(string),
        body: yup
          .string(string)
          .test(
            "body-required-test",
            isRequired`Email Body`,
            (value) =>
              !((!value || value === "<p><br></p>") && !getValues("body2"))
          )
          .test(
            "only-one-body-test",
            "Email body can be filled in only one box",
            (value) =>
              (!!value && value !== "<p><br></p>") ^ !!getValues("body2")
          ),
        body2: yup.string(string),
        followers: yup.boolean(),
        bcPaidStudents: yup.boolean(),
        bcUnPaidStudents: yup.boolean(),
        coursePaidStudents: yup.boolean(),
        courseUnPaidStudents: yup.boolean(),
      })
    ),
    defaultValues: {
      subject: "",
      body: "",
      body2: "",
      followers: false,
      bcPaidStudents: false,
      bcUnPaidStudents: false,
      coursePaidStudents: false,
      courseUnPaidStudents: false,
    },
  });
  const onSub = async (data) => {
    setSubmitting(true);

    data.body =
      !data.body || data.body === "<p><br></p>" ? data.body2 : data.body;
    delete data.body2;

    await submitRequest(data);
    setSubmitting(false);
  };

  return (
    <FollowersEmailEl onSubmit={handleSubmit(onSub)}>
      <Controller
        as={<Input type="text" />}
        control={control}
        name="subject"
        error={errors.subject}
        placeholder="Subject"
      />
      <Controller
        as={<Input type="texteditor" />}
        control={control}
        name="body"
        error={errors.body}
        placeholder="Email body (Styled)"
      />
      {errors?.body && <p className="error">{errors?.body?.message}</p>}
      <Controller
        as={
          <Input
            type="textbox"
            placeholder="Email Body (HTML)"
            className="bodyBox2"
          />
        }
        control={control}
        name="body2"
        errors={errors.body}
        rowsMin={5}
      />
      <div className="checkboxes">
        <div className="checkbox-grp">
          <Controller
            control={control}
            error={errors.coursePaidStudents}
            name="coursePaidStudents"
            render={(props) => (
              <Input
                type="checkbox"
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.value}
                label="Course Paid Students"
              />
            )}
          />
          <Controller
            control={control}
            error={errors.courseUnPaidStudents}
            name="courseUnPaidStudents"
            render={(props) => (
              <Input
                type="checkbox"
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.value}
                label="Course Unpaid Students"
              />
            )}
          />
        </div>
        <div className="checkbox-grp">
          <Controller
            control={control}
            error={errors.bcPaidStudents}
            name="bcPaidStudents"
            render={(props) => (
              <Input
                type="checkbox"
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.value}
                label="Broadcast Class Paid Students"
              />
            )}
          />
          <Controller
            control={control}
            error={errors.bcUnPaidStudents}
            name="bcUnPaidStudents"
            render={(props) => (
              <Input
                type="checkbox"
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.value}
                label="Broadcast Class Unpaid Students"
              />
            )}
          />
        </div>
        <Controller
          control={control}
          error={errors.followers}
          name="followers"
          render={(props) => (
            <Input
              type="checkbox"
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
              label="Followers"
            />
          )}
        />
      </div>
      <Button type="submit" disabled={rest?.disabled || submitting}>
        Submit
      </Button>
    </FollowersEmailEl>
  );
}

export default EmailToFollowers;
