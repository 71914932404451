import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'

const uniqid = require('uniqid')

const VideoListPlayer = ({ url, name, hideList = false }) => {
  const id = useRef(uniqid())
  const [index, setIndex] = useState(0)

  return (
    <div
      className='videoPlayer'
      onClick={(e) => e.stopPropagation()}
      key={`playlist-player-${id.current}-${index}`}
    >
      {!hideList && (
        <div className='playlist'>
          <h1>Videos:</h1>
          {url.map((_, i) => (
            <p
              key={`react-player-playlist-${id.current}-${i}`}
              className={index === i ? 'active' : ''}
              onClick={() => setIndex(i)}
            >
              Part {i + 1}
            </p>
          ))}
        </div>
      )}
      <ReactPlayer
        key={`react-player-${id.current}-${index}`}
        url={url[index]}
        playing
        controls
        style={{ flex: 1 }}
        width={'100%'}
        height={'100%'}
        onEnded={() => {
          if (index < url.length - 1) {
            setIndex(index + 1)
          }
        }}
      />
    </div>
  )
}

export default VideoListPlayer
