import { createContainer } from "unstated-next";
import { classTypes } from "../config";
import { useCallback, useState } from "react";
import { createMasterClassesList, createTeachersList } from "../helpers";
import { useList } from "react-use";
import { getTeachers } from "../../../../../api/teacher";
import { getClasses } from "../../../../../api/broadcastClass";

const GenericEmailStore = createContainer(() => {
  const [loading, setLoading] = useState(true);

  const [masterClassesList, { push: pushBC, removeAt: removeBC }] = useList([]);
  const [
    teachersList,
    { push: pushTeacher, removeAt: removeTeacher },
  ] = useList([]);

  const init = useCallback(async () => {
    for (const classType of classTypes) {
      let data = await getClasses(classType);
      data = createMasterClassesList(data);

      if (data) {
        pushBC(...data);
      }
    }

    let teacherData = await getTeachers();
    teacherData = createTeachersList(teacherData);
    if (teacherData) {
      pushTeacher(...teacherData);
    }

    setLoading(false);
  }, [pushBC, pushTeacher]);

  return {
    init,
    loading,
    masterClassesList,
    teachersList,
    pushTeacher,
    removeTeacher,
    pushBC,
    removeBC,
  };
});

export default GenericEmailStore;
