import React from "react";
import PropTypes from "prop-types";
import { FilterTabEl, TabLinkEl } from "../../elements";

function FilterTab({ text, route, ...rest }) {
  return (
    <FilterTabEl {...rest}>
      <TabLinkEl to={route}></TabLinkEl>
      {text}
    </FilterTabEl>
  );
}

export default FilterTab;

FilterTab.propTypes = {
  text: PropTypes.string.isRequired,
};
