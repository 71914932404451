import React, { useState } from "react";
import { ButtonEl, ConfirmationModalEl, ModalEl } from "./elements";

function Button({
  onClick,
  onSubmit,
  children,
  withconfirmation,
  confirmationMsg,
  ...props
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <ButtonEl
        onClick={async (e) => {
          if (!withconfirmation) {
            onClick(e);
            await onSubmit(e);
          } else {
            e.preventDefault();
            await setModalOpen(true);
          }
        }}
        {...props}
      >
        {children}
      </ButtonEl>
      <ModalEl open={modalOpen} onClose={() => setModalOpen(false)}>
        <ConfirmationModalEl>
          <p>{confirmationMsg}</p>
          <div>
            <Button
              onClick={() => setModalOpen(false)}
              disabled={props?.disabled || submitting}
            >
              No
            </Button>{" "}
            <Button
              onClick={async (e) => {
                setSubmitting(true);
                await onClick(e);
                await onSubmit(e);
                setSubmitting(false);
                setModalOpen(false);
              }}
              disabled={props?.disabled || submitting}
            >
              Yes
            </Button>
          </div>
        </ConfirmationModalEl>
      </ModalEl>
    </>
  );
}

export default Button;

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  onClick: () => {},
  onSubmit: () => {},
  withconfirmation: false,
  confirmationMsg: "Are you sure",
};
