import styled from "styled-components";
import { hFlex, vFlex } from "../../../../../styles/style-helpers";

export const SelectedEntitiesEl = styled.div`
  width: 100%;
  ${vFlex}
  padding: 0.25em 1em;

  .entity {
    width: 100%;
    ${vFlex}
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 0.75em;

    .header {
      width: 100%;
      ${hFlex}
      justify-content: space-between;
      padding: 0.5em 1em;
      > span {
        cursor: pointer;
      }
    }

    .checkboxes {
      width: 100%;
      ${hFlex}
      justify-content: flex-start;
    }
  }
`;

export const GenericEmailEl = styled.div`
  ${vFlex}
  justify-content: flex-start;
  width: 100%;
  padding: 2em;
  height: 100%;
  overflow: scroll;

  > h4 {
    margin-bottom: 0.5em;
  }

  > form {
    width: 50%;
    margin-top: 1em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 1em 2em;
    border-radius: 10px;

    > div {
      margin: 0.5em 0;
    }

    .ql-editor {
      min-height: 10em;
    }

    .body-input {
      margin-bottom: 1em;
      .error {
        color: red;
        font-size: 0.75em;
      }

      .bodyBox2 {
        border: 1px solid black;
        padding: 0.5em;
        margin: 1em 0 0;
      }
    }
  }
`;
