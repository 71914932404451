import { yupResolver } from "@hookform/resolvers";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import {
  ActiveSecContentEl,
  ActiveSecEl,
  SearchSidebarEl,
} from "../../elements";
import * as yup from "yup";
import { isRequired, string } from "../../../../utils/form-errors";
import { withStore } from "../../../../utils/withStore";
import UserStore from "./store/userStore";
import { SearchFormEl, UserContentEl } from "./elements";
import { Text } from "../../../../styles/typography";

function Users() {
  const { loading, setUserKey, userDetails } = UserStore.useContainer();

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        search: yup
          .string(string)
          .required(isRequired`Search Value`)
          .test(
            "email-userId-test",
            "Please enter either userId or email",
            (value, _) => {
              const isUserId = value[0] === "-";
              const isEmail = yup.string().email().isValid(value);

              return isUserId || isEmail;
            }
          ),
      })
    ),
    defaultValues: {
      search: "",
    },
  });

  const onSubmit = async ({ search }) => {
    if (search[0] === "-") setUserKey(`userId=${search}`);
    else setUserKey(`userEmail=${search}`);
  };

  return (
    <ActiveSecEl>
      <ActiveSecContentEl style={{ justifyContent: "flex-start" }}>
        <SearchSidebarEl style={{ padding: "2em", width: "40%" }}>
          <SearchFormEl onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={<Input />}
              control={control}
              name="search"
              error={errors.search}
              placeholder="Search"
            />
            <Button type="submit" disabled={loading}>
              Submit
            </Button>
          </SearchFormEl>
        </SearchSidebarEl>
        <UserContentEl details={userDetails}>
          {!userDetails ? (
            "Search for a user to view details"
          ) : (
            <>
              <div className="profileInfo">
                <div className="cover" />
                <div className="basic-info">
                  <Text>
                    <b>Name:</b> {userDetails.name}
                  </Text>
                  <Text>
                    <b>ID: </b>
                    {userDetails.id}
                  </Text>
                  <Text>
                    <b>Email: </b>
                    {userDetails.email}
                  </Text>
                  <Text>
                    <b>Gender: </b>
                    {userDetails.gender}
                  </Text>
                  <Text>
                    <b>Roles: </b>
                    {Object.keys(userDetails.roles).join(", ")}
                  </Text>
                  <Text>
                    <b>Whatsapp Number: </b>
                    {userDetails.whatsappNumber}
                  </Text>
                  <Text>
                    <b>Time Zone: </b>
                    {userDetails.timezone}
                  </Text>
                </div>
              </div>
            </>
          )}
        </UserContentEl>
      </ActiveSecContentEl>
    </ActiveSecEl>
  );
}

export default withStore([UserStore])(Users);
