import React from "react";
import { useCopyToClipboard } from "react-use";
import { Notification } from "../toastNotification";
import PropTypes from "prop-types";

const ClickToCopy = ({ data, children }) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        copyToClipboard(data);
        Notification("souccess", `Copied ${data} to clipboard`);
      }}
    >
      {children}
    </div>
  );
};

export default ClickToCopy;

ClickToCopy.propTypes = {
  data: PropTypes.string,
};
