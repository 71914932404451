import React, { useEffect, useRef, useState } from "react";
import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import AwsS3 from "@uppy/aws-s3";
import ms from "ms";
import ProgressBar from "./components/progressBar";
import FilesList from "./components/filesList";
import UploadButton from "./components/uploadButton";
import UrlFileUpload from "./components/urlFileUpload";
import { FileSchema } from "../../schemas/misc";
import FilesReorder from "./components/filesReorder";

function FileUploader({
  files = [],
  uploadSuccess,
  deleteFile = null,
  fileTypes = null,
  numberOfFiles = null,
}) {
  const input = useRef(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: fileTypes,
        maxNumberOfFiles: numberOfFiles,
      },
    })
      .use(FileInput, {
        target: input.current,
        replaceTargetContent: true,
      })
      .use(AwsS3, {
        timeout: ms("2 minute"),
        companionUrl: process.env.REACT_APP_API_URL,
      })
      .on("upload", () => {
        setUploading(true);
        setProgress(0);
      })
      .on("upload-progress", (file, progress) => {
        console.log(progress.bytesUploaded, progress.bytesTotal);
        setProgress((progress.bytesUploaded / progress.bytesTotal) * 100);
      })
      .on("upload-success", async (file, response) => {
        await uploadSuccess(FileSchema({ ...file, ...response }));
        setUploading(false);
      });
  }, [input, uploadSuccess, progress, fileTypes, numberOfFiles]);

  return (
    <>
      <UploadButton inputRef={input} />
      <FilesList deleteFile={deleteFile} files={files} />
      <ProgressBar uploading={uploading} progress={progress} />
    </>
  );
}

export { FilesList, ProgressBar, UploadButton, UrlFileUpload, FilesReorder };
export default FileUploader;
