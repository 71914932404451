import { useCallback, useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import useSWR from "swr";
import {
	broadcastClassDataParser,
	broadcastSeriesDataParser,
	dataToBroadcastTab,
	filterSeriesData,
	getFilteredData,
} from "../../../../../helpers/dataParsers";
import PaymentInfo from "../../../../../schemas/paymentInfo";
import { useParams } from "../../../../../utils/hooks/useParams";
import { navigate } from "@reach/router";
import { BroadcastApi } from "../../../../../api/broadcastClass";
import { Notification } from "../../../../../modules/toastNotification";
import { streamOptions } from "../data";

const KEYS_TO_FILTER = ["status"];
const API = BroadcastApi;

const BroadcastClassStore = createContainer(() => {
	const params = useParams(
		"/dashboard/broadcast-class/:classType/:filter/:requestId/:selectedRequestId"
	);
	const [curRequest, setcurRequest] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(PaymentInfo({}));
	const [PremiumCourse, setPremiumCourse] = useState([]);
	const url = `broadcast-classes/${params.classType}`;
	const {
		data: allRequests,
		mutate: mutateClasses,
		error,
	} = useSWR([`admin/${url}`, "protected", broadcastClassDataParser]);
	const {
		data: seriesRequests,
		mutate: mutateSeries,
		error: error2,
	} = useSWR([
		`series/status/all/${params.classType}`,
		"protected",
		broadcastSeriesDataParser,
	]);
	const {
		data: PremiumCourses,
		mutate: mutatePremiumCourses,
		error: error3,
	} = useSWR([`courses/premium`, "protected"]);

	useEffect(() => {
		if (params?.requestId !== undefined && allRequests && seriesRequests) {
			const selectedId =
				params.selectedRequestId !== undefined
					? params.selectedRequestId
					: params.requestId;
			const req = [...allRequests, ...seriesRequests].find(
				({ id }) => id === selectedId
			);
			setcurRequest(req);
		}
	}, [allRequests, seriesRequests, setcurRequest, params]);
	useEffect(() => {
		if (PremiumCourses?.recordedCourses && curRequest) {
			const data = PremiumCourses?.recordedCourses.filter(
				(course) => course.teacherLivdemyId === curRequest.teacherLivdemyId
			);
			setPremiumCourse(data);
		}
	}, [curRequest, PremiumCourses]);
	const filteredRequests = useMemo(() => {
		if (allRequests && seriesRequests) {
			const filteredClasses = getFilteredData(
				params.filter,
				allRequests,
				KEYS_TO_FILTER
			);
			return dataToBroadcastTab(
				filterSeriesData(filteredClasses, seriesRequests, params.filter),
				params.filter
			);
		} else return null;
	}, [allRequests, seriesRequests, params.filter]);

	const processWhitelistEmail = useCallback(
		async (email) => {
			const emails = email.replace(/ /g, "").split(",");
			const { success } = await API?.whitelistEmail(
				params.classType,
				curRequest.id,
				emails
			);
			if (success) {
				await mutateClasses();
			}
		},
		[curRequest, params, mutateClasses]
	);

	const processRecordingId = useCallback(
		async (recordingId) => {
			const { success } =
				recordingId === ""
					? await API?.deleteRecordingId(params.classType, curRequest.id)
					: await API?.editBroadcastClass(params.classType, curRequest.id, {
							recordingId,
					  });
			if (success) {
				await mutateClasses();
			}
		},
		[curRequest, params, mutateClasses]
	);

	const deleteRequest = useCallback(async () => {
		const { success } =
			curRequest?.type === "series"
				? await API?.deleteSeries(params.classType, curRequest.id)
				: await API?.deleteClass(params.classType, curRequest.id);
		if (success) {
			curRequest?.type === "series"
				? await mutateSeries()
				: await mutateClasses();
			setcurRequest(null);
			navigate("../", { replace: true });
		}
	}, [curRequest, params, setcurRequest, mutateClasses, mutateSeries]);

	const toggleModerator = useCallback(
		async (email, state) => {
			const { success } = await API?.toggleModerator(
				params.classType,
				curRequest.id,
				email,
				state
			);
			if (success) {
				await mutateClasses();
			}
		},
		[params, curRequest, mutateClasses]
	);

	const addDiscount = useCallback(
		async (coupon) => {
			const newval = { ...curRequest.countryDiscounts, ...coupon };
			const { success } = await API?.addDiscount(
				params.classType,
				curRequest.id,
				newval
			);
			if (success) {
				await mutateClasses();
			}
		},
		[curRequest, params, mutateClasses]
	);

	const approveRequest = useCallback(async () => {
		const { success } =
			curRequest?.type === "series"
				? await API?.approveSeriesRequest(
						params.classType,
						curRequest.id,
						"APPROVED"
				  )
				: await API?.approveClassRequest(params.classType, curRequest.id);

		if (success) {
			curRequest?.type === "series"
				? await mutateSeries()
				: await mutateClasses();
		}
	}, [params, curRequest, mutateClasses, mutateSeries]);

	const toggleLiveClassRegistrations = useCallback(async () => {
		const { success } = await API?.toggleLiveClassRegistrations(
			params.classType,
			curRequest.id,
			!curRequest.comingSoon
		);

		if (success) {
			curRequest?.type === "series"
				? await mutateSeries()
				: await mutateClasses();
		}
	}, [params, curRequest, mutateClasses, mutateSeries]);

	const toggleSeriesPrivate = useCallback(async () => {
		const { success } = await API?.toggleSeriesPrivate(
			params.classType,
			curRequest.id,
			!curRequest.isPrivate
		);
		if (success) {
			await mutateSeries();
		}
	}, [params, curRequest, mutateSeries]);

	const uploadCover = useCallback(
		async (img) => {
			const { success } = await API?.uploadCover(
				params.classType,
				curRequest.id,
				img
			);
			if (success) {
				await mutateClasses();
			}
		},
		[params, curRequest, mutateClasses]
	);

	const getPaymentInfo = useCallback(async () => {
		const { success, result } = await API?.getPaymentInfo(curRequest.id);

		if (success) {
			setPaymentInfo(PaymentInfo(result));
		}
	}, [curRequest, setPaymentInfo]);

	const highlightUsersChatMessages = useCallback(
		async (regId, shouldHighlight, isSeries) => {
			const { success } = await API?.highlightUserChatMessages(
				params.classType,
				regId,
				shouldHighlight,
				isSeries
			);

			if (success) {
				await getPaymentInfo();
			}
		},
		[getPaymentInfo, params.classType]
	);

	const downloadPaymentInfo = useCallback(async () => {
		const { success, result } = await API?.getPaymentInfoCSV(curRequest.id);
		if (success) {
			const element = document.createElement("a");
			element.setAttribute(
				"href",
				"data:application/octet-stream," + encodeURIComponent(result)
			);

			element.setAttribute("download", `Payment Data - ${new Date()}.csv`);

			element.style.display = "none";
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		}
	}, [curRequest]);

	const markBillPaid = useCallback(
		async (id) => {
			const { success } = await API.markBillPaid(
				curRequest?.type === "series" ? "series" : "broadcast-class",
				id
			);
			if (success) {
				navigate("../");
				Notification("success", `Bill ID:${id} marked paid`);
			}
		},
		[curRequest]
	);

	const deregister = useCallback(
		async (id) => {
			const { success } = await API.deregister(params.classType, id);

			if (success) {
				await getPaymentInfo();
				Notification("success", `De-registered successfully`);
			}
		},
		[getPaymentInfo, params.classType]
	);

	const rescheduleClass = useCallback(
		async (time, msg) => {
			const { success } = await API.rescheduleClass(
				params.classType,
				curRequest.id,
				time,
				msg
			);
			if (success) {
				await mutateClasses();
				Notification("success", "Class rescheduled");
			}
		},
		[curRequest, params, mutateClasses]
	);

	const lobbyAssetUpload = useCallback(
		async (data) => {
			const { success } = await API?.lobbyAssetUpload(
				params.classType,
				curRequest.id,
				data
			);
			if (success) {
				await mutateClasses();
			}
		},
		[curRequest, params, mutateClasses]
	);

	const deleteLobbyAsset = useCallback(
		async ({ id: fileId }) => {
			const { success } = await API?.deleteLobbyAsset(
				params.classType,
				curRequest.id,
				fileId
			);
			if (success) {
				await mutateClasses();
			}
		},
		[curRequest, params, mutateClasses]
	);

	const reorderLobbyAssets = useCallback(
		async (files) => {
			let i = files.length;
			let shouldMutate = 0;

			for (const file of files) {
				if (file.weight !== i) {
					const { success } = await API?.updateLobbyAsset(
						params.classType,
						curRequest.id,
						file.id,
						i
					);

					if (!success) shouldMutate = -1;
					if (shouldMutate !== -1) shouldMutate = 1;
				}
				i -= 1;
			}
			if (shouldMutate === -1) Notification("", "Problem in reordering");
			if (shouldMutate === 1) await mutateClasses();
		},
		[params, curRequest, mutateClasses]
	);

	const editBroadcastClass = useCallback(
		async (data, msg = "") => {
			const { success } = await API?.editBroadcastClass(
				params.classType,
				curRequest.id,
				data
			);

			if (success) {
				await mutateClasses();
				Notification("", `Edited ${msg ? ` ${msg} ` : " "}Successfully.`);
			}
		},
		[params, curRequest, mutateClasses]
	);

	const editSeries = useCallback(
		async (data) => {
			const { success } = await API?.editSeries(
				curRequest.id,
				params.classType,
				data
			);
			if (success) await mutateSeries();
		},
		[params, curRequest, mutateSeries]
	);

	const addCoHost = useCallback(
		async (teacherId) => {
			const { success } = await API?.addCoHost(
				params.classType,
				curRequest.id,
				teacherId
			);
			if (success) {
				await mutateClasses();
				Notification("success", `Successfully add the cohost`);
			}
		},
		[curRequest, mutateClasses, params]
	);

	const startLiveStream = useCallback(
		async (values) => {
			let streams = [];
			streamOptions.forEach(({ name, urlFieldName, keyFieldName }) => {
				if (values[urlFieldName] !== "" && values[keyFieldName] !== "") {
					streams.push({
						id: `${name}-${curRequest.id}-${new Date().getTime()}`,
						serverUrl: values[urlFieldName],
						streamName: values[keyFieldName],
					});
				}
			});

			if (streams.length > 0) {
				const success = await API?.startLiveStream(
					params.classType,
					curRequest.id,
					streams
				);

				if (success) {
					await mutateClasses();
					Notification("success", "Live Stream started successfully");
				}
			} else {
				Notification("error", "Please enter atleast one set of url and key");
			}
		},
		[curRequest, params, mutateClasses]
	);

	const stopLiveStream = useCallback(async () => {
		const success = await API?.stopLiveStream(params.classType, curRequest.id);

		if (success) {
			await mutateClasses();
			Notification("success", "Live Stream stopped.");
		}
	}, [curRequest, params, mutateClasses]);

	const resourcesUpload = useCallback(
		async (data) => {
			const { success } = await API?.resourcesUpload(
				params.classType,
				curRequest.id,
				data
			);

			if (success) {
				await mutateClasses();
			}

			return success;
		},
		[curRequest, params, mutateClasses]
	);

	const deleteResource = useCallback(
		async ({ id }) => {
			const { success } = await API?.deleteResource(
				params.classType,
				curRequest.id,
				id
			);
			if (success) {
				await mutateClasses();
			}
		},
		[curRequest, params, mutateClasses]
	);

	const endClass = useCallback(
		async (value) => {
			const { success } = await API?.endClass(
				params.classType,
				curRequest.id,
				value
			);

			if (success) await mutateClasses();
		},
		[curRequest, params, mutateClasses]
	);

	const addPostRollClasses = useCallback(
		async (data) => {
			const { success } = await API?.addPostRollClasses(
				params.classType,
				curRequest.id,
				data
			);

			if (success) await mutateClasses();
		},
		[mutateClasses, curRequest, params]
	);
	const addCorrespondingCourseClasses = useCallback(
		async (QnAId, CourseId) => {
			const { success } = await API?.addCorrespondingCoursesClasses(
				QnAId,
				CourseId
			);

			if (success) await mutatePremiumCourses();
		},
		[mutatePremiumCourses, curRequest, params]
	);
	const toggleYoutubeUrl = useCallback(
		async (val, msg = "") => {
			const { success } = await API?.editBroadcastClass(
				params.classType,
				curRequest.id,
				{ hasYoutubeUrl: val }
			);

			if (success) {
				await mutateClasses();
				Notification("", `Edited ${msg ? ` ${msg} ` : " "}Successfully.`);
			}
		},
		[params, curRequest, mutateClasses]
	);
	return {
		loading: !filteredRequests,
		loadFailed: Boolean(error) || Boolean(error2),
		requestLoading: !curRequest,
		filteredRequests,
		curRequest,
		paymentInfo,
		params,
		PremiumCourse,
		processWhitelistEmail,
		processRecordingId,
		deleteRequest,
		toggleModerator,
		toggleYoutubeUrl,
		addDiscount,
		approveRequest,
		toggleSeriesPrivate,
		uploadCover,
		getPaymentInfo,
		downloadPaymentInfo,
		markBillPaid,
		rescheduleClass,
		lobbyAssetUpload,
		deleteLobbyAsset,
		reorderLobbyAssets,
		editBroadcastClass,
		addCoHost,
		startLiveStream,
		stopLiveStream,
		editSeries,
		resourcesUpload,
		deleteResource,
		endClass,
		addPostRollClasses,
		highlightUsersChatMessages,
		deregister,
		toggleLiveClassRegistrations,
		addCorrespondingCourseClasses,
	};
});

export default BroadcastClassStore;
