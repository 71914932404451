import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { isRequired, string } from "../../../../../../../utils/form-errors";
import { H5 } from "../../../../../../../styles/typography";
import { FileUploadModalEl } from "../../../../../elements";
import FileUploader from "../../../../../../../modules/fileUploader";
import Input from "../../../../../../../components/input";
import Button from "../../../../../../../components/button";
import TeacherStore from "../../../store/teacherStore";
import { EndorsementFormEl } from "../../../elements";
import Modal from "../../../../../../../components/modal";
import { Modal as MaterialModal } from "@material-ui/core";

const EndorsementForm = ({ data, close, edit }) => {
  const [submitting, setSubmitting] = useState(false);
  const [image, setImage] = useState(data?.logo);
  const { curRequest, endorsementUpdate } = TeacherStore.useContainer();

  const { handleSubmit, errors, setValue, control } = useForm({
    defaultValues: {
      image: data?.logo,
      link: data?.link,
      name: data?.name,
      brandComment: data?.artistComment,
      artistComment: data?.brandComment,
    },
    resolver: yupResolver(
      yup.object().shape({
        image: yup.string(string).required(isRequired`Image`),
        link: yup.string(string).required(isRequired`Link`),
        name: yup.string(string).required(isRequired`Name`),
        brandComment: yup.string(string).required(isRequired`Brand Comment`),
        artistComment: yup.string(string).required(isRequired`Artist Comment`),
      })
    ),
  });

  useEffect(() => {
    if (image) {
      setValue("image", image, { shouldValidate: true });
    }
  }, [image, setValue]);

  return (
    <MaterialModal open onClose={close}>
      <EndorsementFormEl
        onSubmit={handleSubmit(async (values) => {
          setSubmitting(true);

          const data = {
            name: values?.name,
            link: values?.link,
            logo: values?.image,
            brandComment: values?.brandComment,
            artistComment: values?.artistComment,
          };

          const final = [...(curRequest?.endorsements || [])];

          if (edit !== false) {
            final.splice(edit, 1, data);
          } else {
            final.push(data);
          }

          const success = await endorsementUpdate(final);

          if (success) {
            close();
          }

          setSubmitting(false);
        })}
      >
        <Controller as={<input />} control={control} name={"image"} hidden />
        <div className="row">
          <H5>Logo:</H5>
          <div className="image-input">
            {image ? (
              <div className="uploaded-img">
                <img src={image} alt={"banner background"} />
              </div>
            ) : (
              <p>
                <b>Not uploaded</b>
              </p>
            )}
            <Modal
              title={`Upload${image ? " New " : " "}Image`}
              content={({ modalOpen }) => (
                <FileUploadModalEl>
                  <FileUploader
                    fileTypes={["image/*"]}
                    uploadSuccess={async (file) => {
                      console.log(file);
                      setImage(file.location);
                      modalOpen(false);
                    }}
                  />
                </FileUploadModalEl>
              )}
            />
          </div>
        </div>
        <div className="content">
          <div className="content-info">
            <Controller
              as={<Input type="text" />}
              control={control}
              name={"name"}
              error={errors.name}
              label="Name"
              rowsMin={2}
            />
            {errors?.name && <p className="error">{errors.name?.message}</p>}
            <Controller
              as={<Input type="text" />}
              control={control}
              name={"link"}
              error={errors.link}
              label="Link"
              rowsMin={2}
            />
            {errors?.link && <p className="error">{errors.link?.message}</p>}
            <Controller
              as={<Input type="text" placeholder={"Brand Comment"} />}
              control={control}
              name={"brandComment"}
              error={errors.brandComment}
              label="Brand Comment"
              rowsMin={2}
            />
            {errors?.brandComment && (
              <p className="error">{errors.brandComment?.message}</p>
            )}
            <Controller
              as={<Input type="text" placeholder={"Artist Comment"} />}
              control={control}
              name={"artistComment"}
              error={errors.artistComment}
              label="Artist Comment"
              rowsMin={2}
            />
            {errors?.artistComment && (
              <p className="error">{errors.artistComment?.message}</p>
            )}
          </div>
        </div>

        <div className="actions">
          <Button
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
            disabled={submitting}
            withconfirmation
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button type={"Submit"} disabled={submitting}>
            Submit
          </Button>
        </div>
      </EndorsementFormEl>
    </MaterialModal>
  );
};

export default EndorsementForm;
