import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import {
  string,
  isRequired,
  minNumber,
  number,
  numberRange,
} from "../../../utils/form-errors";
import { CountryDiscountFormEl } from "../elements";
import { useToggle } from "../../../utils/hooks/useToggle";
import Button from "../../button";
import Input from "../../input";

function CountryDiscountForm({
  submitRequest,
  items,
  textLabel,
  outputSchema,
  maxLimit,
  ...rest
}) {
  const [submitting, setSubmitting] = useToggle(false);
  const defaultVal = {
    Currency: "",
    Value: "",
  };
  const ValueResolver = maxLimit
    ? yup
        .number()
        .typeError(number)
        .min(0, numberRange(0, maxLimit))
        .max(maxLimit, numberRange(0, maxLimit))
        .required(isRequired`Value`)
    : yup
        .number()
        .typeError(number)
        .min(0, minNumber(0))
        .required(isRequired`Value`);

  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        Currency: yup
          .string(string)
          .typeError(`Please select a Country Code`)
          .required(isRequired`Currency`),
        Value: ValueResolver,
      })
    ),
    defaultValues: defaultVal,
  });
  const onSub = async (data) => {
    setSubmitting(true);
    await submitRequest(outputSchema(data));
    reset(defaultVal);
    setSubmitting(false);
  };

  return (
    <CountryDiscountFormEl onSubmit={handleSubmit(onSub)}>
      <Controller
        control={control}
        as={<Input type="list" />}
        name={"Currency"}
        error={errors.Currency}
        label="Currency"
        items={items}
        fullWidth
      />
      <Controller
        control={control}
        as={<Input type="text" inputType="number" label={textLabel} />}
        error={errors.Value}
        name={"Value"}
      />
      <Button type="submit" disabled={rest?.disabled || submitting}>
        Add
      </Button>
    </CountryDiscountFormEl>
  );
}

export default CountryDiscountForm;
