import { applySpec, pathOr } from "ramda";
import date from "date-and-time";

const broadcastClasses = (brdcst) => {
	const classes = pathOr({}, ["broadcastClasses"])(brdcst);
	return Object.entries(classes).map(([key, value]) => {
		return {
			id: key,
			name: value.name,
			startTime: value.startTime,
		};
	});
};

const fee = (brdcst) => {
	const cur = pathOr("", ["currency"])(brdcst);
	const fees = pathOr("", ["amount"])(brdcst);
	return `${cur} ${fees}`;
};

const minTime = (brdcst) => {
	const classes = pathOr(null, ["broadcastClasses"])(brdcst);
	const dt = classes
		? Math.min(
				...Object.entries(classes).map(([key, value]) => value.startTime)
		  )
		: new Date(8640000000000000).getTime();
	return date.format(new Date(dt), "DD MMM YYYY");
};

const maxTime = (brdcst) => {
	const classes = pathOr(null, ["broadcastClasses"])(brdcst);
	const dt = classes
		? Math.max(
				...Object.entries(classes).map(([key, value]) => value.startTime)
		  )
		: new Date(8640000000000000).getTime();
	return date.format(new Date(dt), "DD MMM YYYY");
};

const BroadcastSeries = applySpec({
	id: pathOr("", ["id"]),
	status: pathOr("", ["status"]),
	type: pathOr("", ["type"]),
	profile: applySpec({
		name: pathOr("", ["name"]),
		fee,
		teacher: pathOr("", ["teacherName"]),
		subtext: pathOr("", ["teacherName"]),
		minTime: minTime,
		maxTime: maxTime,
		description: pathOr("", ["description"]),
	}),
	broadcastClasses,
	isPrivate: pathOr(false, ["isPrivate"]),
	livdemyId: pathOr("", ["livdemyId"]),
	url: pathOr("", ["url"]),
	version: pathOr("", ["version"]),
	priceMatrix: pathOr({}, ["priceMatrix"]),
});

export default BroadcastSeries;
