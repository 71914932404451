import React from "react";
import Modal from "../../../../../components/modal";
import FileUploader from "../../../../../modules/fileUploader";
import { CoverImageEl, FileUploadModalEl } from "../../../elements";
import { VisualSettingsEl } from "../elements";
import { H5 } from "../../../../../styles/typography";
import LobbyAssetUpload from "./lobbyAssetUpload";
import Form from "../../../../../components/forms";
import BroadcastClassStore from "../store/broadcastClassStore";

function VisualSettings() {
  const {
    curRequest: { id, coverImage, profileVideo },
    uploadCover,
    editBroadcastClass,
  } = BroadcastClassStore.useContainer();
  return (
    <VisualSettingsEl>
      <div className="visual-setting-sec cover-image-upload">
        <H5>
          CoverImage:
          <Modal
            title="Upload"
            content={({ modalOpen }) => (
              <FileUploadModalEl>
                <FileUploader
                  numberOfFiles={1}
                  fileTypes={["image/*"]}
                  uploadSuccess={async (file) => {
                    await uploadCover(file);
                    modalOpen(false);
                  }}
                />
              </FileUploadModalEl>
            )}
          />
        </H5>
        <CoverImageEl>
          {coverImage?.location ? (
            <img alt="Class Cover" src={`${coverImage.location}`}></img>
          ) : null}
        </CoverImageEl>
      </div>
      <div className="visual-setting-sec lobby-assets">
        <LobbyAssetUpload />
      </div>
      <div className="visual-setting-sec cover-video">
        <Form
          formType="url-notReq"
          name={`${id}-profileVideo`}
          defaultVal={profileVideo}
          submitRequest={(val) => editBroadcastClass({ profileVideo: val })}
          label="Cover Video"
          editToggle={true}
          displayType="link"
        />
      </div>
    </VisualSettingsEl>
  );
}

export default VisualSettings;
