import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Form from "../../../../../components/forms";
import SchoolStore from "../store/schoolStore";
import { CoHostEl } from "../../broadcastClass/elements";

function GroupCourse() {
	const {
		curRequest: request,
		courses = [],
		addCourse,
		deleteCourse,
		teachers = [],
	} = SchoolStore.useContainer();

	const coHosts = teachers
		.filter((teacher) => request?.teachers.hasOwnProperty(teacher.id))
		.map(({ id, name }) => ({ id, name }));

	const Courses = courses
		.filter((course) => {
			return request?.courses
				? request?.courses?.group
					? request?.courses?.group.hasOwnProperty(course.id)
					: false
				: false;
		})
		.map(({ id, name }) => ({ id, name }));

	const courseList = courses
		.map(({ id, name, teacherId, isGroupCourse }) => ({
			label: name,
			value: id,
			teacherId: teacherId,
			isGroupCourse: isGroupCourse,
		}))
		.filter(({ value, isGroupCourse, teacherId }) => {
			return (
				!request?.courses?.group?.hasOwnProperty(value) &&
				isGroupCourse &&
				coHosts.some((teacher) => teacher.id === teacherId)
			);
		});

	return (
		<>
			<Form
				formType="list"
				name={`${request.id}-courses`}
				options={courseList}
				changeRequest={async ({ value }) => await addCourse(value, "group")}
				resetOnSubmit={true}
				placeholder="Add Courses"
			/>
			<CoHostEl>
				<ol>
					{Courses.map((val, i) => (
						<li key={`course-${i}`}>
							{val.name}
							<Button onClick={async () => deleteCourse(val.id, "group")}>
								Remove Course
							</Button>
						</li>
					))}
				</ol>
			</CoHostEl>
		</>
	);
}

export default GroupCourse;
