import React from "react";
import {
  DateTimePicker as DateTimePickerComp,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function DateTimePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePickerComp {...props} />
    </MuiPickersUtilsProvider>
  );
}

export default DateTimePicker;
