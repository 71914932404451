import { useCallback, useMemo } from "react";
import { createContainer } from "unstated-next";
import useSWR from "swr";
import { useParams } from "../../../../../utils/hooks/useParams";
import { approveReview } from "../../../../../api/reviews";
import { applySpec, concat, map, pathOr, pipe, propOr } from "ramda";
import { SchoolApi } from "../../../../../api/school";
import { id } from "date-fns/locale";

const schoolKey = [
	`admin/schools`,
	"protected",
	applySpec({
		teachers: propOr({}, "teachers"),
		courses: pipe(
			propOr({}, "courses"),
			map(
				applySpec({
					id: propOr("", "id"),
					name: pathOr("", ["info", "title"]),
					description: pathOr("", ["info", "description"]),
					courseUrl: pathOr("", ["info", "courseUrl"]),
					teacherId: pathOr("", ["teacherId"]),
					type: pathOr("", ["type"]),
					isGroupCourse: pathOr("", ["isGroupCourse"]),
				})
			)
		),
		Bc: pipe(
			propOr({}, "Bc"),
			map(
				applySpec({
					id: propOr("", "id"),
					name: pathOr("", ["name"]),
					description: pathOr("", ["description"]),
					teacherId: pathOr("", ["teacherId"]),
					type: pathOr("", ["type"]),
				})
			)
		),
		otherBc: pipe(
			propOr({}, "otherBc"),
			map(
				applySpec({
					id: propOr("", "id"),
					name: pathOr("", ["name"]),
					description: pathOr("", ["description"]),
					teacherId: pathOr("", ["teacherId"]),
					type: pathOr("", ["type"]),
				})
			)
		),
		schools: pipe(
			propOr([], "schools"),
			map(
				applySpec({
					description: propOr("", "description"),
					key: propOr("", "id"),
					route: propOr("", "id"),
					matchRoute: propOr("", "id"),
					meta: applySpec({
						text: propOr("", "name"),
						subtext: pipe(propOr("", "schoolHandle"), concat("/")),
					}),
					id: propOr("", "id"),
					image: propOr("", "image"),
					location: propOr("", "location"),
					name: propOr("", "name"),
					schoolHandle: propOr("", "schoolHandle"),
					teachers: propOr({}, "teachers"),
					courses: propOr({}, "courses"),
					Bc: propOr({}, "Bc"),
					otherBc: propOr({}, "otherBc"),
					createdAt: propOr(0, "createdAt"),
					updatedAt: propOr(0, "updatedAt"),
				})
			)
		),
	}),
];

const SchoolStore = createContainer(() => {
	const params = useParams("/dashboard/schools/:requestId");

	const {
		data: { schools, teachers, courses, Bc, otherBc } = {},
		error,
		mutate,
	} = useSWR(schoolKey);

	const getCurRequest = useMemo(() => {
		if (!params.requestId || !schools) {
			return null;
		}

		return schools.find((req) => req.id === params.requestId);
	}, [params.requestId, schools]);

	const updateRequest = useCallback(
		async (type, data) => {
			const { success } = await approveReview(data);

			if (success) {
				// const index = schools.findIndex(({ id }) => id === params.requestId);

				data.whitelist = String(data.whitelist);
				await mutate();
				// allRequestsUpdate(ind, { ...getCurRequest(), ...data });
			}
		},
		[mutate]
	);

	console.log({ currentReq: getCurRequest });

	const editSchool = useCallback(
		async ({ name, description, schoolHandle, location, image }) => {
			const success = await SchoolApi.createSchool({
				name,
				description,
				schoolHandle,
				location,
				image,
			});

			if (success) {
				await mutate();
			}

			return success;
		},
		[mutate]
	);

	const addTeacher = useCallback(
		async (teacherId) => {
			const { id } = getCurRequest;
			const success = await SchoolApi.addTeacher(id, teacherId);

			if (success) {
				await mutate();
			}

			return success;
		},
		[getCurRequest, mutate]
	);
	const addCourse = useCallback(
		async (courseId, type) => {
			const { id } = getCurRequest;
			const success = await SchoolApi.addCourse(id, courseId, type);

			if (success) {
				await mutate();
			}

			return success;
		},
		[getCurRequest, mutate]
	);
	const addBc = useCallback(
		async (BcId, type) => {
			const { id } = getCurRequest;
			const success = await SchoolApi.addBc(id, BcId, type);

			if (success) {
				await mutate();
			}

			return success;
		},
		[getCurRequest, mutate]
	);

	const deleteTeacher = useCallback(
		async (teacherId) => {
			const { id } = getCurRequest;
			const success = await SchoolApi.deleteTeacher(id, teacherId);

			if (success) {
				await mutate();
			}

			return success;
		},
		[getCurRequest, mutate]
	);
	const deleteCourse = useCallback(
		async (CourseId, type) => {
			const { id } = getCurRequest;
			const success = await SchoolApi.deleteCourse(id, CourseId, type);

			if (success) {
				await mutate();
			}

			return success;
		},
		[getCurRequest, mutate]
	);
	const deleteBc = useCallback(
		async (BcId, type) => {
			const { id } = getCurRequest;
			const success = await SchoolApi.deleteBc(id, BcId, type);

			if (success) {
				await mutate();
			}

			return success;
		},
		[getCurRequest, mutate]
	);

	return {
		loading: !schools,
		loadFailed: Boolean(error),
		requestLoading: !getCurRequest,
		curRequest: getCurRequest,
		filteredRequests: schools,
		schools,
		teachers,
		Bc,
		otherBc,
		courses,
		params,
		updateRequest,
		editSchool,
		addTeacher,
		addBc,
		deleteTeacher,
		deleteCourse,
		deleteBc,
		addCourse,
	};
});

export default SchoolStore;
