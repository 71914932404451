import { applySpec, join, pathOr, pipe, split } from "ramda";
import date from "date-and-time";

const subtext = (review) => {
  const rating = pathOr("", ["rating"])(review);
  const dt = pathOr(0, ["createdAt"])(review);
  return `Rating - ${rating} | ${date.format(new Date(dt), "DD MMM YYYY")}`;
};

const whitelist = (review) => {
  return String(pathOr(false, ["whitelisted"])(review));
};

const Review = applySpec({
  id: pipe(pathOr("", ["SK"]), split("##"), join("--")),
  sk: pipe(pathOr("", ["SK"])),
  statusSk: pipe(pathOr("", ["statusSK"])),
  pk: pipe(pathOr("", ["PK"])),
  status: pathOr("", ["status"]),
  profile: applySpec({
    profilePhoto: pathOr("", ["userInfo", "profilePicture", "location"]),
    name: pathOr("", ["userInfo", "name"]),
    subtext: subtext,
  }),
  teacherName: pathOr("", ["teacherInfo", "userInfo", "name"]),
  user: pathOr("", ["userInfo", "name"]),
  title: pathOr("", ["entityInfo", "title"]),
  rating: pathOr("", ["rating"]),
  type: pathOr("", ["type"]),
  review: pathOr("", ["review"]),
  createdAt: pathOr(new Date().getTime(), ["createdAt"]),
  updatedAt: pathOr(new Date().getTime(), ["updatedAt"]),
  whitelist,
});

export default Review;
