import React, { useState } from "react";
import Input from "../../input";
import Button from "../../button";

function StatusUpdateForm({ defaultVal, onApproved, onRejected, ...rest }) {
  const [msg, setmsg] = useState(defaultVal);
  return (
    <>
      <Input
        type="textbox"
        value={msg}
        placeholder="Add a message"
        onChange={(e) => setmsg(e.target.value)}
        rowsMin={4}
      />
      <Button
        variant="outlined"
        defaultbg={true}
        color="secondary"
        onClick={async () => {
          await onRejected(msg);
          setmsg("");
        }}
      >
        Reject
      </Button>
      <Button
        onClick={async () => {
          await onApproved(msg);
          setmsg("");
        }}
      >
        Approve
      </Button>
    </>
  );
}

export default StatusUpdateForm;
