import useSWR from "swr";
import { createContainer } from "unstated-next";
import recordingBillSchema from "../../../../../schemas/recordingBill";

const key = ["admin/payments/recording/all", "protected", recordingBillSchema];

const RecordingStore = createContainer(() => {
  const { data, error } = useSWR(key);

  return {
    loading: !data,
    loadFailed: !!error,
    recordingData: data,
  };
});

export default RecordingStore;
