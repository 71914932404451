import React from "react";
import { DescriptionListEl, ToggleEl } from "../../../elements";
import Form from "../../../../../components/forms";
import TeacherStore from "../store/teacherStore";
import { H5 } from "../../../../../styles/typography";
import { Box, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import { useToggle } from "react-use";

function Description() {
	const {
		curRequest: request,
		togglePrivate,
		changeArtistType,
		changeSessionTypes,
		updateScore,
		editTeacher,
		languages: languageOptions,
		instruments: instrumentOptions,
	} = TeacherStore.useContainer();
	const [showEditDescription, toggleEditDescription] = useToggle(false);

	const {
		isPrivate,
		id,
		artistType,
		sessionTypes,
		teacherDescription,
		instruments,
		languages,
		score,
	} = request;

	return (
		<>
			<ToggleEl count={1}>
				<Form
					formType="toggle"
					name={`${id}-isprivate`}
					defaultVal={isPrivate}
					onChange={(val) => togglePrivate(val)}
					label="isPrivate"
					type="toggle"
				/>
				{/* <Form
          formType="toggle"
          name={`${request.id}-ismaster`}
          defaultVal={request.isMaster}
          submitRequest={(val) => toggleMaster(val)}
          label="isMaster"
          type="toggle"
        /> */}
			</ToggleEl>
			<Form
				formType="text"
				name={`${id}-score`}
				defaultVal={score}
				submitRequest={updateScore}
				editToggle={true}
				label="Score"
			/>
			<Form
				formType="list"
				options={[
					{ label: "Teacher", value: "teacher" },
					{ label: "Artist", value: "artist" },
					{ label: "Legend", value: "legend" },
				]}
				defaultVal={
					artistType !== ""
						? {
								label: artistType.charAt(0).toUpperCase() + artistType.slice(1),
								value: artistType,
						  }
						: null
				}
				label={"Artist Type"}
				placeholder={"Select Artist Type"}
				changeRequest={(val) => changeArtistType(val.value)}
			/>
			<Form
				formType="list"
				options={[
					{ label: "master-class", value: "master-class" },
					{ label: "concert", value: "concert" },
					{ label: "workshop", value: "workshop" },
					{ label: "demo", value: "demo" },
					{ label: "btm", value: "btm" },
					{ label: "qna", value: "qna" },
					{ label: "gig", value: "gig" },
				]}
				defaultVal={sessionTypes.map((session) => {
					return { label: session, value: session };
				})}
				isMulti
				searchable={false}
				label={"Session Types"}
				placeholder={"Select Session Types"}
				isClearable={false}
				changeRequest={(val) =>
					val ? changeSessionTypes(val.map(({ value }) => value)) : null
				}
			/>
			<Box
				style={{
					width: "100%",
					margin: "2em 0",
				}}
			>
				<Typography
					variant="h5"
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 16,
					}}
				>
					Description{" "}
					<CreateIcon
						style={{ cursor: "pointer", marginLeft: 8 }}
						onClick={() => toggleEditDescription(true)}
					/>
				</Typography>
				<textarea
					value={teacherDescription}
					style={{
						width: "100%",
						border: "none",
						resize: "none",
						lineHeight: 1.5,
						minHeight: "15em",
						paddingLeft: 4,
						paddingRight: 8,
					}}
				/>
			</Box>
			<Form
				formType="list"
				options={instrumentOptions}
				defaultVal={instruments.map(({ value, name }) => {
					return { label: name, value };
				})}
				isMulti
				searchable={false}
				label={"Instruments"}
				placeholder={"Select Instruments"}
				isClearable={false}
				changeRequest={(val) =>
					val ? changeSessionTypes(val.map(({ value }) => value)) : null
				}
			/>
			<Form
				formType="list"
				options={languageOptions}
				defaultVal={languages.map(({ value, name }) => {
					return { label: name, value };
				})}
				isMulti
				searchable={false}
				label={"Languages"}
				placeholder={"Select languages"}
				isClearable={false}
				changeRequest={(val) =>
					val ? changeSessionTypes(val.map(({ value }) => value)) : null
				}
			/>
			<DescriptionListEl>
				<div>
					<H5>Instruments</H5>
					<ol>
						{instruments.map(({ value, name }, i) => (
							<li key={`${value}-${i}`} value={value}>
								{name}
							</li>
						))}
					</ol>
				</div>
				<div>
					<H5>Languages</H5>
					<ol>
						{languages.map(({ value, name }, i) => (
							<li key={`${value}-${i}`} value={value}>
								{name}
							</li>
						))}
					</ol>
				</div>
			</DescriptionListEl>
			<Modal
				open={showEditDescription}
				onClose={() => toggleEditDescription(false)}
			>
				<Box
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box
						style={{
							padding: 16,
							background: "white",
							borderRadius: 4,
							width: "60%",
							maxHeight: "85vh",
							overflow: "auto",
						}}
					>
						<Typography
							variant={"h5"}
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								marginBottom: "0.5em",
							}}
						>
							Edit Description
							<CloseIcon
								style={{ cursor: "pointer", marginLeft: 8 }}
								onClick={() => toggleEditDescription(false)}
							/>
						</Typography>
						<Form
							formType="text"
							type="textbox"
							name="description"
							defaultVal={teacherDescription}
							submitRequest={(val) => editTeacher({ description: val })}
							editToggle={true}
							label="Class description"
						/>
					</Box>
				</Box>
			</Modal>
		</>
	);
}

export default Description;
