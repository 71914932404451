import React from "react";
import { ToggleFormEl } from "../elements";
import Input from "../../input";

function ToggleForm({ label, defaultVal, ...rest }) {
	return (
		<ToggleFormEl>
			{label}: <Input type="toggle" value={defaultVal} {...rest} />
		</ToggleFormEl>
	);
}

export default ToggleForm;
