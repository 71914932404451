import React from "react";
import PropTypes from "prop-types";
import Spinner from "./elements/spinner";
import { LoadingCoverEl } from "./elements";

const Loading = ({ loading, children, cover }) => {
  return loading ? (
    cover ? (
      <LoadingCoverEl>{children}</LoadingCoverEl>
    ) : (
      <Spinner />
    )
  ) : (
    children
  );
};

export default Loading;

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  cover: PropTypes.bool,
};

Loading.defaultProps = {
  cover: false,
};
