import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Form from "../../../../../components/forms";
import SchoolStore from "../store/schoolStore";
import { CoHostEl } from "../../broadcastClass/elements";

function OtherBrodcastClass() {
	const {
		curRequest: request,
		otherBc = [],
		addBc,
		deleteBc,
		teachers = [],
	} = SchoolStore.useContainer();

	const coHosts = teachers
		.filter((teacher) => request?.teachers?.hasOwnProperty(teacher.id))
		.map(({ id, name }) => ({ id, name }));

	const Courses = otherBc
		.filter((course) => {
			let flag = false;
			// console.log("value", flag, request?.otherBc, course.id);
			console.log("request?.otherBc", request?.otherBc);
			if (request?.otherBc) {
				Object.keys(request?.otherBc).forEach((key) => {
					if (request?.otherBc?.[key]?.hasOwnProperty(course.id)) {
						flag = true;
					}
				});
			} else return false;

			return flag;
		})
		.map(({ id, name, type }) => ({ id, name, type }));

	const courseList = otherBc
		.map(({ id, name, teacherId, type }) => ({
			label: name + " - " + type,
			value: id + "/" + type,
			id: id,
			teacherId: teacherId,
			Bctype: type,
		}))
		.filter(({ value, teacherId, Bctype, id }) => {
			return (
				!request?.otherBc?.[Bctype]?.hasOwnProperty(id) &&
				coHosts.some((teacher) => teacher.id === teacherId)
			);
		});

	useEffect(() => {
		console.log("otherBc", otherBc, Courses);
	}, [otherBc, Courses]);

	return (
		<>
			<Form
				formType="list"
				name={`${request.id}-otherBc`}
				options={courseList}
				changeRequest={async ({ value }) => {
					const string = value.split("/");
					const id = string[0];
					const type = string[1];
					return await addBc(id, type);
				}}
				resetOnSubmit={true}
				placeholder="Add Other sessions"
			/>
			<CoHostEl>
				<ol>
					{Courses.map((val, i) => (
						<li key={`course-${i}`}>
							{val.name + " - " + val.type}
							<Button onClick={async () => deleteBc(val.id, val.type)}>
								Remove
							</Button>
						</li>
					))}
				</ol>
			</CoHostEl>
		</>
	);
}

export default OtherBrodcastClass;
