import React from "react";
import { Box, Typography } from "@material-ui/core";
import SchoolStore from "../store/schoolStore";

const baseUrl =
  window.location.origin === "https://admin.livdemy.com"
    ? "https://livdemy.com"
    : "https://app-staging.livdemy.com";

function Description() {
  const { curRequest: request } = SchoolStore.useContainer();
  // const [showEditDescription, toggleEditDescription] = useToggle(false);

  const { description, schoolHandle } = request;

  return (
    <>
      <Box
        style={{
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          School Handle:{"  "}
          <a
            href={`${baseUrl}/${schoolHandle}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "0.5em" }}
          >
            {baseUrl}/<span>{schoolHandle}</span>
          </a>
        </Typography>
      </Box>
      <Box
        style={{
          width: "100%",
          margin: "2em 0",
        }}
      >
        <Typography
          variant="h6"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          Description{" "}
          {/*<CreateIcon
            style={{ cursor: "pointer", marginLeft: 8 }}
            onClick={() => toggleEditDescription(true)}
          />*/}
        </Typography>
        <textarea
          value={description}
          style={{
            width: "100%",
            border: "none",
            resize: "none",
            lineHeight: 1.5,
            minHeight: "15em",
            paddingLeft: 4,
            paddingRight: 8,
          }}
        />
      </Box>
      {/*<Modal
        open={showEditDescription}
        onClose={() => toggleEditDescription(false)}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              padding: 16,
              background: "white",
              borderRadius: 4,
              width: "60%",
              maxHeight: "85vh",
              overflow: "auto",
            }}
          >
            <Typography
              variant={"h5"}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0.5em",
              }}
            >
              Edit Description
              <CloseIcon
                style={{ cursor: "pointer", marginLeft: 8 }}
                onClick={() => toggleEditDescription(false)}
              />
            </Typography>
            <Form
              formType="text"
              type="textbox"
              name="description"
              defaultVal={description}
              submitRequest={(val) => editSchool({ description: val })}
              editToggle={true}
              label="School description"
            />
          </Box>
        </Box>
      </Modal>*/}
    </>
  );
}

export default Description;
