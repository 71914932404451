import { useCallback, useState } from "react";
import useSWR from "swr";
import { createContainer } from "unstated-next";
import {
  createPoll,
  createPollOption,
  EditPoll,
  deleteOption,
  editOption,
} from "../../../api/poll";
import { pollParser } from "../../../helpers/dataParsers";
import { Notification } from "../../../modules/toastNotification";

const PollStore = createContainer(() => {
  const [pollType, setPollType] = useState(null);

  const { data: polls, mutate, error } = useSWR(
    !pollType
      ? null
      : [`admin/${pollType.name}/${pollType.id}/polls`, "protected", pollParser]
  );

  const addPoll = useCallback(
    async (values) => {
      const status = await createPoll(pollType.name, values);

      if (status?.success) {
        await mutate();
        Notification("success", "Poll created successfully");
      }
      return status;
    },
    [pollType, mutate]
  );

  const addPollOption = useCallback(
    async (values) => {
      const { success } = await createPollOption(values);

      if (success) {
        await mutate();
        Notification("success", "Poll option added successfully");
      }
      return success;
    },
    [mutate]
  );

  const editPoll = useCallback(
    async (id, values) => {
      const { success } = await EditPoll(id, values);

      if (success) {
        await mutate();
        Notification("success", "Poll edited successfully");
      }
      return success;
    },
    [mutate]
  );

  const deletePollOption = useCallback(
    async (id) => {
      const { success } = await deleteOption(id);

      if (success) {
        await mutate();
        Notification("success", "Poll option deleted.");
      }
      return success;
    },
    [mutate]
  );

  const editPollOption = useCallback(
    async (id, values) => {
      const { success } = await editOption(id, values);

      if (success) {
        await mutate();
        Notification("success", "Poll option edited");
      }
      return success;
    },
    [mutate]
  );

  return {
    loading: !polls,
    loadFailed: Boolean(error),
    pollType,
    setPollType,
    polls,
    addPoll,
    addPollOption,
    editPoll,
    deletePollOption,
    editPollOption,
  };
});

export default PollStore;
