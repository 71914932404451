import styled from "styled-components";
import {
  background,
  hFlex,
  square,
  vFlex,
} from "../../../../../styles/style-helpers";
import { Text } from "../../../../../styles/typography";

export const SearchFormEl = styled.form`
  ${vFlex}
  align-items: flex-end;

  button {
    margin-top: 1em;
  }
`;

export const UserContentEl = styled.div`
  width: 100%;
  ${vFlex}
  padding: 2em;
  overflow-y: auto;
  justify-content: flex-start;

  .profileInfo {
    ${hFlex}
    width: 100%;
    justify-content: space-evenly;

    .cover {
      ${square("10em")}
      ${({ details }) => background(details?.cover, "cover")}
      margin: 1em;
      border: 1px solid rgba(0, 0, 0, 0.4);
    }

    .basic-info {
      width: 100%;

      ${Text} {
        margin: 0.5em 0;
      }
    }
  }
`;
