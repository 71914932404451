import { yupResolver } from "@hookform/resolvers";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { H5 } from "../../../styles/typography";
import { isRequired, string } from "../../../utils/form-errors";
import Button from "../../button";
import Input from "../../input";
import { QuickPollEl } from "../elements";

function QuickPoll({ onSubmit, onCancel }) {
  const [processing, setProcessing] = useState(false);

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      name: "",
      description: "Pick a choice",
      options: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string(string).required(isRequired`Name`),
        description: yup.string(string),
        options: yup
          .string(string)
          .required(isRequired`Atleast one option`)
          .test(
            "unique-polls-test",
            "All poll options should be unique",
            (value, _) => {
              let val = value.split(",").map((op) => op.trim());
              return val.length === [...new Set(val)].length;
            }
          ),
      })
    ),
  });

  return (
    <QuickPollEl
      onSubmit={handleSubmit(async (values) => {
        setProcessing(true);
        await onSubmit(values);
      })}
    >
      <H5 bold>Add Quick Poll</H5>
      <Controller
        as={<Input />}
        control={control}
        name={"name"}
        label={"Name"}
        error={errors?.name}
      />
      <Controller
        as={<Input />}
        control={control}
        name={"description"}
        label={"Description"}
        error={errors?.description}
      />
      <Controller
        as={<Input />}
        control={control}
        name={"options"}
        label={"Options"}
        error={errors?.options}
      />
      <Button type={"submit"} disabled={processing}>
        Submit
      </Button>
      <Button onClick={onCancel} disabled={processing}>
        Cancel
      </Button>
    </QuickPollEl>
  );
}

export default QuickPoll;
