import SearchInput from "react-search-input";
import styled from "styled-components";

const Search = styled(SearchInput)`
  width: 100%;
  input {
    width: 100%;
    padding: 0.5em;
    background: #fff;
    border: none;
    border-bottom: 1px solid #cdcbcd;
  }
`;

export default Search;
