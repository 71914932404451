import React from "react";
import { Router } from "@reach/router";
import SchoolStore from "./store/schoolStore";
import { ActiveSecEl } from "../../elements";
import SchoolContent from "./components/school-content";
import SchoolStatusbar from "./components/school-statusbar";
import RequestView from "../requestView";
import CreateSchool from "./components/create-school";

const Schools = () => {
  return (
    <ActiveSecEl>
      <SchoolStore.Provider>
        <CreateSchool />
        <Router component={ActiveSecEl}>
          <RequestView
            path="*"
            contentComponent={SchoolContent}
            statusComponent={SchoolStatusbar}
            Store={SchoolStore}
          />
        </Router>
      </SchoolStore.Provider>
    </ActiveSecEl>
  );
};

export default Schools;
