import React, { useState } from "react";
import Button from "../../../../../../components/button";
import Loading from "../../../../../../components/loading";
import ClassBanner from "../../../../../../schemas/classBanner";
import { withStore } from "../../../../../../utils/withStore";
import Banner from "./components/banner";
import BannerEditForm from "./components/bannerEditForm";
import { AllBannersEl, BannerEl } from "./elements";
import ClassBannerStore from "./store/classBannerStore";

function ClassBanners() {
  const {
    loading,
    loadFailed,
    allBanners,
    createBanner,
  } = ClassBannerStore.useContainer();
  const [creating, setCreating] = useState(false);

  const submit = async (values) => {
    await createBanner(values);
    setCreating(false);
  };

  const activeBanners = allBanners
    ?.filter(({ deleted }) => !deleted)
    .sort((a, b) => a.position - b.position);

  return (
    <Loading loading={loading} loadFailed={loadFailed}>
      <AllBannersEl>
        {!creating && (
          <Button className="createBtn" onClick={() => setCreating(true)}>
            Create Banner
          </Button>
        )}
        {creating && (
          <BannerEl editing={creating}>
            <BannerEditForm
              data={ClassBanner({
                position:
                  Number(
                    activeBanners[activeBanners.length - 1]?.position || 0
                  ) + 1,
              })}
              submit={submit}
              cancel={() => setCreating(false)}
              mode={"create"}
            />
          </BannerEl>
        )}
        {activeBanners?.map((banner) => (
          <Banner data={banner} key={banner.id} />
        ))}
        {allBanners
          ?.filter(({ deleted }) => deleted)
          .map((banner) => (
            <Banner data={banner} key={banner.id} />
          ))}
      </AllBannersEl>
    </Loading>
  );
}

export default withStore([ClassBannerStore])(ClassBanners);
