import { Button } from "@material-ui/core";
import React from "react";
import Form from "../../../../../components/forms";
import SchoolStore from "../store/schoolStore";
import { CoHostEl } from "../../broadcastClass/elements";

function CoHosts() {
	const {
		curRequest: request,
		teachers = [],
		addTeacher,
		deleteTeacher,
	} = SchoolStore.useContainer();

	const coHosts = teachers
		.filter((teacher) => request.teachers.hasOwnProperty(teacher.id))
		.map(({ id, name }) => ({ id, name }));

	const teachersList = teachers
		.map(({ id, name }) => ({ label: name, value: id }))
		.filter(({ value }) => !request.teachers.hasOwnProperty(value));

	return (
		<>
			<Form
				formType="list"
				name={`${request.id}-teachers`}
				options={teachersList}
				changeRequest={async ({ value }) => await addTeacher(value)}
				resetOnSubmit={true}
				placeholder="Add teachers"
			/>
			<CoHostEl>
				<ol>
					{coHosts.map((val, i) => (
						<li key={`co-host-${i}`}>
							{val.name}
							<Button onClick={async () => deleteTeacher(val.id)}>
								Remove Teacher
							</Button>
						</li>
					))}
				</ol>
			</CoHostEl>
		</>
	);
}

export default CoHosts;
