import React, { useEffect, useState } from "react";
import { withStore } from "../../utils/withStore";
import Loading from "../loading";
import Poll from "./components/poll";
import { PollsEl } from "./elements";
import PollStore from "./store/pollStore";
import { default as PollSchema } from "../../schemas/poll";
import Button from "../button";
import QuickPoll from "./components/quickPoll";

function Polls({ type, id, entityId }) {
  const {
    setPollType,
    addPoll,
    polls,
    loading,
    loadFailed,
    editPoll,
    addPollOption,
  } = PollStore.useContainer();
  const [creating, setCreating] = useState(null);

  useEffect(() => {
    setPollType({ name: type, id: id, entityId: entityId });
  }, [type, id, entityId, setPollType]);

  const createPoll = async (data) => {
    data = { livdemyId: id, ...data };
    data[`${type}Id`] = entityId;
    await addPoll(data);
    setCreating(null);
  };

  const createQuickPoll = async ({ options, ...pollData }) => {
    pollData = { livdemyId: id, isPrivate: false, ...pollData };
    pollData[`${type}Id`] = entityId;
    options = options.split(",").map((op) => op.trim());

    const {
      success,
      result: { id: pollId },
    } = await addPoll(pollData);
    if (success) {
      for (const option of options) {
        await addPollOption({ pollId, name: option });
      }
    }
    setCreating(null);
  };

  return (
    <Loading loading={loading} loadFailed={loadFailed}>
      <PollsEl>
        <div className="add-btns">
          {!creating && (
            <Button onClick={() => setCreating("poll")}>Add Poll</Button>
          )}
          {!creating && (
            <Button onClick={() => setCreating("quick-poll")}>
              Add Quick Poll
            </Button>
          )}
        </div>
        <div className="polls-list">
          {creating === "poll" && (
            <Poll
              data={PollSchema({})}
              mode={"create"}
              onSubmit={createPoll}
              onCancel={() => setCreating(null)}
            />
          )}
          {creating === "quick-poll" && (
            <QuickPoll
              onSubmit={createQuickPoll}
              onCancel={() => setCreating(null)}
            />
          )}
          {polls?.length < 1
            ? "No Polls created"
            : polls?.map((poll) => (
                <Poll
                  data={poll}
                  mode={"display"}
                  onSubmit={async (values) => editPoll(poll.id, values)}
                />
              ))}
        </div>
      </PollsEl>
    </Loading>
  );
}

export default withStore([PollStore])(Polls);
