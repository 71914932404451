import styled from "styled-components";
import {
  background,
  square,
  overlay,
  vFlex,
  hFlex,
} from "../../../styles/style-helpers";

export const FileUploadEl = styled.div`
  ${square("10em")}
  max-width: 50%;
  margin: 1em;
  background: white;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  border-radius: 0.5em;
  ${vFlex};
  position: relative;
  & > img {
    ${square("3.5em")};
  }

  & > .input {
    ${overlay};
    opacity: 0;

    & > .uppy-Root {
      ${overlay}
      button {
        ${overlay};
        width: 100%;
        cursor: pointer;
      }
    }
  }
`;

export const UploadedFilesEl = styled.div`
  position: relative;
  min-width: 50%;
  height: 100%;
  overflow-y: auto;
`;

export const ProgressEl = styled.div`
  width: 80%;
  position: absolute;
  bottom: 1em;
  height: 0.5em;
  visibility: ${(props) => (props.state === "hide" ? "hidden" : "initial")};

  div {
    background-color: black;
    width: ${(props) => `${props.progress}%`};
    height: 0.5em;
  }
`;

export const FileEl = styled.div`
  ${hFlex}
  width: 95%;
  justify-content: flex-start;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 0.25em;
  overflow: hidden;
  transition: box-shadow 300ms ease 0s;
  padding: 1em;
  margin: 0.5em auto;
  cursor: ${({ clickable, draggable }) =>
    clickable ? "pointer" : draggable ? "grab" : "inherit"};
  opacity: ${({ isDragging }) => (isDragging ? "0" : "1")};

  .dragIcon {
    font-size: 1em;
    margin-right: 0.5em;
  }

  .icon {
    ${() => square("2em")}
    ${(props) => background(props.image, "contain")}
    margin-right: 1em;
    cursor: ${({ canView }) => (canView ? "pointer" : "")};

    &:hover ~ .file-name {
      text-decoration: ${({ canView }) => (canView ? "underline" : "")};
    }
  }

  .sortable {
    ${vFlex}
    width: 5%;
    margin-right: 0.5em;

    > svg {
      cursor: pointer;
    }
  }

  .sorting-icons {
    ${vFlex}
    width: 5%;
    margin-right: 0.25em;

    svg {
      cursor: pointer;
    }
  }

  > p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 2%;
    cursor: ${({ canView }) => (canView ? "pointer" : "")};

    &:hover {
      text-decoration: ${({ canView }) => (canView ? "underline" : "")};
    }
  }
  > button {
    overflow-wrap: normal;
  }
`;

export const UrlFileUploadEl = styled.form`
  > div {
    margin: 0 0 1em 0;
  }
`;

export const FilesReorderEl = styled.div``;
