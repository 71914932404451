import React from "react";
import {
  ToggleButton as ToggleButtonComponent,
  ToggleButtonGroup as ToggleButtonGroupComponent,
} from "@material-ui/lab";
import styled from "styled-components";

const ToggleButtonGroup = styled(ToggleButtonGroupComponent)``;

const ToggleButton = styled(ToggleButtonComponent)``;

function Switch({ curVal, onChange, tabs, component: Component, ...rest }) {
  return (
    <ToggleButtonGroup value={curVal} onChange={onChange} {...rest}>
      {tabs.map((tab, i) => (
        <ToggleButton key={tab.id} value={tab.value}>
          <Component to={`${tab.value}`}></Component>
          {tab.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default Switch;

const DefaultComponent = (props) => <>{props.children}</>;

Switch.defaultProps = {
  component: DefaultComponent,
};
