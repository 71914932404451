import React, { useState } from "react";
import Accordion from "../../../../../components/accordian";
import Form from "../../../../../components/forms";
import CourseStore from "../store/courseStore";
import { CourseInfoEl, FileUploadModalEl } from "../../../elements";
import FileUploader from "../../../../../modules/fileUploader";
import { H5 } from "../../../../../styles/typography";
import Modal from "../../../../../components/modal";
import { Close } from "@material-ui/icons";
import { FileEl } from "../../../../../modules/fileUploader/elements";
import videoIcon from "../../../../../assets/icons/files/video.svg";
import Button from "../../../../../components/button";

function Info() {
	const {
		curRequest: {
			id,
			courseUrl,
			type,
			isAuditionVideoReq,
			isApprovalReq,
			profile: { name, isGroupCourse },
			fee,
			courseCoverVideo,
			coverVideoUrl,
		},
		courseEditRequest,
		setCoureCoverVideo,
	} = CourseStore.useContainer();
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);

	return (
		<Accordion title="Course Info" defaultExpanded={true}>
			<CourseInfoEl>
				<p>
					<b>Price:</b> {fee?.currency}
					{fee?.amount}
				</p>
				<p>
					<b>Group Course:</b> {isGroupCourse ? "Yes" : "No"}
				</p>
				<p>
					<b>Course Type:</b> {type}
				</p>
				<p>
					<b>Link:</b>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${process.env.REACT_APP_BASE_URL}/${courseUrl}`}
					>
						{`${courseUrl}`}
					</a>
				</p>
			</CourseInfoEl>
			<Form
				formType="text"
				name={`${id}-coursename`}
				defaultVal={name}
				submitRequest={async (val) => await courseEditRequest({ title: val })}
				editToggle={true}
				label="Course Name"
			/>
			{type === "recorded" && (
				<>
					<div>
						<b style={{ padding: "5px" }}>Course Cover Video URL</b>
						<Modal
							title="Upload File"
							content={({ modalOpen }) => (
								<FileUploadModalEl>
									<FileUploader
										fileTypes={["video/*"]}
										uploadSuccess={async (file) => {
											await courseEditRequest({ coverVideoUrl: file.location });
											modalOpen(false);
										}}
									/>
								</FileUploadModalEl>
							)}
						/>
						<div>
							{coverVideoUrl !== "" ? (
								<FileEl
									image={videoIcon}
									style={{ justifyContent: "space-around" }}
								>
									<div
										className="icon"
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											e.stopPropagation();
											window.open(coverVideoUrl, "_blank");
										}}
									></div>
									<div
										style={{ cursor: "pointer" }}
										className="file-info"
										onClick={(e) => {
											e.stopPropagation();
											window.open(coverVideoUrl, "_blank");
										}}
									>
										<div className="file-name">{coverVideoUrl}</div>
									</div>
									{
										<Button
											variant="outlined"
											onClick={async (e) => {
												e.stopPropagation();
												setProcessing(true);

												await courseEditRequest({ coverVideoUrl: "" });
												setProcessing(false);
											}}
										>
											{processing ? "Deleting..." : "Delete"}
										</Button>
									}
								</FileEl>
							) : (
								<span>Not Uploaded</span>
							)}
						</div>
					</div>
					{/* <Form
						formType="text"
						name={`${id}-covervideourl`}
						defaultVal={coverVideoUrl}
						submitRequest={async (val) =>
							await courseEditRequest({ coverVideoUrl: val })
						}
						editToggle={true}
						label="Course Cover Video URL"
					/> */}
				</>
			)}
			{type !== "recorded" && (
				<>
					<Form
						formType="url-notReq"
						name={`${id}-cover-video`}
						defaultVal={courseCoverVideo}
						submitRequest={setCoureCoverVideo}
						label={"Cover Video"}
						editToggle={true}
						displayType="link"
					/>
					<Form
						formType="toggle"
						name={`${id}-approval-req`}
						defaultValue={isApprovalReq}
						onChange={async (val) => {
							setLoading(true);
							await courseEditRequest({ isApprovalReq: val });
							setLoading(false);
						}}
						label="Approval Required"
						type="toggle"
						disabled={loading}
					/>
				</>
			)}
			{isGroupCourse && isApprovalReq && (
				<Form
					formType="toggle"
					name={`${id}-audition-vid-req`}
					defaultValue={isAuditionVideoReq}
					onChange={async (val) => {
						setLoading(true);
						await courseEditRequest({ isAuditionVideoReq: val });
						setLoading(false);
					}}
					label="Audition Video Required"
					type="toggle"
					disabled={loading}
				/>
			)}
		</Accordion>
	);
}

export default Info;
