export const sidebarTabs = [
  {
    key: "default-bar-Teachers",
    route: "teachers",
    matchRoute: "/dashboard/teachers/*",
    meta: {
      text: "Teachers",
    },
  },
  {
    key: "default-bar-Courses",
    route: "courses",
    matchRoute: "/dashboard/courses/*",
    meta: {
      text: "Courses",
    },
  },
  {
    key: "default-bar-BroadcastClass",
    route: "broadcast-class",
    matchRoute: "/dashboard/broadcast-class/*",
    meta: {
      text: "Broadcast Class",
    },
  },
  {
    key: "default-bar-Invitations",
    route: "invitations",
    matchRoute: "/dashboard/invitations/*",
    meta: {
      text: "Invitations",
    },
  },
  {
    key: "default-bar-Reports",
    route: "reports",
    matchRoute: "/dashboard/reports/*",
    meta: {
      text: "Reports",
    },
  },
  {
    key: "default-bar-Content",
    route: "content",
    matchRoute: "/dashboard/content/*",
    meta: {
      text: "Content",
    },
  },
  {
    key: "default-bar-Reviews",
    route: "reviews",
    matchRoute: "/dashboard/reviews/*",
    meta: {
      text: "Reviews",
    },
  },
  {
    key: "default-bar-Users",
    route: "users",
    matchRoute: "/dashboard/users/*",
    meta: {
      text: "Users",
    },
  },
  {
    key: "default-bar-Schools",
    route: "schools",
    matchRoute: "/dashboard/schools/*",
    meta: {
      text: "Schools",
    },
  },
  // {
  //   key: "default-bar-Schools",
  //   route: "schools",
  //   matchRoute: "/dashboard/schools",
  //   meta: {
  //     text: "Schools",
  //   },
  // },
  {
    key: "default-bar-generic-email",
    route: "generic-email",
    matchRoute: "/dashboard/generic-email",
    tabType: "fixed",
    meta: {
      text: "Generic Email",
    },
  },
  // {
  //   key: "default-bar-Google Analytics",
  //   route: "analytics",
  //   matchRoute: "/dashboard/analytics",
  //   meta: {
  //     text: "Google Analytics",
  //   },
  // },
];

export const teacherFilterTabs = [
  {
    key: "teacher-approval",
    route: "approval",
    matchRoute: "approval/*",
    meta: {
      text: "Approval Requests",
    },
  },
  {
    key: "teacher-edit",
    route: "edit",
    matchRoute: "edit/*",
    meta: {
      text: "Edit Requests",
    },
  },
  {
    key: "teacher-approved",
    route: "approved",
    matchRoute: "approved/*",
    meta: {
      text: "Approved",
    },
  },
  {
    key: "teacher-all",
    route: "all",
    matchRoute: "all/*",
    meta: {
      text: "All",
    },
  },
];

export const courseFilterTabs = [
  {
    key: "course-approval",
    route: "approval",
    matchRoute: "approval/*",
    meta: {
      text: "Approval Requests",
    },
  },
  {
    key: "course-edit",
    route: "edit",
    matchRoute: "edit/*",
    meta: {
      text: "Edit Requests",
    },
  },
  {
    key: "course-approved",
    route: "approved",
    matchRoute: "approved/*",
    meta: {
      text: "Approved",
    },
  },
  {
    key: "course-live",
    route: "live",
    matchRoute: "live/*",
    meta: {
      text: "Live",
    },
  },
  {
    key: "course-all",
    route: "all",
    matchRoute: "all/*",
    meta: {
      text: "All",
    },
  },
];

export const broadcastFilterTabs = [
  {
    key: "broadcast-classes-upcoming",
    route: "upcoming",
    matchRoute: "upcoming/*",
    meta: {
      text: "Upcoming",
    },
  },
  {
    key: "broadcast-classes-completed",
    route: "completed",
    matchRoute: "completed/*",
    meta: {
      text: "Completed",
    },
  },
];

export const filters = {
  upcoming: "upcoming",
  completed: "completed",
  approval: ["PENDING_APPROVAL"],
  course_approval: ["PENDING_APPROVAL"],
  edit: ["EDITED"],
  course_edit: ["EDITED", "PENDING_EDITS"],
  approved: ["APPROVED", "PENDING_EDITS"],
  course_approved: ["APPROVED"],
  live: ["LIVE"],
  course_live: ["LIVE"],
  all: [""],
  course_all: [""],
  teachers: [""],
  courses: [""],
  whitelisted: ["WHITELISTED"],
};

export const reviewFilterTabs = [
  {
    key: "reviews-teacher",
    route: "teachers",
    matchRoute: "teachers/*",
    meta: {
      text: "Teachers",
    },
  },
  {
    key: "reviews-courses",
    route: "courses",
    matchRoute: "courses/*",
    meta: {
      text: "Courses",
    },
  },
  {
    key: "reviews-whitelisted",
    route: "whitelisted",
    matchRoute: "whitelisted/*",
    meta: {
      text: "Whitelisted",
    },
  },
  {
    key: "reviews-all",
    route: "all",
    matchRoute: "all/*",
    meta: {
      text: "All",
    },
  },
];

export const broadcastClassTypes = [
  { id: "bc-masterclass", value: "master-class", text: "Master Class" },
  { id: "bc-workshop", value: "workshop", text: "Workshops" },
  { id: "bc-demo", value: "demo", text: "Demo" },
  { id: "bc-btm", value: "btm", text: "Behind The Music" },
  { id: "bc-qna", value: "qna", text: "QnA" },
  { id: "bc-concert", value: "concert", text: "Concert" },
  { id: "bc-gig", value: "gig", text: "Gig" },
];

export const contentFilterTabs = [
  {
    key: "content-banner",
    route: "banners",
    matchRoute: "banners/*",
    meta: {
      text: "Class Banners",
    },
  },
  {
    key: "content-featured",
    route: "featured-artists",
    matchRoute: "featured-artists/*",
    meta: {
      text: "Featured Artists",
    },
  },
];
