import React, { useState } from "react";
import { Collapse, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import { SearchTabEl, TabLinkEl } from "../../elements";
import { H5 } from "../../../../styles/typography";
import TabMap from "../../../../components/tabMap";
import { OndemandVideo } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function SearchTab({ text, isActive, subtabs = [], route, ...props }) {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  return (
    <SearchTabEl p={2} mb={2} isactive={isActive ? 1 : 0}>
      <TabLinkEl to={route}></TabLinkEl>
      <H5 bold>
        {subtabs.length > 0 ? (
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expand,
            })}
            onClick={() => setExpand((prev) => !prev)}
            aria-expanded={expand}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        ) : (
          ""
        )}
        {text}
      </H5>
      <p>{props?.subtext}</p>
      {props?.type === "recorded" ? (
        <div className="record-icon">
          <OndemandVideo />
        </div>
      ) : null}
      <p className="status">{props?.status}</p>
      <Collapse in={expand}>
        <TabMap tabs={subtabs} tabStyle={(props) => <SearchTab {...props} />} />
      </Collapse>
    </SearchTabEl>
  );
}

export default SearchTab;

SearchTab.propTypes = {
  text: PropTypes.string.isRequired,
};
