import { useState, useEffect } from "react";
import useSWR from "swr";
import { createContainer } from "unstated-next";
import { Api } from "../../../../../api/Configuration";
import User from "../schema/user";

const UserStore = createContainer(() => {
  const [userKey, setUserKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const { data, error } = useSWR(
    !userKey ? null : `admin/search/user?${userKey}`,
    (url) => {
      setLoading(true);
      return Api.protected
        .get(url)
        .then((data) => data.result)
        .then((data) => {
          setLoading(false);
          if (data.status === 400) {
            setUserKey(null);
            return userDetails;
          } else {
            return User(data);
          }
        });
    }
  );

  useEffect(() => {
    if (!!data) setUserDetails(data);
  }, [setUserDetails, data]);

  return {
    loading,
    userDetails,
    loadFailed: !!error,
    setUserKey,
  };
});

export default UserStore;
