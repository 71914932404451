import { useCallback } from "react";
import { useToggle } from "./useToggle";

export const useLoading = (defaultLoading = true) => {
  const [loading, setLoading] = useToggle(defaultLoading);
  const [loadFailed, setLoadFailed] = useToggle(false);

  const reset = useCallback(() => {
    setLoading(true);
    setLoadFailed(false);
  }, [setLoading, setLoadFailed]);

  const setLoaded = useCallback(
    (success) => {
      setLoading(false);
      setLoadFailed(!success);
    },
    [setLoading, setLoadFailed]
  );

  return { loading, loadFailed, reset, setLoaded };
};
