import { applySpec, join, juxt, pathOr, pipe } from "ramda";

const earnings = (info) => {
	const values = pathOr({}, ["earnings"])(info);
	return Object.entries(values).map(([key, value]) => `${key} ${value}`);
};

const bills = (info) => {
	const allBills = pathOr([], ["allBills"])(info);
	return allBills.map((bill) =>
		applySpec({
			id: pathOr("", ["id"]),
			user: pathOr("", ["name"]),
			userId: pathOr("", ["userId"]),
			registrationId: pathOr("", ["entityRegistrationId"]),
			highlightMessages: pathOr("", ["highlightMessages"]),
			email: pathOr("", ["email"]),
			createdAt: pathOr("", ["createdAt"]),
			updatedAt: pathOr("", ["updatedAt"]),
			amount: pipe(
				juxt([pathOr("", ["currency"]), pathOr("", ["amount"])]),
				join(" ")
			),
			currency: pathOr("", ["currency"]),
			price: pathOr("", ["amount"]),
			gateway: pathOr("", ["paymentGateway"]),
			status: pathOr("", ["status"]),
			isSeriesBill: pathOr(false, ["isSeriesBill"]),
		})(bill)
	);
};

const paid = (info) => {
	const allBills = pathOr([], ["allBills"])(info);
	return allBills.filter(({ status = "" }) => status === "PAID").length;
};

const not_paid = (info) => {
	const allBills = pathOr([], ["allBills"])(info);
	return allBills.filter(({ status = "" }) => status === "NOT_PAID").length;
};

const PaymentInfo = applySpec({
	paid,
	not_paid,
	earnings,
	bills,
});

export default PaymentInfo;
