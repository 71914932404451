import { Button, Modal } from "@material-ui/core";
import styled, { css } from "styled-components";
import { accent, darkAccent } from "../../../styles/colors";
import { hFlex, modalBox, vFlex } from "../../../styles/style-helpers";

export const ButtonEl = styled(Button)`
  ${({ defaultbg, variant }) =>
    !defaultbg && variant === "contained"
      ? css`
          background: #c7402d
            linear-gradient(196.67deg, ${darkAccent} 0%, ${accent} 100%);
          color: white;
        `
      : variant === "outlined"
      ? css`
          border-color: #c7402d !important;
          color: #c7402d !important;
        `
      : ""}

  ${({ type }) =>
    type === "google"
      ? css`
          background: #ea4335 !important;
          width: 75%;
          text-transform: uppercase;
          margin-bottom: 1em !important;
          padding: 0.75em 0 !important;
        `
      : type === "facebook"
      ? css`
          background: #3b5998 !important;
          width: 75%;
          text-transform: uppercase;
          margin-bottom: 1em !important;
          padding: 0.75em 0 !important;
        `
      : ""};

  span {
    text-transform: none;
  }
`;

export const ConfirmationModalEl = styled.div`
  ${modalBox};
  width: 30%;

  > p {
    text-align: center;
    margin-bottom: 2em;
  }

  div {
    ${hFlex};
    justify-content: space-around;
  }
`;

export const ModalEl = styled(Modal)`
  ${vFlex}
`;
