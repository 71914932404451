import React from "react";
import { Router, Redirect } from "@reach/router";
import TabMap from "../../../../../components/tabMap";
import { broadcastFilterTabs } from "../../../config/routes";
import { ActiveSecEl, FilterNavEl } from "../../../elements";
import RequestView from "../../requestView";
import FilterTab from "../../common/filterTab";
import ClassInfo from "./classInfo";
import ClassContent from "./classContent";
import ClassStatusbar from "./classStatusbar";
import BroadcastClassStore from "../store/broadcastClassStore";
import TeacherStore from "../../teachers/store/teacherStore";

function SelectedBroadcastClass() {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        <TabMap
          tabs={broadcastFilterTabs}
          tabStyle={(props) => <FilterTab {...props} />}
        />
      </FilterNavEl>

      <TeacherStore.Provider>
        <BroadcastClassStore.Provider>
          <Router component={ActiveSecEl}>
            <Redirect from="/" to="upcoming" noThrow />
            <RequestView
              path=":filter/*"
              infoComponent={ClassInfo}
              contentComponent={ClassContent}
              statusComponent={ClassStatusbar}
              Store={BroadcastClassStore}
            />
          </Router>
        </BroadcastClassStore.Provider>
      </TeacherStore.Provider>
    </ActiveSecEl>
  );
}

export default SelectedBroadcastClass;
