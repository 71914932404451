import React from "react";
import TextField from "@material-ui/core/TextField";

const TextInput = ({ error, inputType = null, ...props }) => {
  const errorMessage = error?.message;
  // console.log(props);
  return (
    <TextField
      fullWidth
      type={inputType ? inputType : "text"}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      {...props}
    />
  );
};

export default TextInput;
