import { handleErrorNotification } from "./notification";

export const apiErrorHandler = (error) => {
  console.log("Api Error: ", error);
  handleErrorNotification(error);

  // return Promise.reject(error);
};

export const otherErrorHandler = (error) => {
  console.log("Other Error: ", error);

  // return Promise.reject(error);
};
