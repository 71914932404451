import { makeStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";
import { TableCell as CellComp } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  sortLabel: {
    root: {
      "&:hover": {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    active: {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export const TableCellEl = styled(CellComp)`
  > div,
  > a {
    ${(props) =>
      props?.width
        ? css`
            min-width: ${props.width}px;
            max-width: ${props.width}px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          `
        : ""}
  }
`;
