import React from "react";
import SchoolStore from "../store/schoolStore";
import Accordion from "../../../../../components/accordian";
import Description from "./description";
import {
	ProfileInfoEl,
	ProfilePicEl,
	TeacherInfoEl,
} from "../../teachers/elements";
import { H3 } from "../../../../../styles/typography";
import ClickToCopy from "../../../../../modules/clickToCopy";
import CoHosts from "./co-hosts";
import RecordedCourses from "./recordedCourse";
import GroupCourse from "./groupCourse";
import BrodcastClass from "./brodcastClass";
import OtherBrodcastClass from "./otherBrodcastClass";

function SchoolContent() {
	const { curRequest: request } = SchoolStore.useContainer();

	return (
		<>
			<TeacherInfoEl>
				<ProfilePicEl image={request.image} />
				<ProfileInfoEl>
					<H3>{request.name}</H3>
					<p>Location: {request.location}</p>
					<p>
						<ClickToCopy data={request.id}>ID: {request.id}</ClickToCopy>
					</p>
				</ProfileInfoEl>
			</TeacherInfoEl>
			<Accordion title="About School">
				<Description />
			</Accordion>
			<Accordion title="Teachers">
				<CoHosts />
			</Accordion>
			<Accordion title="Recorded Courses">
				<RecordedCourses />
			</Accordion>
			<Accordion title="Group Courses">
				<GroupCourse />
			</Accordion>
			<Accordion title="MasterClass/Workshop">
				<BrodcastClass />
			</Accordion>
			<Accordion title="Other Sessions">
				<OtherBrodcastClass />
			</Accordion>
		</>
	);
}

export default SchoolContent;
