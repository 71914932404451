export const reportsFilterTabs = [
  {
    key: "reports-recording",
    route: "recordings",
    matchRoute: "recordings/*",
    meta: {
      text: "Recordings",
    },
  },
  {
    key: "reports-subscription",
    route: "subscriptions",
    matchRoute: "subscriptions/*",
    meta: {
      text: "Subscriptions",
    },
  },
];
