import React, { useState } from "react";
import BroadcastClassStore from "../store/broadcastClassStore";
import Form from "../../../../../components/forms";
import ContentEditor from "../../../../../modules/content-editor";
import { FormControlLabel, Radio } from "@material-ui/core";
import { useToggle } from "../../../../../utils/hooks/useToggle";

function Recording() {
	const {
		curRequest: {
			type = "",
			id,
			recordingId,
			recordingExpireTime,
			recordingForSale,
			content,
		},
		processRecordingId,
		editBroadcastClass,
	} = BroadcastClassStore.useContainer();
	const [loading, setLoading] = useState(false);
	const [showContent, toggleContent] = useToggle(false);

	return (
		<>
			<Form
				formType="text"
				inputType="number"
				name={`${id}-recExpiryTime`}
				defaultVal={recordingExpireTime}
				submitRequest={(val) =>
					editBroadcastClass({ recordingExpireTime: val })
				}
				label="Recording Expire Time"
				editToggle={true}
			/>
			<Form
				formType="toggle"
				name={`${id}-recForSale`}
				// defaultValue={recordingForSale}
				value={recordingForSale}
				onChange={async (val) => {
					setLoading(true);
					await editBroadcastClass({ recordingForSale: val });
					setLoading(false);
				}}
				label="Recording For Sale"
				type="toggle"
				disabled={loading}
			/>

			<div className="options">
				<FormControlLabel
					value="Use Recording ID"
					control={
						<Radio
							checked={!showContent}
							onChange={() => toggleContent(false)}
							value="a"
							name="radio-buttons"
						/>
					}
					label="Use Recording ID"
				/>
				<FormControlLabel
					value="Use Recording Content"
					control={
						<Radio
							checked={showContent}
							onChange={() => toggleContent(true)}
							value="a"
							name="radio-buttons"
						/>
					}
					label="Use Recording Content"
				/>
			</div>

			{!showContent ? (
				<div style={{ padding: "1em" }}>
					<Form
						formType="text"
						name={`${id}-recordingId`}
						defaultVal={recordingId}
						submitRequest={(val) => processRecordingId(val)}
						label="Recording Id"
						editToggle={true}
					/>
				</div>
			) : (
				<ContentEditor bcId={id} bcType={type} content={content} />
			)}
		</>
	);
}

export default Recording;
