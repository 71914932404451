import React, { useEffect } from "react";
import { withStore } from "../../utils/withStore";
import ContentEditorStore from "./stores/content-editor-store";
import Viewer from "./components/viewer";
import { ContentEditorEl } from "./elements";

const ContentEditor = ({ bcType, bcId, content }) => {
  const { updateConfig, setAllContent } = ContentEditorStore.useContainer();

  useEffect(() => {
    updateConfig({
      bcType,
      bcId,
    });
  }, [bcId, bcType, updateConfig]);

  useEffect(() => {
    setAllContent(content);
  }, [content, setAllContent]);

  return (
    <ContentEditorEl>
      <Viewer />
    </ContentEditorEl>
  );
};

export default withStore([ContentEditorStore])(ContentEditor);
