import React from "react";
import { Router, Redirect } from "@reach/router";
import { ActiveSecEl, FilterNavEl } from "../../elements";
import { teacherFilterTabs } from "../../config/routes";
import FilterTab from "../common/filterTab";
import TabMap from "../../../../components/tabMap";
import PrivateRoute from "../../../../utils/privateRoute";
import TeacherContent from "./components/teacherContent";
import TeacherInfo from "./components/teacherInfo";
import TeacherStatusbar from "./components/teacherStatusbar";
import TeacherStore from "./store/teacherStore";
import RequestView from "../requestView";

function Teachers() {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        <TabMap
          tabs={teacherFilterTabs}
          tabStyle={(props) => <FilterTab {...props} />}
        />
      </FilterNavEl>
      <TeacherStore.Provider>
        <Router component={ActiveSecEl}>
          <Redirect from="/" to="approved" noThrow />
          <RequestView
            path=":filter/*"
            infoComponent={TeacherInfo}
            contentComponent={TeacherContent}
            statusComponent={TeacherStatusbar}
            Store={TeacherStore}
          />
        </Router>
      </TeacherStore.Provider>
    </ActiveSecEl>
  );
}

export default PrivateRoute(Teachers);
