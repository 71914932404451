import { yupResolver } from "@hookform/resolvers";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../../../../components/button";
import Input from "../../../../../components/input";
import { email, isRequired, string } from "../../../../../utils/form-errors";
import { useToggle } from "../../../../../utils/hooks/useToggle";
import AuthStore from "../../../stores/authStore";
import { PasswordLoginEl } from "../elements";

const PasswordLogin = () => {
  const { passwordLogin } = AuthStore.useContainer();
  const [submitting, setSubmitting] = useToggle(false);
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string(string)
          .email(email)
          .required(isRequired`Email`),
        password: yup.string(string).required(isRequired`Password`),
      })
    ),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values) => {
    setSubmitting(true);
    await passwordLogin(values.email, values.password);
    setSubmitting(false);
  };

  return (
    <PasswordLoginEl onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={Input}
        control={control}
        name="email"
        rules={{ required: true }}
        error={errors.email}
        label="Email Address"
      />
      <Controller
        as={<Input inputType="password" />}
        control={control}
        type="password"
        name="password"
        rules={{ required: true }}
        error={errors.password}
        label="Password"
      />

      <Button type="submit" disabled={submitting}>
        Sign In
      </Button>
    </PasswordLoginEl>
  );
};

export default PasswordLogin;
