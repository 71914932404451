import { Button } from "@material-ui/core";
import { pluck } from "ramda";
import React from "react";
import Form from "../../../../../components/forms";
import TeacherStore from "../../teachers/store/teacherStore";
import { CoHostEl } from "../elements";
import BroadcastClassStore from "../store/broadcastClassStore";

function CoHosts() {
  const { curRequest: request, addCoHost } = BroadcastClassStore.useContainer();
  const { allRequests: allTeachers } = TeacherStore.useContainer();

  const coHosts = (allTeachers || [])
    .filter((teacher) => request.cohosts.includes(teacher.id))
    .map(({ id, profile: { name, email } }) => ({ id, name, email }));

  const teachersList = (allTeachers || [])
    .filter(({ status }) => status === "APPROVED" || status === "PENDING_EDITS")
    .map(({ id, profile: { name } }) => ({ label: name, value: id }))
    .filter(
      ({ value }) =>
        ![...pluck("id", coHosts), request.profile.teacherId].includes(value)
    );

  return (
    <>
      <Form
        formType="list"
        name={`${request.id}-cohosts`}
        options={teachersList}
        changeRequest={async ({ value }) => await addCoHost(value)}
        resetOnSubmit={true}
        placeholder="Select Co-host"
      />
      <CoHostEl>
        <ol>
          {coHosts.map((val, i) => (
            <li key={`co-host-${i}`}>
              {val.name} - {val.email}
              <Button onClick={async () => {}}>Remove CoHost</Button>
            </li>
          ))}
        </ol>
      </CoHostEl>
    </>
  );
}

export default CoHosts;
