import React from "react";
import { ActiveSecContentEl } from "../../elements";
import { Router } from "@reach/router";
import Loading from "../../../../components/loading";
import SearchSidebar from "../common/searchSidebar";
import ActiveSection from "./components/activeSection";
import InactiveSection from "./components/inactiveSection";

function RequestView({ ...props }) {
  const { loading, filteredRequests } = props.Store.useContainer();
  return (
    <Loading loading={loading}>
      <ActiveSecContentEl>
        <SearchSidebar tabs={filteredRequests} />
        <Router component={ActiveSecContentEl}>
          <InactiveSection path="/" />
          <ActiveSection path=":requestId" {...props} />
          <ActiveSection path=":requestId/:selectedRequestId" {...props} />
        </Router>
      </ActiveSecContentEl>
    </Loading>
  );
}

export default RequestView;
