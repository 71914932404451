import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { ListFormEl } from "../elements";

function ListForm({ label, changeRequest, defaultVal, isCreatable, ...props }) {
  const onChange = async (value) => {
    await changeRequest(value);
  };
  return (
    <ListFormEl>
      {label && <p>{label}:</p>}
      <div>
        {isCreatable ? (
          <CreatableSelect
            onChange={onChange}
            value={defaultVal}
            styles={customStyles}
            {...props}
          />
        ) : (
          <Select
            onChange={onChange}
            value={defaultVal}
            {...props}
            styles={customStyles}
          />
        )}
      </div>
    </ListFormEl>
  );
}

export default ListForm;

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    borderBottom: "1px solid #cdcbcd",
    borderRadius: 0,
    boxShadow: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#2D2D2D",
    fontWeight: 300,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
};
