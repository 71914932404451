import { applySpec, join, juxt, map, pathOr, pipe } from "ramda";

const CoursePaymentInfo = applySpec({
	earnings: pathOr({}, ["earnings"]),
	bills: pipe(
		pathOr([], ["allBills"]),
		map(
			applySpec({
				id: pathOr("", ["id"]),
				userId: pathOr("", ["userId"]),
				email: pathOr("", ["email"]),
				name: pathOr("", ["name"]),
				courseRegId: pathOr("", ["courseRegistrationId"]),
				price: pipe(
					juxt([pathOr("", ["currency"]), pathOr("", ["amount"])]),
					join(" ")
				),
				amount: pathOr("", ["amount"]),
				currency: pathOr("", ["currency"]),
				status: pathOr("", ["status"]),
				createdAt: pathOr("", ["createdAt"]),
				gateway: pathOr("", ["paymentGateway"]),
				batchName: pathOr("", ["batchName"]),
				regType: pathOr("STANDARD", ["regType"]),
			})
		)
	),
	registrations: pipe(
		pathOr([], ["courseRegs"]),
		map(
			applySpec({
				id: pathOr("", ["id"]),
				userId: pathOr("", ["studentId"]),
				email: pathOr("", ["email"]),
				name: pathOr("", ["name"]),
				createdAt: pathOr("", ["createdAt"]),
				batchName: pathOr("", ["batchName"]),
				status: pathOr("", ["status"]),
			})
		)
	),
});

export default CoursePaymentInfo;
