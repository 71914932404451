import React from "react";
import TextInput from "./components/textInput";
import Toggle from "./components/toggle";
import Textbox from "./components/textBox";
import TextEditor from "./components/textEditor";
import Switch from "./components/switch";
import Checkbox from "./components/checkbox";
import Dropdown from "./components/dropdown";
import DatePicker from "./components/datePicker";
import TimePicker from "./components/timePicker";
import DateTimePicker from "./components/dateTimePicker";

const Input = ({ type, ...props }) => {
  switch (type) {
    case "toggle":
      return <Toggle {...props} />;
    case "textbox":
      return <Textbox {...props} />;
    case "texteditor":
      return <TextEditor {...props} />;
    case "switch":
      return <Switch {...props} />;
    case "checkbox":
      return <Checkbox {...props} />;
    case "list":
      return <Dropdown {...props} />;
    case "datepicker":
      return <DatePicker {...props} />;
    case "timepicker":
      return <TimePicker {...props} />;
    case "dateTimePicker":
      return <DateTimePicker {...props} />;
    default:
      return <TextInput {...props} />;
  }
};

export default Input;

Input.defaultProps = {
  type: null,
};
