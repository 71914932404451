import React from "react";
import { H5 } from "../../../../../styles/typography";
import Form from "../../../../../components/forms";
import { Notification } from "../../../../../modules/toastNotification";
import BroadcastClassStore from "../store/broadcastClassStore";

const Restream = () => {
	const {
		toggleYoutubeUrl,
		editBroadcastClass,
		curRequest: {
			id,
			hasYoutubeUrl = false,
			youtubeUrl = "",
			restreamUrl = "",
		},
	} = BroadcastClassStore.useContainer();
	return (
		<>
			<H5>Youtube Integration</H5>

			<Form
				formType="toggle"
				name={`${id}-youtubeUrl-toggle`}
				defaultVal={hasYoutubeUrl}
				onChange={async (val) => await toggleYoutubeUrl(val)}
				label={"has Youtube Url"}
				type="toggle"
			/>
			<Form
				formType="text"
				name={`${id}-Bc-youtube-Url`}
				defaultVal={youtubeUrl}
				submitRequest={async (val) => {
					const youtube_regex =
						/^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|live\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
					const parsed = val.match(youtube_regex);
					if (parsed && parsed[2]) {
						await editBroadcastClass({ youtubeUrl: val });
					} else {
						Notification("error", "Invalid Youtube URL");
					}
				}}
				label={"Youtube Url"}
				type="text"
			/>
			<Form
				formType="text"
				name={`${id}-Bc-restream-Url`}
				defaultVal={restreamUrl}
				submitRequest={async (val) => {
					const Restream_regex =
						/^https:\/\/studio\.restream\.io\/[a-zA-Z0-9-]+$/;

					const parsed = val.match(Restream_regex);
					if (parsed && parsed[0]) {
						await editBroadcastClass({ restreamUrl: val });
					} else {
						Notification("error", "Invalid Restream URL");
					}
				}}
				label={"Restream Url"}
				type="text"
			/>
		</>
	);
};

export default Restream;
