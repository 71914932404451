import React from 'react'
import ReactPlayer from 'react-player'

const AudioPlayer = ({ url, playing }) => (
  <div className='audioPlayer' onClick={(e) => e.stopPropagation()}>
    <ReactPlayer
      url={url}
      playing={playing}
      controls
      width={'100%'}
      height={'4em'}
    />
  </div>
)

export default AudioPlayer

AudioPlayer.defaultProps = {
  playing: true,
}
