import { useState } from "react";
import { createContainer } from "unstated-next";

const DocumentsViewerStore = createContainer(() => {
  const [doc, setDocument] = useState(null);

  const openDocument = (doc) => setDocument(doc);
  // const openDocument = (doc) => console.log(doc);

  return {
    doc,
    openDocument,
    closeDocument: openDocument,
  };
});

export default DocumentsViewerStore;
