import { Api } from "./Configuration";

export const getClasses = (classType) =>
	Api.protected
		.get(`admin/broadcast-classes/${classType}`)
		.then((data) => data?.result)
		.catch(() => false);

export const addEmailToBroadcastClassWhitelist = (
	broadcastType,
	broadcastClassId,
	emails
) =>
	Api.protected.put(`admin/broadcast-class/${broadcastType}/whitelist`, {
		broadcastClassId,
		emails,
	});

export const sendMasterclassEmail = (broadcastType, id, body) =>
	Api.protected.post(
		`admin/broadcast-class/${broadcastType}/${id}/send-generic-instructions`,
		body
	);

export const editBroadcastClass = (broadcastType, broadcastClassId, data) =>
	Api.protected.put(`admin/broadcast-class/${broadcastType}`, {
		...data,
		broadcastClassId,
	});

export const deleteClass = (broadcastType = "master-class", id) =>
	Api.protected.delete(`broadcast-class/${broadcastType}/${id}`);

export const deleteSeries = (broadcastType = "master-class", id) =>
	Api.protected.delete(`series/${id}/${broadcastType}`);

export const sendBroadcastJoinInstructions = (
	broadcastType = "master-class",
	broadcastClassId
) =>
	Api.protected.post(
		`admin/broadcast-class/${broadcastType}/${broadcastClassId}/send-instructions`
	);

export const sendBroadcastReminderEmails = (
	broadcastType = "master-class",
	broadcastClassId
) =>
	Api.protected.post(
		`admin/broadcast-class/${broadcastType}/${broadcastClassId}/send-reminder`
	);

export const sendBroadcastRecordingEmails = (
	broadcastType = "master-class",
	broadcastClassId
) =>
	Api.protected.post(
		`admin/broadcast-class/${broadcastType}/${broadcastClassId}/send-recording`
	);

export const toggleModerator = (
	broadcastType,
	broadcastClassId,
	email,
	state
) =>
	Api.protected.put(
		`admin/broadcast-class/${broadcastType}/toggle-moderator-state`,
		{
			broadcastClassId,
			emails: [email],
			state,
		}
	);

export const addDiscount = (broadcastType, broadcastClassId, data) =>
	Api.protected.post(
		`broadcast-class/${broadcastType}/${broadcastClassId}/coupon`,
		{
			couponValue: data,
		}
	);

export const approveSeriesRequest = (type = "master-class", id, status) =>
	Api.protected.put(`admin/series/approve-reject/${id}/${type}`, { status });

export const approveMasterclass = (type, broadcastClassId) =>
	Api.protected.put(`broadcast-class/${type}/admin/approve`, {
		broadcastClassId,
	});

export const toggleSeriesPrivate = (type = "master-class", id, data) =>
	Api.protected.put(`series/${type}/${id}/alter-series-state`, {
		isPrivate: data,
	});

export const uploadMasterclassCover = (broadcastType, masterclassId, data) =>
	Api.protected.post(
		`admin/broadcast-class/${broadcastType}/${masterclassId}/upload-cover-image`,
		data
	);

export const getPaymentInfo = (id) => Api.protected.get(`admin/payments/${id}`);

// export const getSeriesPaymentInfo = (id) =>
//   Api.protected.get(`admin/payments/${id}`);

export const getPaymentInfoCSV = (id) =>
	Api.protected.get(`admin/payments/${id}/csv`, {
		meta: {
			type: "csv",
		},
	});

export const markBillPaid = (broadcastType, billId) =>
	Api.protected.post(`admin/${broadcastType}/make-bill-paid`, { billId });

export const deregister = (broadcastType, bcrId) =>
	Api.protected.put(`broadcast-class/${broadcastType}/deregister/${bcrId}`);

export const rescheduleClass = (broadcastType, id, startTime, msg) =>
	Api.protected.put(`admin/broadcast-class/${broadcastType}/${id}/reschedule`, {
		broadcastClassId: id,
		startTime,
		rescheduleMessage: msg,
	});

export const lobbyAssetUpload = (broadcastType, classId, data) =>
	Api.protected.post(
		`broadcast-class/${broadcastType}/${classId}/prerolldocuments`,
		data
	);

export const deleteLobbyAsset = (broadcastType, classId, fileId) =>
	Api.protected.delete(
		`broadcast-class/${broadcastType}/${classId}/prerolldocuments/${fileId}`
	);

export const updateLobbyAsset = (
	broadcastType,
	broadcastClassId,
	fileId,
	weight
) =>
	Api.protected.put(
		`broadcast-class/${broadcastType}/prerolldocuments/${fileId}`,
		{
			broadcastClassId,
			weight,
		}
	);

export const deleteRecordingId = (broadcastType, classId) =>
	Api.protected.delete(`broadcast-class/${broadcastType}/${classId}/recording`);

const addCoHost = (broadcastType, broadcastClassId, coHostId) =>
	Api.protected.put(`broadcast-class/${broadcastType}/add-co-host`, {
		broadcastClassId,
		coHostId,
	});

const startLiveStream = (entitySubType, entityId, rtmp) =>
	Api.protected.post("admin/social/broadcast/start", {
		entityId,
		entitySubType,
		rtmp,
	});

const stopLiveStream = (entitySubType, entityId) =>
	Api.protected.post("admin/social/broadcast/end", {
		entityId,
		entitySubType,
	});

const editSeries = (seriesId, type, data) =>
	Api.protected.put(`series/${seriesId}/${type}`, data);

const resourcesUpload = (classType, classId, data) =>
	Api.protected.post(`broadcast-class/${classType}/${classId}/documents`, data);

const deleteResource = (classType, classId, documentId) =>
	Api.protected.delete(
		`broadcast-class/${classType}/${classId}/documents/${documentId}`
	);

export const endClass = (broadcastType, broadcastClassId, ended) =>
	Api.protected.put(`broadcast-class/${broadcastType}/end`, {
		broadcastClassId,
		ended,
	});

export const addPostRollClasses = (classType, broadcastClassId, classesList) =>
	Api.protected.put(`admin/broadcast-class/${classType}/post-roll/classes`, {
		broadcastClassId,
		classesList,
	});
export const addCorrespondingCoursesClasses = (QnAId, CourseId) =>
	Api.protected.post(`admin/broadcast-class/qna/corresponding-course`, {
		QnAId,
		CourseId,
	});

export const highlightUserChatMessages = (
	classType,
	registrationId,
	shouldHighlight = false,
	isSeries = false
) =>
	Api.protected.put(
		`admin/broadcast-class/${classType}/registration/${registrationId}/highlight-messages`,
		{
			highlightMessages: shouldHighlight,
			isSeries: isSeries,
		}
	);

export const toggleLiveClassRegistrations = (
	classType,
	broadcastClassId,
	comingSoon = false
) =>
	Api.protected.put(
		`admin/broadcast-class/${classType}/${broadcastClassId}/coming-soon`,
		{
			comingSoon,
		}
	);

export const BroadcastApi = {
	whitelistEmail: addEmailToBroadcastClassWhitelist,
	deleteClass,
	deleteSeries,
	toggleModerator,
	addDiscount,
	approveSeriesRequest,
	approveClassRequest: approveMasterclass,
	toggleSeriesPrivate,
	uploadCover: uploadMasterclassCover,
	getPaymentInfo,
	getPaymentInfoCSV,
	markBillPaid,
	rescheduleClass,
	lobbyAssetUpload,
	deleteLobbyAsset,
	deleteRecordingId,
	editBroadcastClass,
	addCorrespondingCoursesClasses,
	addCoHost,
	startLiveStream,
	stopLiveStream,
	editSeries,
	resourcesUpload,
	deleteResource,
	endClass,
	updateLobbyAsset,
	addPostRollClasses,
	highlightUserChatMessages,
	deregister,
	toggleLiveClassRegistrations,
};
