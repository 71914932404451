import { createContainer } from "unstated-next";
import { useMap } from "../../../utils/hooks/useMap";
import { useCallback, useState } from "react";
import ContentApis from "../apis";
import { dissocPath, lensPath, set } from "ramda";

const ContentEditorStore = createContainer(() => {
  const [config, updateConfig] = useState({ bcType: "", bcId: "" });
  const [showEditor, toggleEditor] = useState(false);

  const [content, { setAll: setAllContent }] = useMap({});

  const createContent = useCallback(
    async (path, values) => {
      const { success, result } = await ContentApis.createContent(
        config.bcType,
        config.bcId,
        path,
        values
      );

      if (success) {
        setAllContent((oldContent) => {
          return set(
            lensPath([...path.split("/").filter(Boolean), result.id]),
            result,
            oldContent
          );
        });
        toggleEditor(false);
      }
    },
    [config.bcId, config.bcType, setAllContent]
  );

  const editContent = useCallback(
    async (path, values) => {
      const { success, result } = await ContentApis.editContent(
        config.bcType,
        config.bcId,
        path,
        values
      );

      if (success) {
        setAllContent((oldContent) => {
          return set(
            lensPath(path.split("/").filter(Boolean)),
            result,
            oldContent
          );
        });
        toggleEditor(false);
      }
    },
    [config.bcId, config.bcType, setAllContent]
  );

  const deleteContent = useCallback(
    async (path) => {
      console.log({ path });
      const { success } = await ContentApis.deleteContent(
        config.bcType,
        config.bcId,
        path
      );

      if (success) {
        setAllContent((oldContent) => {
          return dissocPath(path.split("/").filter(Boolean), oldContent);
        });
        toggleEditor(false);
      }
    },
    [config.bcId, config.bcType, setAllContent]
  );

  return {
    config,
    content,
    showEditor,
    toggleEditor,
    setAllContent,
    updateConfig,
    createContent,
    editContent,
    deleteContent,
  };
}, []);

export default ContentEditorStore;
