export const createMasterClassesList = (classes = []) =>
  classes.map(({ name, teacher, id, type }) => ({
    label: `${name} - ${teacher?.userInfo?.name && teacher.userInfo.name}`,
    value: {
      broadcastClassId: id,
      type: type,
    },
  }));

export const createTeachersList = (data = {}) =>
  Object.keys(data)
    .reduce((merged, key) => [...merged, ...data[key]], [])
    .filter(({ status }) => status !== "PENDING_APPROVAL")
    .map(({ user, id }) => ({
      label: `${user?.profile?.name}`,
      value: {
        teacherId: id,
      },
    }));
