import React, { useEffect } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import Input from "../../../../../components/input";
import GenericEmailStore from "../store/genericEmailStore";
import { SelectedEntitiesEl } from "../elements";

function SelectedBCs({ control, reset }) {
  const {
    masterClassesList,
    removeBC,
    pushBC,
  } = GenericEmailStore.useContainer();

  const selectedBC = useWatch({
    control,
    name: "bcList",
    defaultValue: "",
  });
  const {
    fields: selectedBCs,
    append: appendSelBC,
    remove: removeSelBC,
  } = useFieldArray({
    control,
    name: "bcs",
  });

  useEffect(() => {
    if (selectedBC !== "") {
      removeBC(masterClassesList.indexOf(selectedBC));
      appendSelBC(selectedBC);
      reset("bcList", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBC, appendSelBC, removeBC]);

  const deleteBC = (index) => {
    pushBC(selectedBCs[index]);
    removeSelBC(index);
  };

  return (
    selectedBCs.length > 0 && (
      <SelectedEntitiesEl>
        {selectedBCs.map((item, index) => (
          <div key={item.id} className="entity">
            <div className="header">
              <p>
                <b>{item.label}</b>
              </p>
              <span onClick={() => deleteBC(index)}>
                <b>x</b>
              </span>
            </div>
            <Controller
              control={control}
              name={`bcs[${index}].type`}
              defaultValue={item.value.type}
              render={() => <input type="hidden" />}
            />
            <Controller
              control={control}
              name={`bcs[${index}].broadcastClassId`}
              defaultValue={item.value.broadcastClassId}
              render={() => <input type="hidden" />}
            />
            <div className="checkboxes">
              <Controller
                control={control}
                name={`bcs[${index}].paidStudents`}
                defaultValue={false}
                render={(props) => (
                  <Input
                    type="checkbox"
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                    label="Paid Students"
                  />
                )}
              />
              <Controller
                control={control}
                name={`bcs[${index}].unPaidStudents`}
                defaultValue={false}
                render={(props) => (
                  <Input
                    type="checkbox"
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                    label="Unpaid Students"
                  />
                )}
              />
            </div>
          </div>
        ))}
      </SelectedEntitiesEl>
    )
  );
}

export default SelectedBCs;
