import React from "react";
import { Router, Redirect } from "@reach/router";
import { ActiveSecEl, FilterNavEl } from "../../elements";
import TabMap from "../../../../components/tabMap";
import FilterTab from "../common/filterTab";
import { contentFilterTabs } from "../../config/routes";
import ClassBanners from "./components/classBanners";
import FeaturedArtists from "./components/featuredArtists";
import { clearCache } from "../../../../api/auth";
import { Notification } from "../../../../modules/toastNotification";
import Button from "../../../../components/button";

function Content() {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        <TabMap
          tabs={contentFilterTabs}
          tabStyle={(props) => <FilterTab {...props} />}
        />
        <Button
          onClick={async () => {
            const status = await clearCache();
            if (status === 200)
              Notification("success", "Cache cleared successfully.");
            else Notification("error", "Problem clearing cache");
          }}
          withconfirmation
          style={{ position: "absolute", right: "1em", top: "1em" }}
        >
          Clear Cache
        </Button>
      </FilterNavEl>
      <Router component={ActiveSecEl}>
        <Redirect from="/" to="banners" noThrow />
        <ClassBanners path="banners/*" />
        <FeaturedArtists path="featured-artists/*" />
      </Router>
    </ActiveSecEl>
  );
}

export default Content;
