import localforage from "localforage";
import { useCallback, useEffect } from "react";
import { createContainer } from "unstated-next";
import { passwordAuthRequest } from "../../../api/auth";
import User from "../../../schemas/user";
import { useLoading } from "../../../utils/hooks/useLoading";
import { useMap } from "../../../utils/hooks/useMap";

export const LOCAL_STORE_KEY = "user";

const AuthStore = createContainer(() => {
  const [user, { setAll: setUser, reset: resetUser }] = useMap(User({}));
  const { loading, loadFailed, setLoaded } = useLoading();

  const userLoggedIn = useCallback(
    async (userData) => {
      const user = User(userData);

      await localforage.setItem(LOCAL_STORE_KEY, userData);

      setUser(user);
      setLoaded(true);
    },
    [setUser, setLoaded]
  );

  useEffect(() => {
    localforage
      .getItem(LOCAL_STORE_KEY)
      .then((value) => {
        if (value) {
          userLoggedIn(value);
        } else {
          setLoaded(true);
        }
      })
      .catch(() => setLoaded(false));
  }, [setLoaded, setUser, userLoggedIn]);

  const passwordLogin = useCallback(
    async (email, password) => {
      const { success, result } = await passwordAuthRequest(email, password);

      if (success) {
        userLoggedIn(result);
      }
    },
    [userLoggedIn]
  );

  const logout = useCallback(async () => {
    await localforage.removeItem(LOCAL_STORE_KEY);
    resetUser();
  }, [resetUser]);

  return {
    loading,
    loadFailed,
    user,
    passwordLogin,
    logout,
  };
});

export default AuthStore;
