import React from "react";
import { ClassInfoEl, ProfileInfoEl, ProfilePicEl } from "../elements";
import { H2 } from "../../../../../styles/typography";
import Accordion from "../../../../../components/accordian";
import Input from "../../../../../components/input";
import DropdownMenu from "../../../../../components/dropdownMenu";
import {
  sendBroadcastJoinInstructions,
  sendBroadcastRecordingEmails,
  sendBroadcastReminderEmails,
} from "../../../../../api/broadcastClass";
import { Notification } from "../../../../../modules/toastNotification";
import date from "date-and-time";
import BroadcastClassStore from "../store/broadcastClassStore";
import { Box, Modal, Typography } from "@material-ui/core";
import Form from "../../../../../components/forms";
import { useToggle } from "react-use";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";

function ClassInfo() {
  const {
    curRequest: request,
    params,
    editBroadcastClass,
  } = BroadcastClassStore.useContainer();

  const [showEditTitle, toggleEditTitle] = useToggle(false);
  const [showEditDescription, toggleEditDescription] = useToggle(false);

  console.log({ request });
  const emailActions = [
    {
      id: "send-email-1",
      name: "Send Joining Instructions",
      func: async () => {
        await sendBroadcastJoinInstructions(params.classType, request.id);
        Notification("success", `Joining instructions sent`);
      },
    },
    {
      id: "send-email-2",
      name: "Send Payment Reminders",
      func: async () => {
        await sendBroadcastReminderEmails(params.classType, request.id);
        Notification("success", `Payment Reminders sent`);
      },
    },
    {
      id: "send-email-3",
      name: "Send Recording Email",
      func: async () => {
        if (
          request.recordingId === "" &&
          !Object.keys(request.content || {}).length
        )
          Notification("error", `Recording ID or content not set`);
        else {
          await sendBroadcastRecordingEmails(params.classType, request.id);
          Notification("success", `Recording Email sent`);
        }
      },
    },
  ];

  return (
    <ClassInfoEl>
      {request?.type !== "series" ? (
        <>
          <ProfilePicEl image={request?.profile.profilePhoto} />
          <ProfileInfoEl>
            <H2
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {request?.profile.name}
              <CreateIcon
                style={{ cursor: "pointer", marginLeft: 8 }}
                onClick={() => toggleEditTitle(true)}
              />
            </H2>
            <p>Teacher: {request?.profile.teacher}</p>
            <p>Fees: {request?.profile.fee}</p>
            <p>
              Date and duration:{" "}
              {date.format(new Date(request?.startTime), "DD MMM YYYY hh:mmA")}{" "}
              - {date.format(new Date(request?.endTime), "hh:mmA")}
            </p>
            <p>
              Link:{" "}
              <a
                href={`${process.env.REACT_APP_BASE_URL}/${
                  request.livdemyId
                }/${params.classType.replace("-", "")}/${request.url}/${
                  request.version
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >{`/${request.livdemyId}/${params.classType.replace("-", "")}/${
                request.url
              }/${request.version}`}</a>
            </p>
            <DropdownMenu
              title="Send Emails"
              items={
                params.filter === "upcoming"
                  ? emailActions.slice(0, 2)
                  : emailActions.slice(-1)
              }
              withConfirmation
            />
            <Accordion title="Description">
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Input
                  type="texteditor"
                  value={request.profile.description}
                  theme="bubble"
                  disabled
                />
                <CreateIcon
                  style={{ cursor: "pointer", marginLeft: 8 }}
                  onClick={() => toggleEditDescription(true)}
                />
              </Box>
            </Accordion>
          </ProfileInfoEl>
        </>
      ) : (
        <ProfileInfoEl>
          <H2>{request.profile.name}</H2>
          <p>Teacher: {request.profile.teacher}</p>
          <p>Fees: {request.profile.fee}</p>
          <p>
            Link:{" "}
            <a
              href={`${process.env.REACT_APP_BASE_URL}/${
                request.livdemyId
              }/${params.classType.replace("-", "")}/${request.type}/${
                request.url
              }/${request.version}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`/${request.livdemyId}/${params.classType.replace("-", "")}/${
              request.type
            }/${request.url}/${request.version}`}</a>
          </p>
          <Accordion title="Description">
            <Input
              type="texteditor"
              value={request.profile.description}
              theme="bubble"
              disabled
            />
          </Accordion>
        </ProfileInfoEl>
      )}
      <Modal open={showEditTitle} onClose={() => toggleEditTitle(false)}>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              padding: 16,
              background: "white",
              borderRadius: 4,
              width: "60%",
            }}
          >
            <Typography
              variant={"h5"}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0.5em",
              }}
            >
              Edit Title
              <CloseIcon
                style={{ cursor: "pointer", marginLeft: 8 }}
                onClick={() => toggleEditTitle(false)}
              />
            </Typography>
            <Form
              formType="text"
              name="title"
              defaultVal={request?.profile?.name}
              submitRequest={(val) =>
                editBroadcastClass({
                  name: val,
                })
              }
              editToggle={true}
              label="Class Title"
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showEditDescription}
        onClose={() => toggleEditDescription(false)}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              padding: 16,
              background: "white",
              borderRadius: 4,
              width: "60%",
              maxHeight: "85vh",
              overflow: "auto",
            }}
          >
            <Typography
              variant={"h5"}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0.5em",
              }}
            >
              Edit Description
              <CloseIcon
                style={{ cursor: "pointer", marginLeft: 8 }}
                onClick={() => toggleEditDescription(false)}
              />
            </Typography>
            <Form
              formType="text"
              type="texteditor"
              name="description"
              defaultVal={request?.profile?.description}
              submitRequest={(val) =>
                editBroadcastClass({
                  description: val,
                })
              }
              editToggle={true}
              label="Class description"
            />
          </Box>
        </Box>
      </Modal>
    </ClassInfoEl>
  );
}

export default ClassInfo;
