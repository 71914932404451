export const string = `Entered value is not a valid string`;
export const url = `Entered value is not a valid URL`;
export const number = `Entered value is not a number`;
export const isRequired = (field) => `${field} is required`;
export const min = (x) => `Enter at least ${x} characters`;
export const email = `Please enter a valid email address`;
export const numberRange = (min, max) =>
  `Please enter value between ${min} and ${max}`;
export const futureDate = `Please select a future date and time`;
export const minNumber = (min) => `Please select a number greater than ${min}`;
