import { Redirect, Router } from "@reach/router";
import React from "react";
import TabMap from "../../../../components/tabMap";
import { ActiveSecEl, FilterNavEl } from "../../elements";
import FilterTab from "../common/filterTab";
import Recordings from "./components/recordings.js";
import Subscriptions from "./components/subscriptions/index";
import { reportsFilterTabs } from "./config";

function Reports() {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        <TabMap
          tabs={reportsFilterTabs}
          tabStyle={(props) => <FilterTab {...props} />}
        />
      </FilterNavEl>
      <Router component={ActiveSecEl} style={{ justifyContent: "flex-start" }}>
        <Redirect from="/" to="recordings" />
        <Recordings path="/recordings" />
        <Subscriptions path="/subscriptions" />
      </Router>
    </ActiveSecEl>
  );
}

export default Reports;
