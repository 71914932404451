import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { string, isRequired } from "../../../utils/form-errors";
import { useToggle } from "../../../utils/hooks/useToggle";
import Button from "../../button";
import Input from "../../input";

function PaidUnPaidEmail({ submitRequest, withSubject = false, ...rest }) {
  const [submitting, setSubmitting] = useToggle(false);
  const { handleSubmit, errors, control, getValues } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        emailText: yup
          .string(string)
          .test(
            "body-required-test",
            isRequired`Email Body`,
            (value) =>
              !((!value || value === "<p><br></p>") && !getValues("body2"))
          )
          .test(
            "only-one-body-test",
            "Email body can be filled in only one box",
            (value) =>
              (!!value && value !== "<p><br></p>") ^ !!getValues("body2")
          ),
        body2: yup.string(string),
        paidCheckbox: yup.boolean(),
        unpaidCheckbox: yup.boolean(),
        subject: yup.string(string),
      })
    ),
    defaultValues: {
      emailText: "",
      body2: "",
      paidCheckbox: false,
      unpaidCheckbox: false,
      subject: "",
    },
  });
  const onSub = async (values) => {
    setSubmitting(true);

    const data = {
      body: values.emailText,
      paid: values.paidCheckbox,
      unpaid: values.unpaidCheckbox,
    };
    if (withSubject) data.subject = values.subject;

    data.body =
      !data.body || data.body === "<p><br></p>" ? values.body2 : data.body;

    await submitRequest(data);
  };

  return (
    <form onSubmit={handleSubmit(onSub)}>
      {withSubject && (
        <>
          <Controller
            as={<Input type="text" />}
            control={control}
            name="subject"
            error={errors.subject}
            placeholder="Subject"
          />
          <br />
          <br />
        </>
      )}
      <Controller
        as={<Input type="texteditor" placeholder="Email Body (Styled)" />}
        control={control}
        name="emailText"
        error={errors.emailText}
        placeholder="Email body here"
      />
      {errors?.emailText && (
        <p className="error">{errors?.emailText?.message}</p>
      )}
      <Controller
        as={
          <Input
            type="textbox"
            placeholder="Email Body (HTML)"
            className="bodyBox2"
          />
        }
        control={control}
        name="body2"
        errors={errors.emailText}
        rowsMin={5}
      />
      <Controller
        control={control}
        error={errors.paidCheckbox}
        name="paidCheckbox"
        render={(props) => (
          <Input
            type="checkbox"
            onChange={(e) => props.onChange(e.target.checked)}
            checked={props.value}
            label="Paid"
          />
        )}
      />
      <Controller
        control={control}
        error={errors.unpaidCheckbox}
        name="unpaidCheckbox"
        render={(props) => (
          <Input
            type="checkbox"
            onChange={(e) => props.onChange(e.target.checked)}
            checked={props.value}
            label="Unpaid"
          />
        )}
      />
      <br />
      <Button type="submit" disabled={rest?.disabled || submitting}>
        Submit
      </Button>
    </form>
  );
}

export default PaidUnPaidEmail;
