import React from "react";
import { ClassSettingsEl } from "../elements";
import RescheduleClass from "./rescheduleClass";
import BroadcastClassStore from "../store/broadcastClassStore";
import Form from "../../../../../components/forms";
import languages from "../../../../../assets/data/languages";
import { pluck } from "ramda";

function ClassSettings() {
  const {
    curRequest: {
      id,
      paidRegistrationLimit,
      level,
      languages: selectedLanguages,
      preRollTime,
    },
    editBroadcastClass,
  } = BroadcastClassStore.useContainer();

  return (
    <ClassSettingsEl>
      <div className="class-setting-sec">
        <Form
          formType="list-with-submit"
          name={`${id}-preRollTime`}
          options={[
            { label: "15 minutes", value: 15 },
            { label: "30 minutes", value: 30 },
          ]}
          defaultVal={{ label: `${preRollTime} minutes`, value: preRollTime }}
          placeholder="Select pre roll Time"
          onSubmit={(val) =>
            editBroadcastClass({ preRollTime: val.value }, "Pre roll Time")
          }
          label="Pre Roll Time"
        />
      </div>
      <div className="class-setting-sec">
        <Form
          formType="list"
          name={`${id}-level`}
          options={[
            { label: "Beginner", value: "Beginner" },
            { label: "Intermediate", value: "Intermediate" },
            { label: "Advanced", value: "Advanced" },
          ]}
          defaultVal={{ label: level, value: level }}
          label="Class Level"
          placeholder="Select class level"
          changeRequest={(val) => editBroadcastClass({ level: val.value })}
          editToggle={true}
        />
        <Form
          formType="list"
          name={`${id}-lang`}
          options={languages}
          label="Languages"
          isMulti
          value={selectedLanguages.map((lang) => ({
            label: lang,
            value: lang,
          }))}
          placeholder="Select Languages"
          changeRequest={(val) =>
            editBroadcastClass({ languages: pluck("value", val) })
          }
        />
      </div>
      <div className="class-setting-sec class-registration-limit">
        <Form
          formType="text"
          name={`${id}-registrationLimit`}
          defaultVal={paidRegistrationLimit}
          submitRequest={(val) =>
            editBroadcastClass({ paidRegistrationLimit: val })
          }
          editToggle={true}
          label="Paid Registration Limit"
        />
      </div>
      <div className="class-setting-sec">
        <RescheduleClass />
      </div>
    </ClassSettingsEl>
  );
}

export default ClassSettings;
