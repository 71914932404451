import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import styled from "styled-components";

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  background-color: white;
  border: 0;
  border-bottom: 1px solid black;
`;

const Textbox = ({ ...props }) => {
  return <Textarea {...props} />;
};

export default Textbox;
