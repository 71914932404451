import { Api } from "../../../api/Configuration";

const createContent = (bcType, bcId, path, content) =>
  Api.protected.post(`admin/broadcast-class/${bcType}/${bcId}/content`, {
    path,
    content,
  });

const editContent = (bcType, bcId, path, content) =>
  Api.protected.put(`admin/broadcast-class/${bcType}/${bcId}/content`, {
    path,
    content,
  });

const deleteContent = (bcType, bcId, path) =>
  Api.protected.delete(`admin/broadcast-class/${bcType}/${bcId}/content?path=${path}`, {
    path,
  });

const ContentApis = {
  createContent,
  editContent,
  deleteContent,
};

export default ContentApis;

window.contentApis = ContentApis;
