import React, { useEffect } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import Input from "../../../../../components/input";
import GenericEmailStore from "../store/genericEmailStore";
import { SelectedEntitiesEl } from "../elements";

function SelectedTeachers({ control, reset }) {
  const {
    teachersList,
    removeTeacher,
    pushTeacher,
  } = GenericEmailStore.useContainer();

  const selectedTeacher = useWatch({
    control,
    name: "teachersList",
    defaultValue: "",
  });
  const {
    fields: selectedTeachers,
    append: appendSelTeacher,
    remove: removeSelTeacher,
  } = useFieldArray({
    control,
    name: "teachers",
  });

  useEffect(() => {
    if (selectedTeacher !== "") {
      removeTeacher(teachersList.indexOf(selectedTeacher));
      appendSelTeacher(selectedTeacher);
      reset("teachersList", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeacher, appendSelTeacher, removeTeacher]);

  const deleteTeacher = (index) => {
    pushTeacher(selectedTeachers[index]);
    removeSelTeacher(index);
  };

  return (
    selectedTeachers.length > 0 && (
      <SelectedEntitiesEl>
        {selectedTeachers.map((item, index) => (
          <div key={item.id} className="entity">
            <div className="header">
              <p>
                <b>{item.label}</b>
              </p>
              <span onClick={() => deleteTeacher(index)}>
                <b>x</b>
              </span>
            </div>
            <Controller
              control={control}
              name={`teachers[${index}].teacherId`}
              defaultValue={item.value.teacherId}
              render={() => <input type="hidden" />}
            />
            <div className="checkboxes">
              <Controller
                control={control}
                name={`teachers[${index}].followers`}
                defaultValue={false}
                render={(props) => (
                  <Input
                    type="checkbox"
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                    label="Followers"
                  />
                )}
              />
              <Controller
                control={control}
                name={`teachers[${index}].paid`}
                defaultValue={false}
                render={(props) => (
                  <Input
                    type="checkbox"
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                    label="Paid Students"
                  />
                )}
              />
              <Controller
                control={control}
                name={`teachers[${index}].unpaid`}
                defaultValue={false}
                render={(props) => (
                  <Input
                    type="checkbox"
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                    label="Unpaid Students"
                  />
                )}
              />
            </div>
          </div>
        ))}
      </SelectedEntitiesEl>
    )
  );
}

export default SelectedTeachers;
