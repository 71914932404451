import React, { useState } from "react";
import PropTypes from "prop-types";
import { UploadedFilesEl, FileEl } from "../elements";
import Button from "../../../components/button";
import { getFileIcon } from "../../../helpers/files";
import DocumentsViewerStore from "../../documentsViewer/store/documentsViewerStore";

function FilesList({ deleteFile, files, onClick, canView }) {
  const [processing, setProcessing] = useState(false);

  const { openDocument } = DocumentsViewerStore.useContainer();

  return (
    <UploadedFilesEl className="files-list">
      {files.map((file, i) => (
        <FileEl
          key={`${file.id}-$[i]`}
          image={getFileIcon(file?.type, file.url)}
          clickable={onClick}
          canView={canView}
          onClick={() => (onClick ? onClick(file) : null)}
        >
          <div
            className="icon"
            onClick={() => (canView ? openDocument(file) : null)}
          ></div>
          <p
            className="file-name"
            onClick={() => (canView ? openDocument(file) : null)}
          >
            {file.name}
          </p>
          {deleteFile && (
            <Button
              disabled={processing}
              onClick={async (e) => {
                setProcessing(true);
                console.log("a");
                await deleteFile(file);
                setProcessing(false);
              }}
            >
              Delete
            </Button>
          )}
        </FileEl>
      ))}
      {files.length === 0 && "No files"}
    </UploadedFilesEl>
  );
}

export default FilesList;

FilesList.propTypes = {
  deleteFile: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      weight: PropTypes.number,
    })
  ),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  canView: PropTypes.bool,
};

FilesList.defaultProps = {
  deleteFile: null,
  files: [],
  canView: false,
  onClick: null,
};
