import { useCallback } from "react";
import useSWR from "swr";
import { createContainer } from "unstated-next";
import { bannerDataParser } from "../../../../../../../helpers/dataParsers";
import { Notification } from "../../../../../../../modules/toastNotification";
import {
  editClassBanner,
  createClassBanner,
} from "../../../../../../../api/classBanner";

const ClassBannerStore = createContainer(() => {
  const { data: allBanners, mutate, error } = useSWR([
    "admin/banners",
    "protected",
    bannerDataParser,
  ]);

  const editBanner = useCallback(
    async (data) => {
      const success = await editClassBanner(data);

      if (success) {
        await mutate();
        Notification("success", "Banner edited successfully");
      }
    },
    [mutate]
  );

  const createBanner = useCallback(
    async (data) => {
      const success = await createClassBanner(data);

      if (success) {
        await mutate();
        Notification("success", "Banner created successfully.");
      }
    },
    [mutate]
  );

  return {
    loading: !Boolean(allBanners),
    loadFailed: Boolean(error),
    allBanners: allBanners,
    editBanner,
    createBanner,
  };
});

export default ClassBannerStore;
