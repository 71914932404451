import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import logo from "../../../../assets/logo/logo-black.svg";
import { LogoEl } from "../../../../components/topNav/elements";
import PasswordLogin from "./components/passwordLogin";
import { HeadingEl, LoginEl } from "./elements";
import AuthStore from "../../stores/authStore";

const Login = () => {
  const { user } = AuthStore.useContainer();

  useEffect(() => {
    if (user.id) navigate("/dashboard", { replace: true });
  }, [user]);

  return (
    <LoginEl>
      <HeadingEl>
        Welcome to <LogoEl src={logo} />
      </HeadingEl>
      <PasswordLogin />
    </LoginEl>
  );
};

export default Login;
