import { Api } from "./Configuration";

export const createSchool = ({
	name,
	description,
	schoolHandle,
	location,
	image,
}) =>
	Api.protected.post("admin/school", {
		name,
		description,
		schoolHandle,
		location,
		image,
	});

export const addTeacher = (schoolId, teacherId) =>
	Api.protected.post("admin/school/addTeacher", {
		schoolId,
		teacherId,
	});
export const addCourse = (schoolId, courseId, type) =>
	Api.protected.post("admin/school/addCourse", {
		schoolId,
		courseId,
		type,
	});
export const addBc = (schoolId, BcId, type) =>
	Api.protected.post("admin/school/addBc", {
		schoolId,
		BcId,
		type,
	});

export const deleteTeacher = (schoolId, teacherId) =>
	Api.protected.delete(
		`admin/school/deleteTeacher?schoolId=${schoolId}&teacherId=${teacherId}`
	);
export const deleteCourse = (schoolId, courseId, type) =>
	Api.protected.delete(
		`admin/school/deleteCourse?schoolId=${schoolId}&courseId=${courseId}&type=${type}`
	);
export const deleteBc = (schoolId, BcId, type) =>
	Api.protected.delete(
		`admin/school/deleteBc?schoolId=${schoolId}&BcId=${BcId}&type=${type}`
	);

export const SchoolApi = {
	createSchool,
	addTeacher,
	addCourse,
	addBc,
	deleteTeacher,
	deleteCourse,
	deleteBc,
};
