import { addIndex, filter, map, pipe, split } from "ramda";
import { useLocation } from "@reach/router";

export const useParams = (template) => {
  const { pathname } = useLocation();
  const steps = pathname.split("/");
  return pipe(
    split("/"),
    addIndex(map)((step, i) =>
      step.startsWith(":") ? [i, step.slice(1)] : null
    ),
    filter((x) => x !== null),
    map(([i, step]) => [step, steps[i]]),
    Object.fromEntries
  )(template);
};
