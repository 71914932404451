export const getYearAndMonthOptions = () => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();

	const years = Array.from(
		{ length: currentYear - 2000 + 1 },
		(_, i) => 2000 + i
	);
	const months = Array.from({ length: 12 }, (_, i) =>
		new Date(0, i).toLocaleString("default", { month: "long" })
	);

	return { years, months, currentMonth, currentYear };
};
