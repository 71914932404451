import React from "react";
import { FileUploadEl } from "../elements";

function UploadButton({ inputRef }) {
  return (
    <FileUploadEl>
      <p>Upload Files</p>
      <div className="input" ref={inputRef}></div>
    </FileUploadEl>
  );
}

export default UploadButton;
