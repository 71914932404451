export const serviceWrapper = service => ({
  get: (url, config = {}) =>
    service.doRequest({
      url,
      ...config,
      method: "GET"
    }),
  post: (url, body, config = {}) =>
    service.doRequest({
      url,
      ...config,
      method: "POST",
      body
    }),
  put: (url, body, config = {}) =>
    service.doRequest({
      url,
      ...config,
      method: "PUT",
      body
    }),
  delete: (url, config = {}) =>
    service.doRequest({
      url,
      ...config,
      method: "DELETE"
    })
});
