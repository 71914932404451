import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { isRequired, string, url } from "../utils/form-errors";

export const getYupResolver = (type, key) => {
  switch (type) {
    case "url":
      return yupResolver(
        yup.object().shape({
          [key]: yup
            .string(string)
            .url(url)
            .required(isRequired`Url`),
        })
      );
    case "url-notReq":
      return yupResolver(
        yup.object().shape({
          [key]: yup.string(string).url(url),
        })
      );
    case "text":
      return yupResolver(
        yup.object().shape({
          [key]: yup.string(string),
        })
      );
    default:
      return null;
  }
};
