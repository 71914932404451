import React from "react";
import Accordion from "../../../../../components/accordian";
import CountryDiscountForm from "../../../../../components/forms/specificForms/countryDiscounts";
import { getCurrencyList } from "../../../../../helpers/dataParsers";
import { H4 } from "../../../../../styles/typography";
import { DiscountEl, DiscountsEl } from "../elements";
import BroadcastClassStore from "../store/broadcastClassStore";

function SeriesContent() {
  const {
    params,
    editSeries,
    curRequest: { id, priceMatrix },
  } = BroadcastClassStore.useContainer();
  return (
    <>
      <Accordion title={"Pricing"}>
        {params.filter === "upcoming" && (
          <CountryDiscountForm
            submitRequest={async (val) =>
              await editSeries({
                priceMatrix: { ...priceMatrix, ...val },
              })
            }
            items={getCurrencyList()}
            textLabel={"Price"}
            outputSchema={({ Value, Currency }) => ({
              [`${Currency}`]: Value,
            })}
          />
        )}
        <DiscountsEl>
          {Object.entries(priceMatrix).map(([key, val]) => (
            <DiscountEl key={`${id}-${key}`}>
              <H4>{key}</H4>
              <p>{val}</p>
            </DiscountEl>
          ))}
        </DiscountsEl>
      </Accordion>
    </>
  );
}

export default SeriesContent;
