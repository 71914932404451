import videoIcon from "../assets/icons/files/video.svg";
import audioIcon from "../assets/icons/files/audio.svg";
import attachmentIcon from "../assets/icons/files/attachment.svg";
import documentIcon from "../assets/icons/files/pdf.svg";

export const getFileIcon = (type = "", url) =>
  type.includes("video")
    ? videoIcon
    : type.includes("image")
    ? url
    : type.includes("audio")
    ? audioIcon
    : type.includes("attachment")
    ? attachmentIcon
    : documentIcon;
