import React from "react";
import { ContentEl } from "../../../elements";
import Accordion from "../../../../../components/accordian";
import { H6 } from "../../../../../styles/typography";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "../../../../../components/button";
import AddIcon from "@material-ui/icons/Add";
import ContentEditorStore from "../../../stores/content-editor-store";
import { useConfirm } from "material-ui-confirm";

const Content = ({
  index,
  data: {
    id = "",
    title = "",
    description = "",
    primaryVideo = "",
    content = [],
  } = {},
  path = "",
}) => {
  const { toggleEditor, deleteContent } = ContentEditorStore.useContainer();
  const confirm = useConfirm();

  return (
    <Accordion title={`${index + 1}. ${title}`}>
      <ContentEl>
        <H6>
          Actions:
          <div className="actions">
            <IconButton
              onClick={() =>
                confirm({
                  description: `Are you sure you want to delete ${title}?`,
                })
                  .then(() => deleteContent(`${path}${id}`))
                  .catch(() => {})
              }
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                toggleEditor({
                  path: `${path}${id}`,
                  content: {
                    id,
                    title,
                    description,
                    primaryVideo,
                  },
                });
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        </H6>
        {primaryVideo && <video src={primaryVideo} controls />}
        <p>{description}</p>
        <p>ID: {id}</p>
        <p>Path: {path}</p>

        <H6>Contents:</H6>
        <div className="child-content">
          {Object.values(content).map((content, index) => (
            <Content
              key={content.id}
              data={content}
              path={`${path}${id}/content/`}
              index={index}
            />
          ))}
        </div>
      </ContentEl>
      <div className="actions">
        <Button
          variant="default"
          startIcon={<AddIcon />}
          onClick={() => {
            toggleEditor({
              path: `${path}${id}/content/`,
              content: {},
            });
          }}
        >
          Add Content
        </Button>
      </div>
    </Accordion>
  );
};

export default Content;
