import { applySpec, maxBy, pathOr, pipe, reduce, values } from "ramda";
import { parseDoc } from "../../../../../helpers/dataParsers";

const User = applySpec({
  id: pathOr("", ["id"]),
  name: pathOr("", ["profile", "name"]),
  city: pathOr("NOT SET", ["profile", "city"]),
  cover: pipe(
    pathOr({}, ["profile", "profilePhoto"]),
    values,
    reduce(
      maxBy((a) => a.createdAt),
      { createdAt: 0 }
    ),
    parseDoc,
    pathOr("", ["url"])
  ),
  email: pathOr("", ["email"]),
  gender: pathOr("NOT SET", ["gender"]),
  roles: pathOr({}, ["roles"]),
  timezone: pathOr("NOT SET", ["timeZone"]),
  whatsappNumber: pathOr("NOT SET", ["whatsappNumber"]),
});

export default User;
