import React from "react";
import { ProgressEl } from "../elements";

function ProgressBar({ progress, uploading }) {
  return (
    <ProgressEl progress={progress} state={uploading ? "show" : "hide"}>
      Uploading...
      <div></div>
    </ProgressEl>
  );
}

export default ProgressBar;
