import { yupResolver } from "@hookform/resolvers";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { H5 } from "../../../styles/typography";
import { isRequired, string } from "../../../utils/form-errors";
import Button from "../../button";
import Input from "../../input";

function PollOptionEditForm({ data, mode, onSubmit, onCancel }) {
  const [processing, setProcessing] = useState(false);

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      name: data.name,
      description: data.description,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string(string).required(isRequired`Name`),
        description: yup.string(string),
      })
    ),
  });

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        setProcessing(true);
        await onSubmit(values);
      })}
    >
      <H5 bold>{mode === "create" ? "Add Poll Option" : "Edit Poll Option"}</H5>
      <Controller
        as={<Input />}
        control={control}
        name={"name"}
        label={"Name"}
        error={errors?.name}
      />
      <Controller
        as={<Input />}
        control={control}
        name={"description"}
        label={"Description"}
        error={errors?.description}
      />
      <Button type={"submit"} disabled={processing}>
        Submit
      </Button>
      <Button onClick={onCancel} disabled={processing}>
        Cancel
      </Button>
    </form>
  );
}

export default PollOptionEditForm;
