import React, { useMemo, useState } from "react";
import EndorsementForm from "./components/endorsement-form";
import Button from "../../../../../../components/button";
import TeacherStore from "../../store/teacherStore";
import Endorsement from "./components/endorsement";

const Endorsements = () => {
  const [showForm, toggleForm] = useState(false);

  const { curRequest } = TeacherStore.useContainer();

  const endorsements = useMemo(() => curRequest?.endorsements || [], [
    curRequest,
  ]);

  return (
    <div>
      <div className="endorsementList">
        {endorsements.map((item, index) => (
          <Endorsement data={item} key={item.link} index={index} />
        ))}
      </div>
      <Button onClick={() => toggleForm(true)}>Show Form</Button>
      {showForm && <EndorsementForm close={() => toggleForm(false)} />}
    </div>
  );
};

export default Endorsements;
