import styled from "styled-components";
import { greyBorder } from "../../../../../styles/colors";
import { hFlex, modalBox, vFlex } from "../../../../../styles/style-helpers";
import { H4, H5, S05 } from "../../../../../styles/typography";

export const BatchEl = styled.div`
  border: 1px solid ${greyBorder};
  border-radius: 1em;
  padding: 1em;
  position: relative;
  margin: 1em 0;

  > ${H5} {
    margin: 0.5em 0;
  }

  .edit-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .batch-edit {
    ${vFlex}
    width: 100%;
    align-items: baseline;

    ${H4} {
      width: 100%;
      text-align: center;
    }

    > * {
      width: 100%;
    }

    ${H5} {
      margin: 1em 0 0.5em;
    }
  }
`;

export const EditBatchFormEl = styled.form`
  width: 100%;

  .section-heading {
    margin: 1em 0 0.5em;
  }

  .schedule {
    ${hFlex}
    border: 1px solid ${greyBorder};
    margin: 0.5em 0;
    border-radius: 0.5em;

    ${S05} {
      padding-left: 0.5em;
      width: 100%;
    }
  }

  .add-slot-btn {
    width: 100%;
    ${hFlex}
    justify-content: flex-end;
  }

  .error {
    color: red;
    font-size: 0.8em;
    margin: 0.2em;
  }

  .MuiFormHelperText-root {
    display: none;
  }

  .row {
    ${hFlex}

    > * {
      width: 100%;
      margin: 0.5em;
    }
  }

  .action-btns {
    width: 100%;
    padding: 0.5em 0;
    ${hFlex}
    justify-content: space-between;
  }
`;

export const AddSlotModalEl = styled.div`
  ${modalBox}
  ${vFlex}
  overflow: visible;

  > * {
    width: 100%;
    margin: 0.5em 0 !important;
  }

  button {
    width: auto;
  }
`;

export const AllSlotsEl = styled.div`
  ${modalBox}
  ${vFlex}
  justify-content: flex-start;

  .preview-slot {
    width: 100%;
    margin: 0.25em 0;
  }
`;
