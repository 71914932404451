import styled from "styled-components";
import {
  background,
  center,
  flex,
  hFlex,
  square,
  vFlex,
} from "../../../../../styles/style-helpers";

export const ExperienceEl = styled.div`
  ${flex};
  justify-content: flex-start;

  > .icon {
    ${() => square("3vw")}
    ${(props) => background(props.image, "cover")}
  }

  a {
    margin-left: 1em;
    color: inherit;
    font-size: 0.8em;

    :hover {
      font-weight: 600;
    }
  }
`;

export const ProfileInfoEl = styled.div`
  ${vFlex};
  flex: 1 1;
  align-items: flex-start;
  justify-content: space-around;

  p {
    color: #4a4a4a;
    margin: 0.3em 0;
  }
`;

export const ProfilePicEl = styled.div`
  ${() => square("14vw")}
  ${(props) => background(props.image, "cover")}
  margin-right: 2em;
  border-radius: 10px;
`;

export const TeacherInfoEl = styled.div`
  ${hFlex};
  align-items: unset;
  padding: 1em 0;
`;

export const AvailabilityEl = styled.div`
  ${vFlex};
  align-items: flex-start;
`;

export const LobbyAssetsEl = styled.div`
  width: 100%;
  position: relative;

  h5 {
    width: 100%;

    button {
      float: right;
      margin-left: 1em;
    }
  }

  .files-list {
    width: 90%;
  }
`;

export const EndorsementFormEl = styled.form`
  width: 50%;
  height: 50%;
  background: white;
  ${center};
  border-radius: 1em;
  padding: 2em;
  ${vFlex};
  align-items: flex-start;
  overflow: auto;

  .row {
    width: 100%;
    ${hFlex};
  }

  .content {
    width: 100%;

    .content-info {
      width: 100%;

      .MuiTextField-root {
        margin-bottom: 1em;
      }
    }
  }

  .image-input {
    ${hFlex};
    justify-content: flex-start;

    .uploaded-img {
      ${hFlex}
      img {
        width: 5em;
        margin-right: 1em;
      }
    }

    > button {
      margin-left: 1.5em;
    }
  }

  .position-input {
    width: 45%;
    margin: 0.5em 0;
  }

  .content {
    ${hFlex};
    justify-content: space-between;

    > div {
      width: 45%;
    }

    .content-button {
      > div {
        margin-bottom: 0.5em;
      }
    }
  }

  .actions {
    width: 100%;
    ${hFlex};
    justify-content: space-around;
    margin-top: 1em;

    button {
      width: 12.5em;
    }
  }

  .error {
    color: red;
    font-size: 0.75em;
  }
`;

export const EndorsementEl = styled.div`
  width: 100%;
  margin: 1em 0;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
  padding: 0.5em;
  border-radius: 0.5em;
  ${hFlex};

  img {
    ${square("7.5em")};
    object-fit: contain;
    margin-right: 1em;
  }

  .info {
    flex: 1;
  }
`;
