import React, { useState } from "react";
import Button from "../../../../../../../components/button";
import { H2, H5 } from "../../../../../../../styles/typography";
import { BannerEl } from "../elements";
import CreateIcon from "@material-ui/icons/Create";
import BannerEditForm from "./bannerEditForm";
import ClassBannerStore from "../store/classBannerStore";

function Banner({ data }) {
  const [editing, setEditing] = useState(false);
  const { editBanner } = ClassBannerStore.useContainer();
  const submit = async (values) => {
    await editBanner({ bannerId: data.id, ...values });
    setEditing(false);
  };

  return (
    <BannerEl style={data} editing={editing}>
      {editing ? (
        <BannerEditForm
          data={data}
          submit={submit}
          cancel={() => setEditing(false)}
          secondaryAction={async () => await submit({ deleted: !data.deleted })}
          mode={data.deleted ? "edit_restore" : "edit_delete"}
        />
      ) : (
        <>
          <img className="background" src={data.image} alt="background" />
          <div className="edit-icon" onClick={() => setEditing(true)}>
            {data.deleted && "DELETED"}
            <CreateIcon />{" "}
          </div>
          <div className="info">
            <H2>
              {data.heading.map((h, i) => (
                <span key={`heading-${i}`}>{h}</span>
              ))}
            </H2>
            <H5>
              {data.para.map((p, i) => (
                <span key={`para-${i}`}>
                  {p} <br />
                </span>
              ))}
            </H5>
            {data.cta.name && (
              <div>
                {data.cta?.external ? (
                  <Button type="primary" onClick={() => {}}>
                    {data.cta.name}
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => {}}>
                    {data.cta.name}
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </BannerEl>
  );
}

export default Banner;
