import React from 'react'

const ImageViewer = ({ url, name }) => (
  <div className='imageViewer'>
    <div className='imageContainer'>
      <img src={url} alt={name} />
    </div>
  </div>
)

export default ImageViewer
