import { yupResolver } from "@hookform/resolvers";
import { IconButton } from "@material-ui/core";
import React, { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "react-select";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import { batchDurationOptions } from "../../config";
import DeleteIcon from "@material-ui/icons/Delete";
import { getNewSlots, getSchedule, getScheduleStart } from "../../helpers";
import { useList, useUpdateEffect } from "react-use";
import {
  createSelectOptions,
  getTimeZone,
} from "../../../../../../helpers/misc";
import AddSlot from "./addSlot";
import * as yup from "yup";
import { isRequired } from "../../../../../../utils/form-errors";
import CourseStore from "../../store/courseStore";
import { EditBatchFormEl } from "../../elements/batches";
import { S05, Text } from "../../../../../../styles/typography";

function EditBatchForm({
  data: { duration: durationMS, slots },
  onSubmit,
  onCancel,
}) {
  const [schedule, { removeAt, push }] = useList(getScheduleStart(slots));
  const duration = durationMS / (1000 * 60 * 60);
  const {
    curRequest: { nClasses },
  } = CourseStore.useContainer();

  const { handleSubmit, control, errors, setValue } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        duration: yup
          .mixed()
          .test(
            "nScheduletest",
            "No of slots is greater than no of classes",
            () => nClasses >= schedule.length
          )
          .test(
            "noSlotsCheck",
            "Pick atleast one slot",
            () => schedule.length > 0
          ),
        slot: yup.mixed().required(isRequired`Slot`),
        startDate: yup.mixed().required(isRequired`Starting Date`),
      })
    ),
    defaultValues: {
      duration: createSelectOptions(duration, `${duration} hour`),
      slot: createSelectOptions(
        slots[0].startTime,
        getSchedule(slots[0].startTime, duration)
      ),
      startDate: new Date(slots[0].startTime),
    },
  });

  const batchDuration = useWatch({
    control,
    name: "duration",
    defaultValue: { value: duration },
  });

  const selectedSlot = useWatch({
    control,
    name: "slot",
    defaultValue: createSelectOptions(
      slots[0].startTime,
      getSchedule(slots[0].startTime, duration)
    ),
  });

  useUpdateEffect(() => {
    setValue("startDate", null);
  }, [selectedSlot, setValue]);

  useEffect(() => {
    if (!schedule.includes(selectedSlot?.value)) setValue("slot", null);
    // eslint-disable-next-line
  }, [schedule, setValue]);

  return (
    <EditBatchFormEl
      onSubmit={handleSubmit((values) => {
        onSubmit(
          getNewSlots(
            schedule,
            values.duration.value,
            values.slot.value,
            values.startDate.setHours(0, 0, 0, 0),
            nClasses
          )
        );
      })}
    >
      <Controller
        control={control}
        as={
          <Select
            isClearable={false}
            value={createSelectOptions(duration, `${duration} hour`)}
            options={batchDurationOptions}
          />
        }
        name="duration"
        placeholder="Select Duration"
      />
      <Text className="section-heading" bold>
        Slots:
      </Text>
      {schedule.map((timing, i) => (
        <div key={timing} className="schedule">
          <S05>
            {getSchedule(timing, batchDuration.value)} {getTimeZone()}
          </S05>
          <IconButton onClick={() => removeAt(i)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      {nClasses > schedule.length && (
        <div className="add-slot-btn">
          <AddSlot
            schedule={schedule}
            insert={push}
            duration={batchDuration.value}
          />
        </div>
      )}
      <Text className="section-heading" bold>
        Select Batch Starting:
      </Text>
      <div className="row">
        <Controller
          as={
            <Select
              options={schedule.map((timing) =>
                createSelectOptions(
                  timing,
                  getSchedule(timing, batchDuration.value)
                )
              )}
              value={selectedSlot}
            />
          }
          control={control}
          name="slot"
          placeholder="Select Starting Slot"
        />
        {errors?.slot && <p className="error">{errors?.slot?.message}</p>}
        <Controller
          as={
            <Input
              type="datepicker"
              shouldDisableDate={(date) =>
                date.getDay() !== new Date(selectedSlot.value).getDay()
              }
              disablePast={true}
              disabled={!selectedSlot}
              value={new Date(slots[0].startTime)}
            />
          }
          control={control}
          name="startDate"
          label="Start Date"
        />
      </div>
      {errors?.startDate && (
        <p className="error">{errors?.startDate?.message}</p>
      )}
      {errors?.duration && <p className="error">{errors?.duration?.message}</p>}
      <div className="action-btns">
        <Button type="submit">Submit</Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
          withconfirmation
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    </EditBatchFormEl>
  );
}

export default EditBatchForm;
