import React, { useState } from "react";
import { createFilter } from "react-search-input/lib/util";
// import Button from "../../../../../../components/button";
import Loading from "../../../../../components/loading";
import Search from "../../../../../components/search";
import Table from "../../../../../components/table";
import { Text } from "../../../../../styles/typography";
import { withStore } from "../../../../../utils/withStore";
import { getYearAndMonthOptions, recordingCellModifier } from "../helpers";
import RecordingStore from "../stores/recordingStore";
import { RecordingsEl } from "../elements/recordings";
import Button from "../../../../../components/button";

const recordingTableHeaders = [
	{ id: "id", label: "ID", width: 80 },
	{ id: "name", label: "User" },
	{ id: "userId", label: "User-ID", width: 80 },
	{ id: "email", label: "Email" },
	{ id: "classLink", label: "Class" },
	{ id: "updatedAt", label: "Purchase Date" },
	{ id: "price", label: "Amount" },
	{ id: "paymentGateway", label: "Gateway" },
	{ id: "status", label: "Status" },
];

const KEYS_TO_FILTER = ["id", "userId", "classLink", "name", "status", "price"];

function Recordings() {
	const [query, setQuery] = useState("");
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

	const {
		loading,
		loadFailed,
		recordingData: { earnings = {}, bills = [] } = {},
	} = RecordingStore.useContainer();

	const { years, months } = getYearAndMonthOptions();

	// Filter data by search query, year, and month
	const recordingBills = bills
		.filter(createFilter(query, KEYS_TO_FILTER))
		.filter(({ updatedAt }) => {
			const date = new Date(updatedAt);
			return (
				date.getFullYear() === selectedYear &&
				(selectedMonth === -1 || date.getMonth() === selectedMonth)
			);
		});
	const currentEarnings = recordingBills.reduce((acc, payment) => {
		if (payment.status === "PAID") {
			const { amount, currency } = payment;
			acc[currency] = (acc[currency] || 0) + amount;
			return acc;
		} else {
			return acc;
		}
	}, {});
	const paidCount = recordingBills.filter(
		({ status }) => status === "PAID"
	).length;
	const unPaidCount = recordingBills.length - paidCount;
	const jsonToCsv = (json) => {
		delete json["amount"];
		delete json["pricing"];
		const headers = Object.keys(json[0]);
		const csvRows = [headers.join(",")];

		json.forEach((row) => {
			const values = headers.map((header) => `"${row[header] || ""}"`);
			csvRows.push(values.join(","));
		});

		return csvRows.join("\n");
	};

	// Trigger the CSV download
	const downloadCSV = () => {
		if (!recordingBills || recordingBills.length === 0) {
			alert("No data to download!");
			return;
		}

		const csvData = jsonToCsv(recordingBills);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = `recordings.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	return (
		<Loading loading={loading} loadFailed={loadFailed}>
			<RecordingsEl>
				<div className="filter-container">
					<Search placeholder="Search" onChange={setQuery} />
					<div className="month-filter">
						<select
							value={selectedYear}
							onChange={(e) => setSelectedYear(Number(e.target.value))}
						>
							{years.map((year) => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
						</select>
						<select
							value={selectedMonth}
							onChange={(e) => setSelectedMonth(Number(e.target.value))}
						>
							<option value={-1}>All Months</option>
							{months.map((month, index) => (
								<option key={index} value={index}>
									{month}
								</option>
							))}
						</select>
						<Button onClick={downloadCSV}>Download Csv</Button>
					</div>
				</div>
				<Text className="earnings-info">
					<b>Total Earnings:</b>{" "}
					{Object.entries(earnings)
						.map(([curr, amount]) => `${curr} ${amount}`)
						.join(", ")}
				</Text>
				<Text className="earnings-info">
					<b>Selected Earnings:</b>{" "}
					{Object.entries(currentEarnings)
						.map(([curr, amount]) => `${curr} ${amount}`)
						.join(", ")}
				</Text>
				<Text className="earnings-info">
					<b>Paid:</b> {paidCount}
					<b className="not-paid">Not Paid:</b> {unPaidCount}
				</Text>
				<div className="info">
					{/* <Button onClick={() => {}}>Download</Button> */}
					<Table
						data={recordingBills}
						headers={recordingTableHeaders}
						rowCellModifier={recordingCellModifier}
						stickyHeader
						defOrder="desc"
						defOrderBy="updatedAt"
					/>
				</div>
			</RecordingsEl>
		</Loading>
	);
}

export default withStore([RecordingStore])(Recordings);
