import React from "react";
import { TeacherInfoEl, ProfilePicEl, ProfileInfoEl } from "../elements";
import { EmailModalEl, CloseButtonEl } from "../../../elements";
import { H3 } from "../../../../../styles/typography";
import Modal from "../../../../../components/modal";
import { sendTeacherFollowerEmails } from "../../../../../api/teacher";
import EmailToFollowers from "../../../../../components/forms/specificForms/emailToFollowers";
import ClickToCopy from "../../../../../modules/clickToCopy";
import { Notification } from "../../../../../modules/toastNotification";

function TeacherInfo({ request }) {
  const { profile: teacher, id } = request;

  return (
    <TeacherInfoEl>
      <ProfilePicEl image={teacher.profilePhoto} />
      <ProfileInfoEl>
        <H3>{teacher.name}</H3>
        <p>
          Email: <ClickToCopy data={teacher.email}>{teacher.email}</ClickToCopy>
        </p>
        <p>Location: {teacher.location}</p>
        <p>Gender: {teacher.gender}</p>
        <p>Phone: {teacher.phone}</p>
        <p>
          <ClickToCopy data={id}>ID: {id}</ClickToCopy>
        </p>
        <p>
          LivdemyId:{" "}
          <a
            href={`https://livdemy.com/${teacher.livdemyId}`}
            target="_blank"
            rel="noopener noreferrer"
          >{`https://livdemy.com/${teacher.livdemyId}`}</a>
        </p>
        <Modal
          title="Send Email To Followers"
          content={({ modalOpen }) => (
            <EmailModalEl>
              <CloseButtonEl onClick={() => modalOpen(false)}>X</CloseButtonEl>
              <EmailToFollowers
                submitRequest={async (data) => {
                  const { success } = await sendTeacherFollowerEmails({
                    teacherId: request.id,
                    ...data,
                  });
                  if (success) {
                    Notification("success", "Email Sent Successfully.");
                    modalOpen(false);
                  }
                }}
              />
            </EmailModalEl>
          )}
        />
      </ProfileInfoEl>
    </TeacherInfoEl>
  );
}

export default TeacherInfo;
