import { Api } from "./Configuration";

export const createPoll = (type, data) =>
  Api.protected.post(`admin/${type}/poll`, data);

export const createPollOption = (data) =>
  Api.protected.post(`admin/poll-option`, data);

export const EditPoll = (pollId, data) =>
  Api.protected.put(`admin/poll/${pollId}`, data);

export const getPollData = (pollId) =>
  Api.protected.get(`admin/poll/${pollId}`);

export const deleteOption = (pollOptionId) =>
  Api.protected.delete(`admin/poll-option/${pollOptionId}`);

export const editOption = (pollOptionId, data) =>
  Api.protected.put(`admin/poll-option/${pollOptionId}`, data);
