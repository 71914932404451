import React from "react";
import Accordion from "../../../../../components/accordian";
import { AvailabilityEl, ExperienceEl } from "../elements";
import Form from "../../../../../components/forms";
import CoverImages from "./coverImages";
import Description from "./description";
import LobbyAssets from "./lobbyAssets";
import Polls from "../../../../../components/polls";
import Endorsements from "./endorsements";

function TeacherContent({ request, updateCoverVideo }) {
  return (
    <>
      <Accordion title="About the Teacher">
        <Description />
      </Accordion>
      <Accordion title="Teacher Cover Video">
        <Form
          formType="url-notReq"
          name={`${request.id}-covervideo`}
          defaultVal={request.profileVideo}
          submitRequest={async (val) => await updateCoverVideo(val)}
          label="Cover Video"
          editToggle={true}
          displayType="link"
        />
      </Accordion>
      <Accordion title="Cover Images">
        <CoverImages />
      </Accordion>
      <Accordion title="Availability">
        <AvailabilityEl>
          {request.availability.map((schedule, i) => (
            <span key={`${request.id}-schedule-${i}`}>{schedule}</span>
          ))}
        </AvailabilityEl>
      </Accordion>
      <Accordion title="Experience">
        {Object.keys(request.experience).map((doc, i) => {
          return (
            <ExperienceEl
              image={request.experience[doc].location}
              key={`experience-${i}`}
            >
              <div className="icon" />
              <a
                href={request.experience[doc].location}
                target="_blank"
                rel="noopener noreferrer"
              >
                {request.experience[doc].fileName}
              </a>
            </ExperienceEl>
          );
        })}
      </Accordion>
      <Accordion title="Poll" TransitionProps={{ unmountOnExit: true }}>
        <Polls
          type={"teacher"}
          id={request.profile.livdemyId}
          entityId={request.id}
        />
      </Accordion>
      <Accordion title="Lobby Assets">
        <LobbyAssets />
      </Accordion>
      <Accordion title="Endorsements">
        <Endorsements />
      </Accordion>
    </>
  );
}

export default TeacherContent;
