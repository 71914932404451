import React from "react";
import ClickToCopy from "../../../../../modules/clickToCopy";
import date from "date-and-time";

export const recordingCellModifier = (data, header) => {
	return header === "classLink" ? (
		<a
			href={`${process.env.REACT_APP_BASE_URL}/${data["classLink"]}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{data[header]}
		</a>
	) : header === "updatedAt" ? (
		<ClickToCopy data={String(data[header])}>
			{date.format(new Date(data[header]), "DD MMM YYYY hh:mmA")}
		</ClickToCopy>
	) : (
		<ClickToCopy data={String(data[header])}>{data[header]}</ClickToCopy>
	);
};

export const getYearAndMonthOptions = () => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();

	const years = Array.from(
		{ length: currentYear - 2000 + 1 },
		(_, i) => 2000 + i
	);
	const months = Array.from({ length: 12 }, (_, i) =>
		new Date(0, i).toLocaleString("default", { month: "long" })
	);

	return { years, months, currentMonth, currentYear };
};
