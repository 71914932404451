import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getFileIcon } from "../../../../helpers/files";
import { FileEl } from "../../elements";
import ReorderIcon from "@material-ui/icons/Reorder";

function File({ data: { name, ...data }, index, moveFile }) {
  const fileRef = useRef(null);

  const [, drop] = useDrop({
    accept: "files",
    hover: (item, monitor) => {
      if (!fileRef.current) return;

      const dragInd = item.index;
      const hoverInd = index;

      if (dragInd === hoverInd) return;

      const hoverBoundingBox = fileRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingBox.bottom - hoverBoundingBox.top) / 2;
      const mousePos = monitor.getClientOffset();
      const mouseTopPos = mousePos.y - hoverBoundingBox.top;

      if (
        (dragInd < hoverInd && mouseTopPos < hoverMiddleY) ||
        (dragInd > hoverInd && mouseTopPos > hoverMiddleY)
      )
        return;

      moveFile(dragInd, hoverInd);

      item.index = hoverInd;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, name, type: "files" },
    type: "files",
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(fileRef));

  return (
    <FileEl
      ref={fileRef}
      draggable={true}
      isDragging={isDragging}
      image={getFileIcon(data?.type, data.url)}
    >
      <ReorderIcon className="dragIcon" />
      <div className="icon" />
      <p>{name}</p>
    </FileEl>
  );
}

export default File;
