import styled from "styled-components";
import { close } from "../../../assets/icons/documentViewer";
import {
  background,
  hFlex,
  overlay,
  square,
  vFlex,
  center,
} from "../../../styles/style-helpers";
import { accent, darkAccent } from "../../../styles/colors";
import { fadeIn } from "../../../styles/keyframes";

export const DocumentsViewerEl = styled.div`
  ${overlay};
  position: fixed;
  pointer-events: none;
  z-index: 999999999;

  .react-draggable {
    transform: translate(-50%, -50%);
    pointer-events: auto;
  }
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    background: black;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.5);
    flex: 1;
  }

  .topBar {
    height: 2em;
    width: 100%;
    background: white;
    ${hFlex};
    padding: 0 1em;
    cursor: move;
    font-size: 0.8em;

    p {
      flex: 1;
    }

    .close {
      ${square("1.25em")};
      ${background(close)};
      cursor: pointer;
    }
  }

  .media {
    height: calc(100% - 2em);
    overflow: hidden;

    .imageViewer {
      height: 100%;
      width: 100%;
      ${vFlex};

      .imageContainer {
        flex: 1;
        width: 100%;
        ${vFlex};

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      .imageName {
        color: white;
        margin-top: 0.5em;
        font-size: 1.25em;
      }
    }

    .protectedVideo > div {
      height: 100%;
      width: 100%;
    }

    .videoPlayer,
    .protectedVideo {
      height: 100%;
      width: 100%;
      ${hFlex};

      .playlist {
        background: white;
        height: 100%;
        width: 10em;
        overflow: auto;

        h1 {
          font-size: 1.25em;
          border-bottom: 1px solid black;
          width: 100%;
          padding: 0.5em;
        }

        p {
          padding: 0.5em 1.25em;
          font-size: 1.125em;
          font-weight: 500;
          cursor: pointer;

          &.active {
            color: ${accent};
          }

          &:hover {
            color: ${darkAccent};
          }
        }
      }

      .videoName {
        color: white;
        margin-top: 0.5em;
        font-size: 1.25em;
      }
    }

    .audioPlayer {
      width: 100%;
      ${vFlex};

      .audioName {
        color: white;
        margin-top: 0.5em;
        font-size: 1.25em;
      }
    }

    .iframeViewer {
      height: 100%;
      width: 100%;
      position: relative;
      .placeholderMessage {
        ${center};
        color: white;
        text-align: center;
        width: 30em;
        font-size: 0.8em;
        line-height: 1.5;
        opacity: 0;
        animation: ${fadeIn} 500ms ease;
        animation-fill-mode: forwards;
        animation-delay: 3000ms;

        a {
          font-size: inherit;
          color: inherit;
          opacity: 1;
          display: inline;
          border-bottom: 1px solid white;
        }
      }

      iframe {
        width: 100%;
        height: 100%;
        flex: 1;
        position: relative;

        &.withPadding {
          height: calc(100% - 2em);
        }
      }

      .fileName {
        color: white;
        margin-top: 0.5em;
        font-size: 1.25em;
      }

      .openInNewTab {
        color: white;
        text-decoration: none;
        font-size: 0.825em;
        opacity: 1;
        height: 1.5rem;
        ${hFlex};
        justify-content: center;

        span {
          ${square("1.5em")};
          filter: invert(1);
          margin-right: 1em;
          ${background(
            "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDk2IDk2IiBoZWlnaHQ9Ijk2cHgiIGlkPSJvcGVuIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA5NiA5NiIgd2lkdGg9Ijk2cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik04OCw0OGMtMi4yMDksMC00LDEuNzkxLTQsNHYyOGMwLDIuMjEtMS43OSw0LTQsNEgxNmMtMi4yMSwwLTQtMS43OS00LTRWMTZjMC0yLjIxLDEuNzktNCw0LTRoMjhjMi4yMDksMCw0LTEuNzkxLDQtNCAgcy0xLjc5MS00LTQtNEgxNkM5LjM3Myw0LDQsOS4zNzMsNCwxNnY2NGMwLDYuNjI3LDUuMzczLDEyLDEyLDEyaDY0YzYuNjI3LDAsMTItNS4zNzMsMTItMTJWNTJDOTIsNDkuNzkxLDkwLjIwOSw0OCw4OCw0OHoiLz48cGF0aCBkPSJNOTEuOTk2LDcuOTU4Yy0wLjAwMy0wLjI0OS0wLjAyNi0wLjQ5OC0wLjA3NS0wLjc0MkM5MS44OTgsNy4xMDQsOTEuODU2LDcsOTEuODI0LDYuODkxICBjLTAuMDQtMC4xNDItMC4wNzQtMC4yODUtMC4xMzEtMC40MjNjLTAuMDUtMC4xMjItMC4xMi0wLjIzMS0wLjE4Mi0wLjM0NmMtMC4wNjItMC4xMTUtMC4xMTMtMC4yMzQtMC4xODctMC4zNDQgIGMtMC4xMi0wLjE3OS0wLjI2MS0wLjM0Mi0wLjQwNy0wLjQ5OWMtMC4wMzItMC4wMzUtMC4wNTUtMC4wNzUtMC4wODktMC4xMDhjLTAuMDMxLTAuMDMxLTAuMDY3LTAuMDUxLTAuMS0wLjA4MSAgYy0wLjE1OS0wLjE1LTAuMzI2LTAuMjkzLTAuNTA5LTAuNDE2Yy0wLjEwOC0wLjA3Mi0wLjIyNi0wLjEyMy0wLjMzOS0wLjE4NGMtMC4xMTYtMC4wNjItMC4yMjgtMC4xMzMtMC4zNS0wLjE4NCAgYy0wLjEzOS0wLjA1OC0wLjI4My0wLjA5MS0wLjQyNy0wLjEzMmMtMC4xMDctMC4wMzEtMC4yMS0wLjA3My0wLjMyMS0wLjA5NUM4OC41MjUsNC4wMjcsODguMjYyLDQsODcuOTk5LDRINjQgIGMtMi4yMDksMC00LDEuNzkxLTQsNHMxLjc5MSw0LDQsNGgxNC4zNDNsLTMyLjc3LDMyLjc3Yy0xLjU2MiwxLjU2Mi0xLjU2Miw0LjA5NSwwLDUuNjU2YzEuNTYyLDEuNTYyLDQuMDk1LDEuNTYyLDUuNjU2LDAgIEw4NCwxNy42NTZWMzJjMCwyLjIwOSwxLjc5MSw0LDQsNHM0LTEuNzkxLDQtNFY4QzkyLDcuOTg2LDkxLjk5Niw3Ljk3Myw5MS45OTYsNy45NTh6Ii8+PC9zdmc+"
          )};
          display: inline-block;
        }
      }
    }
  }
`;
