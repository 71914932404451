import React from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = ({ url }) => (
  <div className='videoPlayer' onClick={(e) => e.stopPropagation()}>
    <ReactPlayer
      url={url}
      playing
      controls
      style={{ flex: 1 }}
      width={'100%'}
    />
  </div>
)

export default VideoPlayer
