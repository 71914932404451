import React from "react";
import { default as SwitchComponent } from "@material-ui/core/Switch";
import styled from "styled-components";

const Switch = styled(SwitchComponent)``;

function Toggle({ value, onChange, ...rest }) {
  return (
    <Switch
      checked={value}
      onChange={() => (onChange ? onChange(!value) : console.log("Toggled!!!"))}
      {...rest}
    />
  );
}

export default Toggle;
