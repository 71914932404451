import React from "react";
import DocumentsViewerStore from "./store/documentsViewerStore";
import { Rnd } from "react-rnd";
import { Portal } from "react-portal";
import ImageViewer from "./components/imageViewer";
import Animated from "react-animated-transitions";
import "animate.css";
import VideoPlayer from "./components/videoPlayer";
import AudioPlayer from "./components/audioPlayer";
import IframeViewer from "./components/iframeViewer";
import VideoListPlayer from "./components/videoListPlayer";
import ProtectedVideo from "./components/protectedVideo";
import { useKey } from "react-use";
import { DocumentsViewerEl } from "./elements";

const DocumentsViewer = () => {
  const { doc, closeDocument } = DocumentsViewerStore.useContainer();

  useKey("Escape", () => closeDocument());

  return doc ? (
    <Animated enter="fadeIn" exit="fadeOut" timeout={300}>
      <Portal node={doc.node}>
        <DocumentsViewerEl>
          <div className="dragWrapper" onClick={(e) => e.stopPropagation()}>
            <Rnd
              dragHandleClassName="topBar"
              default={{
                x: window.innerWidth * 0.2,
                y: window.innerHeight * 0.2,
                width: window.innerWidth * 0.6,
                height: window.innerHeight * 0.6,
              }}
              bounds={".documentsViewer"}
            >
              <div className="container">
                <div className="topBar">
                  <p className="name">{doc.name}</p>
                  <span className="close" onClick={() => closeDocument()} />
                </div>
                <div className="media">
                  {doc.type.includes("image") ? (
                    <ImageViewer url={doc.url} name={doc.name} />
                  ) : doc.type.includes("protected-video") ? (
                    <ProtectedVideo
                      url={doc.url}
                      name={doc.name}
                      otp={doc.otp}
                      playbackInfo={doc.playbackInfo}
                    />
                  ) : doc.type.includes("video") ? (
                    <VideoPlayer url={doc.url} name={doc.name} />
                  ) : doc.type.includes("playlist") ? (
                    <VideoListPlayer url={doc.url} name={doc.name} />
                  ) : doc.type.includes("audio") ? (
                    <AudioPlayer url={doc.url} name={doc.name} />
                  ) : (
                    <IframeViewer
                      url={doc.url}
                      name={doc.name}
                      type={doc.type}
                    />
                  )}
                </div>
              </div>
            </Rnd>
          </div>
        </DocumentsViewerEl>
      </Portal>
    </Animated>
  ) : (
    <div />
  );
};

export default DocumentsViewer;
