import React from "react";
import Accordion from "../../../../../components/accordian";
import CourseStore from "../store/courseStore";
import WhitelistCourseForm from "../../../../../components/forms/specificForms/whitelistCourse";

function Whitelist() {
	const {
		curRequest: { id },
		WhiteListCourse,
	} = CourseStore.useContainer();
	return (
		<Accordion title="Whitelisted Emails">
			<WhitelistCourseForm
				submitRequest={async (email, courseType) =>
					await WhiteListCourse(id, email, courseType)
				}
				items={[
					{ name: "STANDARD", value: "STANDARD" },
					{ name: "CERTIFICATION", value: "CERTIFICATION" },
					{ name: "UPGRADE", value: "UPGRADE" },
				]}
				textLabel={"Email"}
			/>
		</Accordion>
	);
}

export default Whitelist;
