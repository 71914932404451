import React from "react";
import PrivateRoute from "../../../../utils/privateRoute";
import { ActiveSecContentEl, ActiveSecEl } from "../../elements";
import SearchSidebar from "../common/searchSidebar";
import InvitationStore from "./store/invitationStore";
import { withStore } from "../../../../utils/withStore";
import { Router } from "@reach/router";
import InactiveSection from "../requestView/components/inactiveSection";
import ViewTemplate from "./components/viewTemplate";
import { InvitationSecEl } from "./elements";

function Invitations() {
  const { sidebarTabs } = InvitationStore.useContainer();
  return (
    <ActiveSecEl>
      <ActiveSecContentEl>
        <SearchSidebar tabs={sidebarTabs} />
        <Router component={InvitationSecEl}>
          <InactiveSection path="/" />
          <ViewTemplate path=":requestId" />
        </Router>
      </ActiveSecContentEl>
    </ActiveSecEl>
  );
}

export default withStore([InvitationStore])(PrivateRoute(Invitations));
