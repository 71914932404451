import React from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";

//Make default component for not logged in
const PrivateRoute = (WrappedComponent) => ({
  permission,
  action,
  ...rest
}) => {
  if (permission === "REDIRECT") {
    action();
    navigate("/login", { replace: true });
  }

  return permission === "SHOW" ? <WrappedComponent {...rest} /> : null;
};

export default PrivateRoute;

PrivateRoute.defaultProps = {
  action: null,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  action: PropTypes.func,
  permission: PropTypes.string,
};
