import React, { useEffect, useState } from "react";
import { IconButton, Modal as MaterialModal } from "@material-ui/core";
import { EditorEl } from "../../elements";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { isRequired, string } from "../../../../utils/form-errors";
import { H5, H6 } from "../../../../styles/typography";
import { FileUploadModalEl } from "../../../../app/dashboard/elements";
import FileUploader from "../../../fileUploader";
import Modal from "../../../../components/modal";
import Input from "../../../../components/input";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../../../components/button";
import ContentEditorStore from "../../stores/content-editor-store";

const Editor = ({ path, content, close }) => {
  const { createContent, editContent } = ContentEditorStore.useContainer();
  const [submitting, setSubmitting] = useState(false);
  const [primaryVideo, setPrimaryVideo] = useState(content.primaryVideo);

  const { handleSubmit, errors, setValue, control } = useForm({
    defaultValues: {
      title: content.title || "",
      description: content.description || "",
      primaryVideo: content.primaryVideo || "",
    },
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string(string).required(isRequired`Title`),
        description: yup.string(string),
        primaryVideo: yup.string(string),
      })
    ),
  });

  useEffect(() => {
    if (primaryVideo)
      setValue("primaryVideo", primaryVideo, { shouldValidate: true });
  }, [primaryVideo, setValue]);

  return (
    <MaterialModal open>
      <EditorEl>
        <H5>
          {content?.id ? `Edit Content` : `Create Content`}{" "}
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </H5>
        <form
          onSubmit={handleSubmit(async (values) => {
            setSubmitting(true);
            console.log({ values });
            await (!content?.id ? createContent : editContent)(path, values);
            setSubmitting(false);
            // await submit(data);
          })}
        >
          <Controller
            as={<Input type="text" />}
            control={control}
            name={"title"}
            error={errors.title}
            label="Title"
            rowsMin={2}
          />
          {errors?.title && <p className="error">{errors.title?.message}</p>}
          <Controller
            as={<Input type="text" multiline rows={5} />}
            control={control}
            name={"description"}
            error={errors.description}
            label="Description"
            rowsMin={2}
          />
          {errors?.description && (
            <p className="error">{errors.description?.message}</p>
          )}
          <div className="video">
            <Controller
              as={<input />}
              control={control}
              name={"primaryVideo"}
              hidden
            />
            <H6>Primary Video:</H6>
            {errors?.primaryVideo && (
              <p className="error">{errors.primaryVideo.message}</p>
            )}
            <div className="video-input">
              {primaryVideo ? (
                <div className="uploaded-video">
                  <video src={primaryVideo} controls />
                </div>
              ) : (
                <p>Not uploaded </p>
              )}
              <Modal
                title={`Upload${primaryVideo ? " New " : " "}Video`}
                content={({ modalOpen }) => (
                  <FileUploadModalEl>
                    <FileUploader
                      fileTypes={["video/*"]}
                      uploadSuccess={async (file) => {
                        setPrimaryVideo(file.location);
                        modalOpen(false);
                      }}
                    />
                  </FileUploadModalEl>
                )}
              />
            </div>
          </div>

          <div className="actions">
            <Button type={"Submit"} disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </EditorEl>
    </MaterialModal>
  );
};

export default Editor;
