import React, { useState } from "react";
import FileUploader, {
  FilesList,
  FilesReorder,
  UrlFileUpload,
} from "../../../../../modules/fileUploader";
import BroadcastClassStore from "../store/broadcastClassStore";
import {
  FileUploadModalEl,
  CloseButtonEl,
  EmailModalEl as FormModalEl,
  FilesListModalEl,
} from "../../../elements";
import { H4, H5 } from "../../../../../styles/typography";
import { FileSchema } from "../../../../../schemas/misc";
import DropdownMenu from "../../../../../components/dropdownMenu";
import { addLobbyAssetOptions } from "../data";
import { ModalEl } from "../../../../../components/button/elements";
import Button from "../../../../../components/button";

function LobbyAssetUpload() {
  const [modalState, setModal] = useState(null);
  const [sorting, setSort] = useState(false);
  const {
    lobbyAssetUpload,
    deleteLobbyAsset,
    reorderLobbyAssets,
    curRequest: { lobbyAssets, teacherLobbyAssets },
  } = BroadcastClassStore.useContainer();

  return (
    <>
      <H5>
        Lobby Assets
        {!sorting && (
          <DropdownMenu
            title="Add Lobby Asset"
            items={addLobbyAssetOptions(setModal)}
            variant="contained"
          />
        )}
        {lobbyAssets.length > 0 && !sorting && (
          <Button variant="outlined" onClick={() => setSort(!sorting)}>
            Re-Order
          </Button>
        )}
      </H5>
      {sorting ? (
        <FilesReorder
          files={lobbyAssets}
          onSubmit={async (data) => {
            await reorderLobbyAssets(data);
            setSort(false);
          }}
        />
      ) : (
        <FilesList
          files={lobbyAssets}
          deleteFile={deleteLobbyAsset}
          canView={true}
        />
      )}

      <ModalEl open={!!modalState} onClose={() => setModal(null)}>
        {modalState === "URL" ? (
          <FormModalEl>
            <CloseButtonEl onClick={() => setModal(null)}>X</CloseButtonEl>
            <UrlFileUpload
              submitRequest={async (data) => {
                await lobbyAssetUpload(data);
                setModal(null);
              }}
            />
          </FormModalEl>
        ) : modalState === "UPLOAD" ? (
          <FileUploadModalEl>
            <FileUploader
              fileTypes={["video/*", "image/*"]}
              uploadSuccess={async (file) => {
                await lobbyAssetUpload(file);
                setModal(null);
              }}
            />
          </FileUploadModalEl>
        ) : modalState === "TEACHER" ? (
          <FilesListModalEl>
            <H4>Select file</H4>
            <FilesList
              files={teacherLobbyAssets}
              onClick={async (file) => {
                await lobbyAssetUpload(FileSchema(file));
                setModal(null);
              }}
            />
          </FilesListModalEl>
        ) : (
          <></>
        )}
      </ModalEl>
    </>
  );
}

export default LobbyAssetUpload;
