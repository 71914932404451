import React from "react";
import { Router, Redirect } from "@reach/router";
import Teachers from "./components/teachers";
import Courses from "./components/courses";
import BroadcastClass from "./components/broadcastClass";
import Reviews from "./components/reviews";
import Schools from "./components/schools";
import Content from "./components/content";
import Analytics from "./components/analytics";
import GenericEmail from "./components/genericEmail";
import { SectionEl, DashboardSectionEl, PrimarySidebarEl } from "./elements";
import PrimaryTab from "./components/common/primaryTab";
import { sidebarTabs } from "./config/routes";
import TabMap from "../../components/tabMap";
import checkPermission from "../../utils/checkPermission";
import Invitations from "./components/invitations";
import Reports from "./components/reports";
import Users from "./components/users";

const Dashboard = ({ role, action }) => {
  return (
    <SectionEl>
      <PrimarySidebarEl>
        <TabMap
          tabs={sidebarTabs}
          tabStyle={(props) => <PrimaryTab {...props} />}
        />
      </PrimarySidebarEl>
      <Router component={DashboardSectionEl}>
        <Redirect from="/" to="teachers" noThrow />
        <Teachers
          path="teachers/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Courses
          path="courses/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <BroadcastClass
          path="broadcast-class/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Invitations
          path="invitations/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Reviews
          path="reviews/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Schools
          path="schools/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Content
          path="content/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Analytics
          path="analytics/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <GenericEmail
          path="generic-email/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Reports
          path="reports/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
        <Users
          path="users/*"
          permission={checkPermission(role, "teachers")}
          action={action}
        />
      </Router>
    </SectionEl>
  );
};

export default Dashboard;
