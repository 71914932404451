import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import React from "react";
import CollapsableRow from "./collapsableRow";

function SubscriptionTable({ subscriptions = [] }) {
	return (
		<TableContainer>
			<Table stickyHeader aria-label="sticky table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Plan</TableCell>
						<TableCell>Plan Frequency</TableCell>
						<TableCell>Date of Purchase</TableCell>
						<TableCell>Subscription End Date</TableCell>
						<TableCell>Bills</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{subscriptions.length < 1 ? (
						<TableCell colspan={7} align="center">
							No Results
						</TableCell>
					) : (
						subscriptions.map((subscription, ind) => (
							<CollapsableRow data={subscription} />
						))
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default SubscriptionTable;
