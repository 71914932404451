import { createSelectOptions, getDaysList } from "../../../../../helpers/misc";

export const getPaymentSelectorOptions = (bills, regs) => {
	var options = [{ name: "All", value: "all" }];

	if (bills > 0) {
		options = options.concat([
			{ name: "Paid", value: "PAID" },
			{ name: "UnPaid", value: "NOT_PAID" },
		]);
	}

	if (regs > 0) {
		options = options.concat([
			{ name: "Approved", value: "APPROVED" },
			{ name: "Rejected", value: "REJECTED" },
			{ name: "Requests", value: "DRAFT" },
		]);
	}

	return options;
};

export const getPaymentHeaders = (bills, regs) => {
	var headers = [];

	if (bills > 0) {
		headers = headers.concat([
			{ id: "id", label: "ID", width: 80 },
			{ id: "name", label: "User" },
			{ id: "userId", label: "User-ID", width: 80 },
			{ id: "email", label: "Email" },
			{ id: "batchName", label: "Batch Name", width: 80 },
			{ id: "courseRegId", label: "Registration Id", width: 80 },
			{ id: "price", label: "Amount" },
			{ id: "createdAt", label: "Created At" },
			{ id: "gateway", label: "Gateway" },
			{ id: "status", label: "Status" },
			{ id: "regType", label: "Registration Type" },
		]);
	}

	if (regs > 0) {
		if (headers.length === 0) {
			headers = headers.concat([
				{ id: "id", label: "ID", width: 80 },
				{ id: "name", label: "User" },
				{ id: "userId", label: "User-ID", width: 80 },
				{ id: "email", label: "Email" },
				{ id: "batchName", label: "Batch Name", width: 80 },
				{ id: "createdAt", label: "Created At" },
				{ id: "status", label: "Status" },
			]);
		}
	}

	return headers;
};

export const KEYS_TO_FILTER_PAYMENT = [
	"id",
	"name",
	"userId",
	"email",
	"courseRegId",
	"price",
	"gateway",
	"status",
	"regType",
];

export const batchDurationOptions = [1, 1.5, 2, 2.5, 3].map((option) =>
	createSelectOptions(option, `${option} hour`)
);

export const daysSelectOptions = getDaysList().map((day, i) =>
	createSelectOptions(i, day)
);
