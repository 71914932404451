import React, { useState } from "react";
import { H4, Text } from "../../../../../../../styles/typography";
import { EndorsementEl } from "../../../elements";
import TeacherStore from "../../../store/teacherStore";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useToggle } from "../../../../../../../utils/hooks/useToggle";
import EndorsementForm from "./endorsement-form";

const Endorsement = ({ data, index }) => {
  const [showForm, toggleForm] = useState(false);
  const [disable, toggleDisable] = useToggle(false);
  const { curRequest, endorsementUpdate } = TeacherStore.useContainer();

  return (
    <EndorsementEl>
      <img src={data?.logo} alt={"logo"} />
      <div className="info">
        <H4>{data?.name}</H4>
        <Text>
          Link: <a href={data?.link || "/"}>{data?.link}</a>
        </Text>
        <Text>Brand Comments: {data?.brandComment}</Text>
        <Text>Artist Comments: {data?.artistComment}</Text>

        <div className="actions">
          <Button
            startIcon={<DeleteIcon />}
            onClick={async () => {
              toggleDisable(true);

              const updated = [...(curRequest?.endorsements || [])];

              updated.splice(index, 1);

              await endorsementUpdate(updated);

              toggleDisable(false);
            }}
            disabled={disable}
          >
            Delete
          </Button>
          <Button
            startIcon={<EditIcon />}
            disabled={disable}
            onClick={() => toggleForm(data)}
          >
            Edit
          </Button>
        </div>
      </div>

      {showForm && (
        <EndorsementForm
          data={showForm}
          close={() => toggleForm(false)}
          edit={index}
        />
      )}
    </EndorsementEl>
  );
};

export default Endorsement;
