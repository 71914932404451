import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../../components/button";
import Input from "../../../../../components/input";
import { LiveClassSettingsEl, LiveStreamingEl } from "../elements";
import BroadcastClassStore from "../store/broadcastClassStore";
import { streamOptions } from "../data";
import { H5, Text } from "../../../../../styles/typography";

function LiveClassSettings() {
	const { handleSubmit, control } = useForm();
	const [submitting, setSubmitting] = useState(false);

	const {
		startLiveStream,
		stopLiveStream,
		endClass,
		curRequest: { startTime, socialBroadcastId, ended },
	} = BroadcastClassStore.useContainer();

	return (
		<LiveClassSettingsEl>
			<H5>Class status</H5>
			<div className="end-class">
				{startTime > new Date().getTime() ? (
					<Text>Class has not started yet.</Text>
				) : (
					<>
						{ended ? (
							<Text>Class has ended.</Text>
						) : (
							<Text>Class is going on.</Text>
						)}
						<Button
							onClick={async () => {
								setSubmitting(true);
								await endClass(!ended);
								setSubmitting(false);
							}}
							disabled={submitting}
						>
							{ended ? "Restart" : "End Class"}
						</Button>
					</>
				)}
			</div>
			<hr />

			<LiveStreamingEl
				onSubmit={handleSubmit(async (values) => {
					setSubmitting(true);
					await startLiveStream(values);
					setSubmitting(false);
				})}
			>
				<H5>Live Stream</H5>
				{streamOptions.map(({ urlFieldName, url, keyFieldName }) => (
					<div className="url-key">
						<Controller
							as={<Input />}
							control={control}
							name={urlFieldName}
							label={"URL"}
							defaultValue={url}
							disabled={Boolean(socialBroadcastId)}
						/>
						<Controller
							as={<Input />}
							control={control}
							name={keyFieldName}
							label={"Key"}
							defaultValue=""
							disabled={Boolean(socialBroadcastId)}
						/>
					</div>
				))}
				<Button
					type="submit"
					disabled={submitting || Boolean(socialBroadcastId)}
				>
					Submit
				</Button>
			</LiveStreamingEl>
			{socialBroadcastId && (
				<Button onClick={() => stopLiveStream()}>Stop Live Stream</Button>
			)}
		</LiveClassSettingsEl>
	);
}

export default LiveClassSettings;
