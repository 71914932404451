import { useCallback, useState } from "react";
import { useList } from "react-use";
import useSWR from "swr";
import { createContainer } from "unstated-next";
import { Notification } from "../../../../../../../modules/toastNotification";
import { createTeachersList } from "../helpers";
import { updateArtists } from "../../../../../../../api/featuredArtists";
import { getTeachers } from "../../../../../../../api/teacher";
import { always, identity, juxt, pipe } from "ramda";

const parser = pipe(
  juxt([
    identity,
    always(
      Array.from({ length: 4 }).map((_, i) => ({
        coverImage: "",
        id: i,
        livdemyId: "",
        name: "",
      }))
    ),
  ]),
  ([data, template]) => template.map((item, i) => data[i] || item)
);

const FeaturedArtistsStore = createContainer(() => {
  const [loading, setLoading] = useState(true);
  const [teachersList, { push: pushTeacher }] = useList([]);

  const init = useCallback(async () => {
    let data = await getTeachers();
    data = createTeachersList(data);

    if (data) {
      pushTeacher(...data);
      setLoading(false);
    }
  }, [pushTeacher, setLoading]);

  const { data: featuredArtists, error, mutate } = useSWR([
    "admin/config/featured-artists",
    "protected",
    parser,
  ]);

  const updateFeaturedArtists = useCallback(
    async (data) => {
      const success = await updateArtists(data);

      if (success) {
        await mutate();
        Notification("success", "Featured Artists list updated");
      }
    },
    [mutate]
  );

  return {
    loading: loading || !Boolean(featuredArtists),
    loadFailed: Boolean(error),
    teachersList,
    init,
    featuredArtists,
    updateFeaturedArtists,
  };
});

export default FeaturedArtistsStore;
