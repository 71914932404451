import React, { useEffect, useState } from "react";
import GenericEmailStore from "./store/genericEmailStore";
import PrivateRoute from "../../../../utils/privateRoute";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { isRequired, string } from "../../../../utils/form-errors";
import { Notification } from "../../../../modules/toastNotification";
import { sendCommonEmail, sendMailToAllUsers } from "../../../../api/common";
import Loading from "../../../../components/loading";
import { H4, Text } from "../../../../styles/typography";
import Input from "../../../../components/input";
import Select from "react-select";
import SelectedTeachers from "./components/selectedTeachers";
import SelectedBCs from "./components/selectedBCs";
import Button from "../../../../components/button";
import { withStore } from "../../../../utils/withStore";
import { GenericEmailEl } from "./elements";
import Checkbox from "../../../../components/input/components/checkbox";
import {
  ConfirmationModalEl,
  ModalEl,
} from "../../../../components/button/elements";

function GenericEmail() {
  const [toAll, setToAll] = useState(false);
  const [allConfirm, setAllConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, errors, control, setValue, reset, getValues } = useForm(
    {
      resolver: yupResolver(
        yup.object().shape({
          subject: yup.string(string).required(isRequired`Subject`),
          body: yup
            .string(string)
            .test(
              "body-required-test",
              isRequired`Email Body`,
              (value) =>
                !((!value || value === "<p><br></p>") && !getValues("body2"))
            )
            .test(
              "only-one-body-test",
              "Email body can be filled in only one box",
              (value) =>
                (!!value && value !== "<p><br></p>") ^ !!getValues("body2")
            ),
          body2: yup.string(string),
        })
      ),
      defaultValues: {
        subject: "",
        body: "",
        body2: "",
        teachersList: "",
        bcList: "",
      },
    }
  );

  const { loading, init, masterClassesList, teachersList } =
    GenericEmailStore.useContainer();

  useEffect(() => {
    (async function () {
      await init();
    })();
  }, [init]);

  const onSubmit = async ({ bcList, teachersList, ...values }) => {
    values.body =
      !values.body || values.body === "<p><br></p>"
        ? values.body2
        : values.body;
    delete values.body2;

    setSubmitting(true);
    if (toAll) {
      const success = await sendMailToAllUsers(values);

      if (success) {
        Notification("success", "Email sent successfully");
        reset();
      }
    } else if (!values?.teachers && !values?.bcs) {
      Notification("error", "Select atleast one batch of students");
    } else {
      const success = await sendCommonEmail(values);

      if (success) {
        Notification("success", "Email sent successfully.");
        reset();
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (toAll) setAllConfirm(true);
  }, [toAll, setAllConfirm]);

  return (
    <Loading loading={loading}>
      <GenericEmailEl>
        <H4>Generic Email</H4>
        <Text>
          Select teachers and masterclasses and subsequently select batches of
          students to send email.
        </Text>
        <form>
          <Controller
            as={<Input />}
            control={control}
            name={"subject"}
            error={errors.subject}
            placeholder={"Subject"}
          />
          <div className="body-input">
            <Controller
              as={<Input type="texteditor" />}
              control={control}
              name={"body"}
              error={errors.body}
              placeholder={"Email body..."}
            />
            {errors?.body && <p className="error">{errors?.body.message}</p>}
            <Controller
              as={
                <Input
                  type="textbox"
                  placeholder="Email Body (HTML)"
                  className="bodyBox2"
                />
              }
              control={control}
              name="body2"
              errors={errors.emailText}
              rowsMin={5}
            />
          </div>
          <Checkbox
            label={"All Users"}
            value={toAll}
            onChange={(e) => setToAll(e.target.checked)}
            checked={toAll}
          />
          <ModalEl open={allConfirm} onClose={() => setAllConfirm(false)}>
            <ConfirmationModalEl>
              <p>Are you sure you want to email ALL the users on system?</p>
              <div>
                <Button
                  onClick={() => {
                    setToAll(false);
                    setAllConfirm(false);
                  }}
                >
                  No
                </Button>{" "}
                <Button onClick={async () => await setAllConfirm(false)}>
                  Yes
                </Button>
              </div>
            </ConfirmationModalEl>
          </ModalEl>
          {!toAll && (
            <>
              <Controller
                as={<Select />}
                control={control}
                name={"teachersList"}
                placeholder={"Select Teachers"}
                options={teachersList}
              />
              <SelectedTeachers control={control} reset={setValue} />
              <Controller
                as={<Select />}
                control={control}
                name={"bcList"}
                placeholder={"Select Classes"}
                options={masterClassesList}
              />
              <SelectedBCs control={control} reset={setValue} />
            </>
          )}
          <Button
            type="submit"
            withconfirmation={toAll}
            confirmationMsg="Are you sure you want to email ALL the users on system?"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting}
          >
            Submit
          </Button>
        </form>
      </GenericEmailEl>
    </Loading>
  );
}

export default withStore([GenericEmailStore])(PrivateRoute(GenericEmail));
