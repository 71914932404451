import React, { useState } from "react";
import { SearchboxEl, SearchSidebarEl, SearchTabsEl } from "../../elements";
import Search from "../../../../components/search";
import SearchTab from "./searchTab";
import TabMap from "../../../../components/tabMap";
import { createFilter } from "react-search-input";

const KEYS_TO_FILTER = [
  "meta.status",
  "meta.text",
  "meta.subtext",
  "meta.subtabs.meta.text",
];

function SearchSidebar({ tabs, filterKeys, TabStyle }) {
  const [query, setQuery] = useState("");
  let filteredTabs = tabs.filter(createFilter(query, filterKeys));

  return (
    <SearchSidebarEl className="search-sidebar">
      <SearchboxEl>
        <Search onChange={setQuery} />
      </SearchboxEl>
      <SearchTabsEl>
        <TabMap tabs={filteredTabs} tabStyle={(props) => <TabStyle {...props} />} />
      </SearchTabsEl>
    </SearchSidebarEl>
  );
}

export default SearchSidebar;

SearchSidebar.defaultProps = {
  filterKeys: KEYS_TO_FILTER,
  TabStyle: SearchTab,
};
