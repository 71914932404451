import date from "date-and-time";
import React from "react";
import Button from "../components/button";
import ClickToCopy from "../modules/clickToCopy";

export const classPaymentData = (
	markBillPaid,
	highlightUserMessages,
	deregister,
	data,
	header
) => {
	switch (header) {
		case "createdAt":
		case "updatedAt":
			data = date.format(new Date(data[header]), "DD MMM YYYY hh:mmA");
			break;
		case "isSeriesBill":
			data = data[header] ? "Yes" : "No";
			break;
		default:
			data = data[header];
			break;
	}

	return typeof data === "object" ? (
		<>
			<Button
				variant={data.status === "NOT_PAID" ? "contained" : "outlined"}
				color={data.status === "NOT_PAID" ? "primary" : "secondary"}
				onClick={() => markBillPaid(data.id)}
				withconfirmation
			>
				{data.status === "NOT_PAID" ? "Mark Paid" : "Conf Email"}
			</Button>

			{data.status === "PAID" && (
				<Button
					variant={"contained"}
					color={"primary"}
					onClick={() => deregister(data.registrationId)}
					withconfirmation
				>
					Deregister
				</Button>
			)}

			<Button
				variant={!data?.highlightMessages ? "contained" : "outlined"}
				color={!data?.highlightMessages ? "primary" : "secondary"}
				onClick={() => {
					highlightUserMessages(
						data.registrationId,
						!data?.highlightMessages,
						data?.isSeriesBill
					);
				}}
				withconfirmation={false}
			>
				{!data?.highlightMessages ? "Highlight Msg." : "Remove Highlight"}
			</Button>
		</>
	) : (
		<ClickToCopy data={String(data)}>{data}</ClickToCopy>
	);
};
