import { Api } from "./Configuration";

export const updateCourseDetailsAdmin = (courseID, teacherID, updates) =>
	Api.protected.put(`admin/courses/${courseID}`, {
		...updates,
		teacherId: teacherID,
	});

export const processCourseApprovalRequest = (id, status, note) =>
	Api.protected.post(`admin/process-course/${id}`, {
		status,
		note,
	});

export const deleteCourse = (courseID) =>
	Api.protected.delete(`courses/${courseID}`);

export const setCourseCover = (courseId, teacherId, imageId) =>
	Api.protected.post("courses/add-course-cover/", {
		imageId,
		teacherId,
		courseId,
	});
export const setCourseDiscount = (courseId, couponValue) =>
	Api.protected.post(`course/${courseId}/coupon`, {
		couponValue,
	});

export const sendpaidUnpaidEmail = (courseId, data) =>
	Api.protected.post(`course/recorded/communication/${courseId}`, data);

export const getPaymentInfo = (courseId) =>
	Api.protected.get(`admin/payments/course/${courseId}`);

export const updateEditRequest = (courseId, approve, note) =>
	Api.protected.post(`admin/approve-reject-course/${courseId}/${approve}`, {
		approve,
		note,
	});

export const rescheduleBatch = (batchId, dateSlots, rescheduleMessage) =>
	Api.protected.post(`course/batch/${batchId}/reschedule`, {
		dateSlots,
		rescheduleMessage,
	});

export const batchEditRequest = (batchId, data) =>
	Api.protected.put(`course/batch`, {
		batchId,
		...data,
	});

export const batchRenew = (batchId) =>
	Api.protected.post(`course/batch/${batchId}/renew`);

export const batchCommunicationEmail = (batchId, data) =>
	Api.protected.post(`batch/${batchId}/communication`, data);

export const addCoverVideo = (courseId, data) =>
	Api.protected.post(`courses/${courseId}/course-cover-video`, data);

export const deleteCoverVideo = (courseId) =>
	Api.protected.delete(`courses/${courseId}/course-cover-video`);
export const getPaymentInfoCSV = (id) =>
	Api.protected.get(`admin/payments/${id}/csv`, {
		meta: {
			type: "csv",
		},
	});
export const whitelistCourse = (data) =>
	Api.protected.post(`course/whitelist`, data);

export const CourseApi = {
	whitelistCourse,
	courseEditRequest: updateCourseDetailsAdmin,
	updateRequestStatus: processCourseApprovalRequest,
	deleteRequest: deleteCourse,
	setCoverImage: setCourseCover,
	setCourseDiscount,
	getPaymentInfo,
	updateEditRequest,
	rescheduleBatch,
	batchEditRequest,
	addCoverVideo,
	deleteCoverVideo,
	batchRenew,
	getPaymentInfoCSV,
};
