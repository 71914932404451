import { useCallback, useState } from "react";

export const useMap = (initialState = {}) => {
  const [map, update] = useState(initialState);

  const set = useCallback((key, value) => {
    update((map) =>
      typeof value === "function"
        ? { ...map, [key]: value(map[key], map) }
        : { ...map, [key]: value }
    );
  }, []);

  const setAll = useCallback((value) => {
    if (!["object", "function"].includes(typeof value)) {
      return;
    }
    update((map) => (typeof value === "function" ? value(map) : value));
  }, []);

  const remove = useCallback((key) => {
    update((map) => {
      delete map[key];
      return { ...map };
    });
  }, []);

  const reset = useCallback(() => {
    update(initialState);
  }, [initialState]);

  return [
    map,
    {
      set,
      setAll,
      remove,
      reset,
    },
  ];
};
