import React, { useState } from "react";
import Select from "react-select";
import Button from "../../button";
import { ListSubmitFormEl } from "../elements";

function ListSubmitForm({
  label,
  defaultVal = null,
  onSubmit,
  withConfirmation = false,
  buttonTitle = "Submit",
  ...rest
}) {
  const [selected, setSelected] = useState(defaultVal);
  const [loading, setLoading] = useState(false);

  return (
    <ListSubmitFormEl>
      {label && (
        <p>
          <b>{label}:</b>
        </p>
      )}
      <div>
        <Select
          value={selected}
          onChange={(value) => setSelected(value)}
          {...rest}
          styles={customStyles}
        />
        <Button
          onClick={async () => {
            setLoading(true);
            await onSubmit(selected);
            setLoading(false);
          }}
          disabled={loading}
          withconfirmation={withConfirmation}
        >
          {buttonTitle}
        </Button>
      </div>
    </ListSubmitFormEl>
  );
}

export default ListSubmitForm;

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    borderBottom: "1px solid #cdcbcd",
    borderRadius: 0,
    boxShadow: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#2D2D2D",
    fontWeight: 300,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
};
