import React from "react";
import { ActiveSecEl, ScrollSectionEl } from "../../../elements";
import Loading from "../../../../../components/loading";

function ActiveSection({
  infoComponent: InfoComponent,
  contentComponent: ContentComponent,
  statusComponent: StatusComponent,
  Store,
}) {
  const { curRequest, requestLoading, ...rest } = Store.useContainer();

  return (
    <Loading loading={requestLoading}>
      <ActiveSecEl>
        <StatusComponent request={curRequest} {...rest} />
        <ScrollSectionEl>
          {InfoComponent ? (
            <InfoComponent request={curRequest} params={rest.params} />
          ) : null}
          {ContentComponent ? (
            <ContentComponent request={curRequest} {...rest} />
          ) : null}
        </ScrollSectionEl>
      </ActiveSecEl>
    </Loading>
  );
}

export default ActiveSection;
