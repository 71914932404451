import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Input from "../../../../../components/input";
import { H4 } from "../../../../../styles/typography";
import { SendInvitationEl } from "../elements";
import { templateToList } from "../helpers";
import { templates } from "../config";
import Button from "../../../../../components/button";
import InvitationStore from "../store/invitationStore";

function SendInvitation({ onComplete }) {
  const [submitting, setSubmitting] = useState(false);
  const { handleSubmit, reset, control } = useForm();
  const { sendInvitation } = InvitationStore.useContainer();

  const onSub = async ({ email, template }) => {
    setSubmitting(true);
    const userEmails = { data: email.replace(/ /g, "").split(",") };
    const templateType = template.value;
    await sendInvitation({ userEmails, templateType });
    reset({ email: "", template: null });
    onComplete();
    setSubmitting(false);
  };

  return (
    <SendInvitationEl onSubmit={handleSubmit(onSub)}>
      <H4>Send Invitation</H4>
      <Controller
        as={<Input />}
        control={control}
        name={"email"}
        placeholder={"Comma seperated list of user emails"}
        label="Email Ids"
      />
      <Controller
        as={<Select />}
        control={control}
        name={"template"}
        isClearable
        options={templateToList(templates)}
        placeholder="Select Template"
      />
      <Button type={"submit"} disabled={submitting}>
        Submit
      </Button>
    </SendInvitationEl>
  );
}

export default SendInvitation;
