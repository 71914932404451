import React, { useState } from "react";
import { BatchEl } from "../../elements/batches";
import { H4, H5, S05 } from "../../../../../../styles/typography";
import date from "date-and-time";
import { getBatchSchedule } from "../../helpers";
import { IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import EditBatchForm from "./editBatchForm";
import { getTimeZone } from "../../../../../../helpers/misc";
import AllSlots from "./allSlots";
import CourseStore from "../../store/courseStore";
import Form from "../../../../../../components/forms";
import Modal, { ModalComp } from "../../../../../../components/modal";
import { CloseButtonEl, EmailModalEl } from "../../../../elements";
import BatchEmail from "./batchEmail";
import { batchCommunicationEmail } from "../../../../../../api/course";
import { Notification } from "../../../../../../modules/toastNotification";
import Button from "../../../../../../components/button";
import { useToggle } from "../../../../../../utils/hooks/useToggle";

function Batch({ data: { id, name, slots, batchLimit }, data }) {
  const [editState, setEditState] = useState(false);
  const [slotsData, updateSlotData] = useState(slots);
  const [preview, showPreview] = useState(false);

  const { rescheduleBatch, batchEditRequest, renewBatchRequest } =
    CourseStore.useContainer();
  const [processing, toggleProcessing] = useToggle(false);

  const startTime = slots[0].startTime;
  const endTime = slots.slice(-1)[0].endTime;
  const schedule = getBatchSchedule(slots);

  return (
    <BatchEl>
      {editState ? (
        <div className="batch-edit">
          <H4>{name}</H4>
          <Form
            formType="text"
            name={`${id}-batchLimit`}
            label={"Batch Limit"}
            defaultVal={batchLimit}
            editToggle={true}
            submitRequest={async (val) => {
              await batchEditRequest(id, { title: name, batchLimit: val });
              setEditState(false);
            }}
          />
          <H5 bold>Schedule</H5>
          <EditBatchForm
            data={data}
            onCancel={() => {
              setEditState(false);
            }}
            onSubmit={(data) => {
              updateSlotData(data);
              showPreview(true);
            }}
          />
          <ModalComp open={preview} onClose={() => showPreview(false)}>
            <AllSlots
              data={slotsData}
              onSubmit={async () => {
                await rescheduleBatch(id, slotsData);
                showPreview(false);
                setEditState(false);
              }}
            />
          </ModalComp>
        </div>
      ) : (
        <>
          <IconButton className="edit-icon" onClick={() => setEditState(true)}>
            <CreateIcon />
          </IconButton>
          <H4>{name}</H4>
          <H5 bold>Timeline:</H5>
          <S05>
            From{" "}
            {date
              .format(new Date(startTime), `DD MMMM YYYY`)
              .split(".")
              .join("")}{" "}
            to{" "}
            {date.format(new Date(endTime), `DD MMMM YYYY`).split(".").join("")}
          </S05>
          <H5 bold>Schedule:</H5>
          {schedule.map((timing) => (
            <S05 key={timing}>
              {timing} {getTimeZone()}
            </S05>
          ))}
          <H5>
            <b>Batch Limit: </b>
            {batchLimit}
          </H5>
          <Button
            withconfirmation
            style={{ marginRight: 4 }}
            onClick={async () => {
              toggleProcessing(true);
              await renewBatchRequest(id);
              toggleProcessing(false);
            }}
            disabled={processing}
          >
            Renew Batch
          </Button>
          <Modal
            title="Send Email"
            content={({ modalOpen }) => (
              <EmailModalEl>
                <CloseButtonEl onClick={() => modalOpen(false)}>
                  X
                </CloseButtonEl>

                <BatchEmail
                  submitRequest={async (val) => {
                    const { success } = await batchCommunicationEmail(id, val);
                    if (success)
                      Notification("success", "Email Sent successfully.");
                    modalOpen(false);
                  }}
                  withSubject
                />
              </EmailModalEl>
            )}
          />
        </>
      )}
    </BatchEl>
  );
}

export default Batch;
