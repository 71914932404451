import React, { useState } from "react";
import { Router, Redirect } from "@reach/router";
import { ActiveClassSecEl, ClassFilterNavEl } from "./elements";
import { ActiveSecEl, TabLinkEl } from "../../elements";
import Input from "../../../../components/input";
import SelectedBroadcastClass from "./components/selectedBroadcastClass";
import PrivateRoute from "../../../../utils/privateRoute";
import { broadcastClassTypes } from "../../config/routes";
import { useParams } from "../../../../utils/hooks/useParams";

function BroadcastClass() {
  const { classType: clstype } = useParams(
    "/dashboard/broadcast-class/:classType"
  );
  const [classType, setClassType] = useState(
    clstype !== undefined ? clstype : "master-class"
  );
  return (
    <ActiveSecEl>
      <ClassFilterNavEl>
        <Input
          type="switch"
          curVal={classType}
          component={TabLinkEl}
          onChange={(e, val) => setClassType(val)}
          tabs={broadcastClassTypes}
          exclusive
        />
      </ClassFilterNavEl>
      <Router component={ActiveClassSecEl}>
        <Redirect from="/" to="master-class" noThrow />
        <SelectedBroadcastClass path=":classType/*" />
      </Router>
    </ActiveSecEl>
  );
}
export default PrivateRoute(BroadcastClass);
